import React from 'react';
import ReactDOM from 'react-dom';
import Script from 'react-load-script';
import {IntlProvider} from 'react-intl';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {
  BrowserRouter as Router,
} from "react-router-dom";
import { createBrowserHistory } from 'history';

import { config } from './config/config';
import rollbar from './react-shared/src/logging/rollbar';
let rollbarHackImport = rollbar;

const segmentScript = config.env === 'production' ? '/prod-segment.min.js' : '/staging-segment.min.js';
const history = createBrowserHistory();
ReactDOM.render(
  <IntlProvider locale="en">
    <Script attributes={{ id:"segmentScript", ref:"segmentScript"}}  url={segmentScript} />
    <Router>
      <App history={ history }/>
    </Router>
  </IntlProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
