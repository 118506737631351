

import React, {
  useState,
  useEffect,
} from 'react';

import {
  appStates
} from './statemachine/app_states';

import { css, StyleSheet } from 'aphrodite/no-important';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';

// External hooks
import { useBeforeunload } from 'react-beforeunload';

import AppDeviceDetectState from './Components/app_states/AppDeviceDetectState';
import AppInitState from './Components/app_states/AppInitState';
import AppErrorState from './Components/app_states/AppErrorState';
import AppLoggedInStateMachine from './Components/logged_in_states/AppLoggedInStateMachine';
import AppLoggedOutState from './Components/app_states/AppLoggedOutState';
import AppLoginErrorState from './react-shared/src/components/app_states/AppLoginErrorState';
import AppNCPTLoginState from './Components/app_states/AppNCPTLoginState';
import AppLumosSSOLoginState from './Components/app_states/AppLumosSSOLoginState';
import Header from './Components/header/Header';

// HACK: TEMP Easy way to build components
import DevTestComponent from './Components/DevTestComponent';

import {
  STORES,
  STATES,
  STATE_EVENTS,
} from './constants';
import {
  createStore,
  useStoreState,
} from './react-shared/src/stores';
import {
  initialAppStore,
  setCurrentAppState,
  setNewPreviousAppState,
} from './stores/app_store';
import {
  initialUserStore,
  setAdminShowLib,
} from './stores/user_store';
import {
  useStateMachine,
} from './react-shared/src/statemachine';
import {
  saveInitPath,
} from './actions/app_actions';

import {
  analyticsTrack,
} from './react-shared/src/segment/analytics';

import {
  backToLumos,
} from './utils/link_utils';

import {
  getUserFirstName,
  isAdmin,
  isTester,
} from './stores/user_store_getters';
import {
  getCurrentAppState,
} from './react-shared/src/stores/app_store_getters';

import { config } from './config/config';

if (['development', 'staging'].includes(window.REACT_APP_ENVIRONMENT)) {
  console.log(window);
  console.log(config);
}

createStore(STORES.APP.NAME, initialAppStore);
createStore(STORES.USER.NAME, initialUserStore);

const App = (props) => {

  useBeforeunload(event => {
    analyticsTrack('application_quit');
  });

  const updateUserStore = (newUserStoreState) => {
    userStoreState = newUserStoreState;
    setUser(userStoreState.user);
    setUserName(getUserFirstName(userStoreState));
    setAdmin(isAdmin(userStoreState));
    setTester(isTester(userStoreState));
  }
  let [userStoreState] = useStoreState(STORES.USER.NAME, updateUserStore);

  const updateAppStore = (newAppStoreState) => {
    appStoreState = newAppStoreState;
  }
  let [appStoreState] = useStoreState(STORES.APP.NAME, updateAppStore);

  const updateStateMachineState = function (newStateMachineState) {
    if (stateMachineState.flattenedValue !== newStateMachineState.flattenedValue) {
      // State changed
      setNewPreviousAppState(appStoreState, getCurrentAppState(appStoreState));
      setCurrentAppState(newStateMachineState.flattenedValue);
      console.log("State: ", getCurrentAppState(appStoreState));
    }

    setStateMachineState(newStateMachineState);
  }

  const headerSetCatalog = () => {
    setAdminShowLib();
    sendStateMachineEvent(STATE_EVENTS.LOGGED_IN.LIBRARY);
  }

  const logout = () => {
    sendStateMachineEvent(STATE_EVENTS.APP.LOGOUT);
  }

  // User has switched back to the tab
  const onFocus = () => {
    analyticsTrack('page_resume');
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    analyticsTrack('page_pause');
  };

  // Hooks
  let [smState, sendStateMachineEvent] = useStateMachine({
    updateCallback: updateStateMachineState
  });
  const [stateMachineState, setStateMachineState] = useState(smState);
  const [started, setStarted] = useState(false);
  const [, setUser] = useState(userStoreState.user);
  const [userName, setUserName] = useState(getUserFirstName(userStoreState));
  const [userIsTester, setTester] = useState(false);
  const [userIsAdmin, setAdmin] = useState(false);

  useEffect(() => {
    saveInitPath();
    props.history.replace(`/`, {});
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    setTimeout(() => {
      setStarted(true);
      analyticsTrack('application_attempt', {
        previous_page: window.document.referrer,
      });
    }, 500);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  if (!started) {
    return (<div> </div>);
  }

  return (
    <Container className={css(styles.app)}>
      <Container className={css(styles.header)}>
        {config.env !== 'kernel' &&
          <Header
            links={{
              logo: {
                href: 'https://lumosity.com',
                eventName: 'foo',
                trackLinkClick: 'goto_homepage'
              }
            }}
            backToLumosCallback={backToLumos}
            catalogCallback={headerSetCatalog}
            logoutCallback={logout}
            isAdmin={userIsAdmin}
            isTester={userIsTester || config.development === true}
            userName={userName}
          />
        }
      </Container>
      <Container className={css(styles.mainContent)}>
        {
          stateMachineState.flattenedValue === STATES.APP.INIT &&
          <AppInitState history={props.history} />
        }
        {
          stateMachineState.flattenedValue === STATES.APP.DEVICE_DETECT &&
          <AppDeviceDetectState history={props.history} />
        }
        {
          stateMachineState.flattenedValue === STATES.APP.APP_ERROR &&
          <AppErrorState history={props.history} />
        }
        {
          stateMachineState.flattenedValue.includes(STATES.APP.LOGGED_IN) &&
          <AppLoggedInStateMachine history={props.history} />
        }
        {
          stateMachineState.flattenedValue === STATES.APP.LOGGED_OUT &&
          <AppLoggedOutState history={props.history} />
        }
        {
          stateMachineState.flattenedValue === STATES.APP.LOGIN_ERROR &&
          <AppLoginErrorState history={props.history} />
        }
        {
          stateMachineState.flattenedValue === STATES.APP.LUMOS_SSO_LOGIN &&
          <AppLumosSSOLoginState history={props.history} />
        }
        {
          stateMachineState.flattenedValue === STATES.APP.NCPT_LOGIN &&
          <AppNCPTLoginState history={props.history} />
        }
        {
          stateMachineState.flattenedValue === STATES.APP.DEV_TEST &&
          <DevTestComponent />
        }
      </Container>
    </Container>
  );
}

const styles = StyleSheet.create({
  app: {
    width: '100%',
    maxWidth: '100%',
    margin: '0',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    // flex: '1',
    width: '100%',
    maxWidth: '100%',
    margin: '0',
    padding: '0',
    backgroundColor: "#0C3040",
  },
  mainContent: {
    // minHeight: '805px',
    padding: 0,
    margin: 0,
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    display: 'none',
  },
});

export default App;
