
import get from 'lodash/get';
import merge from 'lodash/merge';
import * as Cookies from 'js-cookie';

import {
  mutateStore,
  mutateStoreMultiple,
} from '../react-shared/src/stores';

import {
  getCurrentBattery,
  getFutureBattery,
  getUser
} from './user_store_getters';

import {
  MISC,
  STORES,
} from '../constants';

import {
  analyticsIdentify,
} from '../react-shared/src/segment/analytics';

let initialStore = {};
initialStore[STORES.USER.USER_KEY] = {};
initialStore[STORES.USER.CURRENT_ASSESSMENT_RUN_KEY] = {};
initialStore[STORES.USER.CURRENT_ASSESSMENT_CONFIG_KEY] = {};
initialStore[STORES.USER.CURRENT_BATTERY_RUN_KEY] = {};
initialStore[STORES.USER.COMPLETED_BATTERY_RUN_KEY] = {};
initialStore[STORES.USER.BATTERY_SUMMARY_KEY] = {};
initialStore[STORES.USER.FUTURE_BATTERY_RUN_KEY] = {};
// initialStore[STORES.USER.ADMIN_MOVES_KEY] = {'showLibrary': false};
// initialStore[STORES.USER.ADMIN_MOVES_KEY] = {};
initialStore[STORES.USER.FORCE_LUMOS_SSO_KEY] = true;
initialStore[STORES.USER.SHOW_LOGIN_WELCOME_SCREEN_KEY] = true;

// BATTERY RESULTS_HISTORY
initialStore[STORES.USER.BATTERY_RESULTS_HISTORY_KEY] = {};
initialStore[STORES.USER.CHOSEN_BATTERY_SUMMARY_ID_KEY] = '';
initialStore[STORES.USER.LOADED_BATTERY_SUMMARY_BY_ID_KEY] = {};

const initialUserStore = Object.assign({}, initialStore);

const resetUserStore = () => {
  for (let [key, value] of Object.entries(initialUserStore)) {
    mutateStore(STORES.USER.NAME, key, value);
  }
}

const updateAssessmentRunStores = (userStore, assessmentRun) => {
  if (assessmentRun.assessment) {
    mutateStore(STORES.USER.NAME, STORES.USER.CURRENT_ASSESSMENT_RUN_KEY, assessmentRun.assessment);
    if (assessmentRun.assessment.assessment_config) {
      setNewAssessmentConfig(assessmentRun.assessment.assessment_config);
    }
  }
  if (assessmentRun.user) {
    let existingUserData = getUser(userStore);
    mergeUser(existingUserData, assessmentRun.user);
  }
}

const setBatteryResultsHistory = (batteryResultsHistoryData) => {
  mutateStore(STORES.USER.NAME, STORES.USER.BATTERY_RESULTS_HISTORY_KEY, batteryResultsHistoryData);
}

const setBatterySummary = (userStore, batterySummary) => {
  const loadedBatterySummaryById = get(userStore, STORES.USER.LOADED_BATTERY_SUMMARY_BY_ID_KEY, {});
  loadedBatterySummaryById[batterySummary.battery.id] = batterySummary;
  mutateStore(STORES.USER.NAME, STORES.USER.LOADED_BATTERY_SUMMARY_BY_ID_KEY, loadedBatterySummaryById);
}

const setChosenBatterySummaryId = (batteryId) => {
  mutateStore(STORES.USER.NAME, STORES.USER.CHOSEN_BATTERY_SUMMARY_ID_KEY, batteryId);
}

const setNewAssessmentRun = (userStore, assessmentRun) => {
  updateAssessmentRunStores(userStore, assessmentRun);
}

const updateAssessmentRun = (userStore, assessmentRun) => {
  updateAssessmentRunStores(userStore, assessmentRun);
}

const setNewAssessmentConfig = (config) => {
  mutateStore(STORES.USER.NAME, STORES.USER.CURRENT_ASSESSMENT_CONFIG_KEY, config);
}

const setUser = (user) => {
  console.log("User Id: ", user.id);
  mutateStore(STORES.USER.NAME, STORES.USER.USER_KEY, user);
}

const mergeUser = (existingUserData, user) => {
  let updatedUser = merge(existingUserData, user);
  mutateStore(STORES.USER.NAME, STORES.USER.USER_KEY, updatedUser);
}

const setAdminShowLib = () => {
  mutateStore(STORES.USER.NAME, STORES.USER.ADMIN_LIB_KEY, true);
}

// This is a delicate piece. Do all mutations together.
const updateBatteryRun = (userStore, updatedBatteryRunData) => {
  let mutations = {};
  let battery = updatedBatteryRunData.battery;
  if (battery) {
    if (battery.battery_state === 'CREATED') {
      mutations[STORES.USER.FUTURE_BATTERY_RUN_KEY] = battery;
    } else if (['COMPLETED'].includes(battery.battery_state)) {
      mutations[STORES.USER.COMPLETED_BATTERY_RUN_KEY] = battery;
      mutations[STORES.USER.CHOSEN_BATTERY_SUMMARY_ID_KEY] = battery.id;
      if (get(getCurrentBattery(userStore), 'id', '') === battery.id) {
        mutations[STORES.USER.CURRENT_BATTERY_RUN_KEY] = {};
      }
    } else {
      mutations[STORES.USER.CURRENT_BATTERY_RUN_KEY] = battery;
      if (get(getFutureBattery(userStore), 'id', '') === battery.id) {
        mutations[STORES.USER.FUTURE_BATTERY_RUN_KEY] = {};
      }
    }
    mutateStoreMultiple(STORES.USER.NAME, mutations);
  }
  if (updatedBatteryRunData.user) {
    let existingUserData = getUser(userStore);
    mergeUser(existingUserData, updatedBatteryRunData.user);
  }
}

const setForceLumosSSO = (force) => {
  mutateStore(STORES.USER.NAME, STORES.USER.FORCE_LUMOS_SSO_KEY, force);
}

const setShowLoginWelcomeScreen = (show) => {
  mutateStore(STORES.USER.NAME, STORES.USER.SHOW_LOGIN_WELCOME_SCREEN_KEY, show);
}

const setSeenLoggedInWelcomeScreen = () => {
  setShowLoginWelcomeScreen(false);
}

const loginUser = (loginData) => {
  setAuthTokenCookie(loginData.access_token);
  setRefreshTokenCookie(loginData.refresh_token);

  setForceLumosSSO(get(loginData, 'force_lumos_sso', false));
  setShowLoginWelcomeScreen(get(loginData, 'show_logged_in_welcome', true));

  const user = get(loginData, 'user', {});
  analyticsIdentify(user.id);
  setUser(user);
}

const logoutUser = () => {
  resetUserStore();
  unsetAuthTokenCookie();
  unsetRefreshTokenCookie();
}

////////// TOKENS //////////

const setAuthTokenCookie = (accessToken) => {
  Cookies.set(MISC.AUTH_TOKEN_COOKIE, accessToken);
}

const unsetAuthTokenCookie = () => {
  Cookies.remove(MISC.AUTH_TOKEN_COOKIE);
}

const setRefreshTokenCookie = (accessToken) => {
  Cookies.set(MISC.REFRESH_TOKEN_COOKIE, accessToken);
}

const unsetRefreshTokenCookie = () => {
  Cookies.remove(MISC.REFRESH_TOKEN_COOKIE);
}

export {
  initialUserStore,
  loginUser,
  logoutUser,
  mergeUser,
  setAdminShowLib,
  setBatteryResultsHistory,
  setBatterySummary,
  setChosenBatterySummaryId,
  setNewAssessmentConfig,
  setNewAssessmentRun,
  setSeenLoggedInWelcomeScreen,
  setUser,
  updateAssessmentRun,
  updateBatteryRun,
}
