
import React from 'react';

import {FormattedMessage} from 'react-intl';

import { css, StyleSheet } from 'aphrodite/no-important';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import commonStyles from '../commonStyles';

const PageTitleSubheader = (props) => {
  return (
    <Container className = { css([commonStyles.flexColumnAllCenter, styles.subheader]) }>
      <Container className= { css(commonStyles.flexColumnAllCenter) }>
        <Typography className= { css(styles.title) }>
          <FormattedMessage
            id="header"
            defaultMessage={ props.title }
            values={{ name: props.userName }}
          />
        </Typography>
      </Container>
    </Container>
  )
}

const styles = StyleSheet.create({
  subheader: {
    backgroundColor: "#F3F3F3",
    height: '66px',
    padding: 0,
    margin: 0,
    width: '100%',
    maxWidth: '100%',
  },
  // TITLE

  title: {
    color: "#666666",
    fontFamily: "MuseoSans500",
    fontSize: 28,
    letterSpacing: '1px',
  },
});

export default PageTitleSubheader;
