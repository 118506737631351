
import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { css } from 'aphrodite/no-important';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import {
  STATE_EVENTS,
  STORES,
} from '../../constants';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import {
  isTester,
} from '../../stores/user_store_getters';

import PrepStateCheckIn from '../prep_state/PrepStateCheckIn';
import commonStyles from '../commonStyles';


const BatteryPrepState = () => {
  const PAGE_NAME = 'battery_prep';
  const [userStoreState] = useStoreState(STORES.USER.NAME);
  const [, sendStateMachineEvent] = useStateMachine({});

  useEffect(() => {
    analyticsPageView(PAGE_NAME);
  }, []);

  const goToNextState = () => {
    sendStateMachineEvent(STATE_EVENTS.COMMON.NEXT);
  }

  return (
    <Container className = { css([commonStyles.simpleFlex, commonStyles.fullWidthCenter]) }>
      <PrepStateCheckIn 
        clickedBeginTest = { goToNextState }
        currentPage = { PAGE_NAME }
        isTester = { isTester(userStoreState) }
      />
    </Container>
  );
}


export default BatteryPrepState;

