
import * as Cookies from 'js-cookie';

import {
  STORES,
} from '../constants';


class InitialPath {
  constructor(path, params) {
    this.path = path;
    this.params = params;
  }

  convertToCookieString() {
    return JSON.stringify({path: this.path, params: this.params});
  }

  static convertFromCookieString(cookieString) {
    let jsonObj = JSON.parse(cookieString);
    return new InitialPath(jsonObj.path, jsonObj.params);
  }
}

// COOKIE store is delimited using `{{cook_delim}}`
const cookieDelim = '{{cook_delim}}';

const clearAllCookiePaths = () => {
  Cookies.remove(STORES.APP.COOKIE_INITIAL_PATHS_STACK_KEY);
}

const setNewCookiePath = (path) => {

  let cookiePathsString = Cookies.get(STORES.APP.COOKIE_INITIAL_PATHS_STACK_KEY);
  let cookiePaths = [];

  if (cookiePathsString) {
    cookiePaths = cookiePathsString.split(cookieDelim);
  }

  cookiePaths.push(path.convertToCookieString());
  let mutatedPathsString = cookiePaths.join(cookieDelim);
  
  Cookies.set(STORES.APP.COOKIE_INITIAL_PATHS_STACK_KEY, mutatedPathsString);

  return cookiePaths.map(cookiePath => InitialPath.convertFromCookieString(cookiePath));
}

const removePathFromCookie = (path) => {
  let cookiePathsString = Cookies.get(STORES.APP.COOKIE_INITIAL_PATHS_STACK_KEY);
  let cookiePaths = [];

  if (cookiePathsString) {
    cookiePaths = cookiePathsString.split(cookieDelim);
  }

  if (!cookiePaths) {
    console.error(`No cookie paths found to remove: ${JSON.stringify(path)}`);
    return [];
  }

  cookiePaths = cookiePaths.filter(cookiePath => cookiePath !== path.convertToCookieString());

  let mutatedPathsString = cookiePaths.join(cookieDelim);
  Cookies.set(STORES.APP.COOKIE_INITIAL_PATHS_STACK_KEY, mutatedPathsString);

  return cookiePaths.map(cookiePath => InitialPath.convertFromCookieString(cookiePath));
}

export {
  InitialPath,
  clearAllCookiePaths,
  removePathFromCookie,
  setNewCookiePath,
}
