
import React, { 
  useEffect 
} from 'react';

import { 
  useStateMachine,
} from '../../statemachine';

import { 
  analyticsPageView,
} from '../../segment/analytics';

import { 
  STATE_EVENTS,
} from '../../constants';

import ReloginComponent from '../ReloginComponent';

// TODO: Handle Login Issues
const AppLoginErrorState = () => {
  const PAGE_NAME = 'login_fail';
  const login = () => {
    sendStateMachineEvent(STATE_EVENTS.APP.LOGIN);
  }

  const [, sendStateMachineEvent] = useStateMachine({});
  
  useEffect(() => {
    analyticsPageView(PAGE_NAME);
  }, []);

  return ( 
    <ReloginComponent
      message = 'Login Failed'
      loginCallback = { login }
      id = { 'login_fail_relogin' }
      currentPage = { PAGE_NAME }
      extraMainDivStyles = { [] }
    />
  )
}
  
export default AppLoginErrorState;

