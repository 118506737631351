/* eslint no-undef: 0 */

import merge from 'lodash/merge';

const devConfig = {
    development: true,
    env: 'development',
    locale: 'en_us',
    use_jwt_auth: false,
    can_store_cookie: true,
}


const stagingConfig = {
    development: true,
    env: 'staging',
}

const productionConfig = {
    development: false,
    env: 'production',
}

let finalConfig = devConfig;

switch(window.REACT_APP_ENVIRONMENT) {
    case 'staging':
        finalConfig = merge({}, devConfig, stagingConfig);
        break;
    case 'production':
        finalConfig = merge({}, devConfig, productionConfig);
        break;
    default:
        break;
}

console.log(finalConfig);

export const sharedConfig = { ...finalConfig };
