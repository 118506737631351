
import React, { useEffect } from 'react';

import Container from '@material-ui/core/Container';
import { css, StyleSheet } from 'aphrodite/no-important';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import {
  LocalizedStrings,
} from '../../locale';

import {
  STORES,
  STATE_EVENTS,
} from '../../constants';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  getUserFirstName,
} from '../../stores/user_store_getters';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import { 
  updateBatteryData
} from '../../actions/battery_actions';

import {
  getCurrentUserStateBatteryRunId,
  getFutureBattery,
} from '../../stores/user_store_getters';

import {
  convertToCurrentUserTimeZone,
  diffTime,
  getCurrentUTCString,
  readableFormatWithHourAndMinute,
} from '../../utils/time_utils';

import {
  backToLumos,
} from '../../utils/link_utils';

import commonStyles from '../commonStyles';
import PageTitleSubheader from '../subheader/PageTitleSubheader';
import BatteryTextContainer from './BatteryTextContainer';

const BatteryNotStartedState = () => {

  const PAGE_NAME = 'battery_locked_out';

  const updateUserStore = (newUserStoreState) => {
    userStoreState = newUserStoreState;
  }

  // Hooks
  let [userStoreState] = useStoreState(STORES.USER.NAME, updateUserStore);
  
  let [, sendStateMachineEvent] = useStateMachine({});
  
  const adminOnlyQuit = () => {
    sendStateMachineEvent(STATE_EVENTS.COMMON.NEXT);
  }

  const goToResultsHistory = () => {
    sendStateMachineEvent(STATE_EVENTS.BATTERY.RESULTS_HISTORY);
  }

  const goToLumosity = () => {
    backToLumos();
  }

  const loadFutureBatteryIfAny = async () => {
    let futureBatteryId = getCurrentUserStateBatteryRunId(userStoreState);
    if (futureBatteryId) {
      await updateBatteryData(userStoreState, futureBatteryId);
    }
  }

  useEffect(() => {
    analyticsPageView(PAGE_NAME);
    loadFutureBatteryIfAny();
  }, [])

  const userName = getUserFirstName(userStoreState);
  const futureBattery = getFutureBattery(userStoreState);
  let futureText = LocalizedStrings.batteryNotStartedBody;
  let studyBeginTime = '';
  let studyBeginDays = 0;
  if (futureBattery) {
    studyBeginTime = readableFormatWithHourAndMinute(
      convertToCurrentUserTimeZone(futureBattery.battery_begin_time)
    );
    studyBeginDays = diffTime(futureBattery.battery_begin_time,
      convertToCurrentUserTimeZone(getCurrentUTCString()),
      'days');
  }

  return (
    <Container className = { css([commonStyles.fullWidthCenter, styles.notStartedContainer]) }>
      <PageTitleSubheader 
        title= { LocalizedStrings.batteryNotStartedHeader } 
        userName = { userName }
      />
      <BatteryTextContainer
        batteryBodyText = { futureText }
        studyBeginTime = { studyBeginTime }
        studyBeginDays = { studyBeginDays }
        userName = { userName }
        showAdminOnlyQuit = { true }
        onResultsHistoryClick = { goToResultsHistory }
        adminOnlyQuit = { adminOnlyQuit }
        showActionButton = { true }
        actionButtonText = { 'Back To Lumosity' }
        actionButtonClicked = { goToLumosity }
        currentPage = { PAGE_NAME }
        wideButtonId = { 'battery_locked_out_back' }
      />
    </Container>
  );
}

const styles = StyleSheet.create({
  notStartedContainer: {
    height: '805px',
    margin: 0,
    padding: 0,
  }
});



export default BatteryNotStartedState;

