
import React from 'react';
import Container from '@material-ui/core/Container';

import { css, StyleSheet } from 'aphrodite';
import { RoundedButton } from './lumos_common/buttons';

import { 
  analyticsTrack,
} from '../react-shared/src/segment/analytics';

import commonStyles from './commonStyles';

const WideActionButton = (props) => {
  return (
    <Container className={ css(commonStyles.flexJustifyCenter) }>
      <RoundedButton
        styles = { [commonStyles.action, styles.wideAction] }
        value = { props.buttonText }
        onClick = {e => {
          analyticsTrack('button_click', {
            id: props.id,
            current_page: props.currentPage,
            message: props.buttonText,
          });
          props.onClick(e);
          e.preventDefault();
        }}
        disabled = { props.disabled }
      />
    </Container>
  );
}

const styles = StyleSheet.create({
  wideAction: {
    width: 327,
    height: 56,
  },
});

export default WideActionButton;
