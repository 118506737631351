export const orange7 = '#AB3C21';
export const orange6 = '#C94727'; // aka darkOrange
export const orange5 = '#DD5832';
export const orange4 = '#F1693C';
export const orange3 = '#FF793F';
export const orange2 = '#FF9357';
export const orange1 = '#FFA370';

const orange = {
  7: orange7,
  6: orange6,
  5: orange5,
  4: orange4,
  3: orange3,
  2: orange2,
  1: orange1
};

export default orange;