
import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { css, StyleSheet } from 'aphrodite';

import { 
  analyticsTrack,
} from '../react-shared/src/segment/analytics';

import { base, gray2 } from './lumos_common/colors';
import commonStyles from './commonStyles';

import blueCheckMark from '../assets/blue_checkmark.png';

const WideCheckBox = (props) => {
  
  return (
    <Container 
      className={ css([commonStyles.flexRowAllCenter, styles.checkBoxContainer]) }
      onClick = {
        (e) => {
          analyticsTrack('card_click', {
            id: props.id,
            current_page: props.currentPage,
            message: props.text,
            type: props.selected ? 'unchecked' : 'checked', // intentionally opposite as the toggle happens after
          });
          props.onClick();
          e.preventDefault();
        }
    }>
      <Container className = { css([commonStyles.flexJustifyCenter, styles.checkBox]) }>
        { 
          props.selected && 
          <img 
            src = { blueCheckMark } 
            className = { css(styles.checkImage) }
          />
        }
      </Container>
      <Container className = { css([commonStyles.flexJustifyCenter, styles.textContainer]) }>
        <Typography  className = { css(commonStyles.wideText) }>
        { props.text }
        </Typography>
      </Container>
    </Container>
  );
}

const styles = StyleSheet.create({
  checkBoxContainer: {
    backgroundColor: gray2,
    width: 640,
    height: 64,
    marginTop: 20,
    cursor: 'pointer',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    userSelect: 'none',
  },
  checkImage: {
    height: 22,
    width: 24,
  },
  checkBox: {
    backgroundColor: base.lumosWhite,
    width: 32,
    height: 32,
    marginLeft: 10,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    ':focus': {
      outline: 'none'
    },
    ':hover': {
      backgroundColor: '#eee',
      color: base.lumosBlack,
    },
    ':active': {
      backgroundColor: '#d5d5d5'
    }
  },
  wideText: {
    color: base.lumosBlack,
    fontFamily: "MuseoSans500",
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: '1px',
    userSelect: 'none',
  }
});

export default WideCheckBox;
