
import React, { 
  useState,
  useEffect,
} from 'react';

import { css, StyleSheet } from 'aphrodite/no-important';
import Container from '@material-ui/core/Container';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  getCurrentBatteryAssessmentIndex,
  getNumAssessmentsInCurrentBattery,
} from '../../stores/user_store_getters';

import {
  STORES,
} from '../../constants';

import commonStyles from '../commonStyles';
import AssessmentStageViewComponent from '../assessment_states/AssessmentStageViewComponent';

const AssessmentStageViewSubheader = () => {

  const setStageData = (userStoreState) => {
    let batteryIndex = getCurrentBatteryAssessmentIndex(userStoreState);
    let numAssessments = getNumAssessmentsInCurrentBattery(userStoreState);
    setCurrentStage(batteryIndex);
    setNumStages(numAssessments);
  }

  const updateUserStore = (newUserStoreState) => {
    userStoreState = newUserStoreState;
    setStageData(userStoreState);
  }
  
  let [userStoreState] = useStoreState(STORES.USER.NAME, updateUserStore);
  const [numStages, setNumStages] = useState(1);
  const [currentStage, setCurrentStage] = useState(-1);

  useEffect(() => {
    setStageData(userStoreState);
  }, []);

  if (currentStage < 0) {
    return (null);
  }

  return (
    <Container className = { css([commonStyles.flexColumnAllCenter, styles.subheader]) }>
      <Container className= { css([commonStyles.flexRowAllCenter, styles.assessmentStageViewContainer])  }>
        <AssessmentStageViewComponent 
          numStages = { numStages }
          currentStage = { currentStage }
        />
      </Container>
    </Container>
  )
}

const styles = StyleSheet.create({
  subheader: {
    backgroundColor: "#F3F3F3",
    height: '66px',
    padding: 0,
    margin: 0,
    width: '100%',
    maxWidth: '100%',
  },
  assessmentStageViewContainer: {
    paddingTop: 20,
    flex: 1,
  },
});

export default AssessmentStageViewSubheader;
