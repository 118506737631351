import { 
  useEffect 
} from 'react';

import { Machine, interpret } from 'xstate';

import { STATE_MACHINE } from '../constants';
import { 
  flattenKeyValueIntoArray,
} from '../utils/obj_utils';

const APP_STATE_MACHINE_NAME = STATE_MACHINE.NAMES.APP_STATE_MACHINE;

const stateMachines = {};

class StateMachine {
  constructor(name, config, service) {
    this.name = name;
    this.stateMachineConfig = config;
    this.service = service;
    this.subscriptions = [];

    this.service.onTransition(state => {
      if (state.changed) {
        // console.log('STATE CHANGED: ', state.value);
      }
      state.flattenedValue = flattenKeyValueIntoArray(state.value)[0];
      
      // Send subscriptions from the most recent sub to latest
      // Bottom up FTW
      this.subscriptions.reverse().forEach(sub => sub(state));
    });
  }

  getState() {
    return this.service.state;
  }

  getSend() {
    return this.service.send;
  }

  subscribe(updateCallback) {
    if (!updateCallback) { return }
    this.subscriptions.push(updateCallback)
  }

  unsubscribe(updateCallback) {
    if (!updateCallback) { return }
    this.subscriptions = this.subscriptions.filter(sub => sub !== updateCallback)
  }
}

const registerStateMachine = (newStateMachineName, newStateMachineConfig) => {
  // console.log(JSON.stringify(newStateMachineConfig));

  const stateMachine = Machine(newStateMachineConfig);
  const stateMachineService = interpret(stateMachine);
  stateMachines[newStateMachineName] = new StateMachine(newStateMachineName, stateMachine, stateMachineService);

  // Don't forget to start the machine when you need to.
  return [stateMachine, stateMachineService];
}

// Use as a Hook
const useStateMachine = ({ 
  name = APP_STATE_MACHINE_NAME,
  updateCallback = undefined,
}) => {
  const stateMachine = stateMachines[name];
  
  useEffect(() => {
    stateMachine.subscribe(updateCallback);
    return () => stateMachine.unsubscribe(updateCallback);
  }, []);

  return [stateMachine.getState(), stateMachine.getSend()];
}

// Use in Class Components
const subscribeToStateMachine = ({ 
  name = APP_STATE_MACHINE_NAME,
  updateCallback = undefined
}) => {
  const stateMachine = stateMachines[name];
  stateMachine.subscribe(updateCallback);
}

const unsubscribeToStateMachine = ({ 
  name = APP_STATE_MACHINE_NAME,
  updateCallback = undefined
}) => {
  const stateMachine = stateMachines[name];
  stateMachine.unsubscribe(updateCallback);
}

const sendStateMachineEvent = ({ 
  event, 
  name = APP_STATE_MACHINE_NAME
}) => {
  if (!event) { return }
  const stateMachine = stateMachines[name];
  stateMachine.getSend()(event);
}

export {
  StateMachine,
  registerStateMachine,
  sendStateMachineEvent,
  subscribeToStateMachine,
  unsubscribeToStateMachine,
  useStateMachine,
}  
