
import get from 'lodash/get';

import {
  STORES,
} from '../constants';

import {
  setAdminShowLib,
  setNewAssessmentRun,
  updateAssessmentRun,
} from '../stores/user_store';

import {
  returnSuccess,
  returnError,
} from '../utils/obj_utils';

import { 
  analyticsAssessmentEvent,
} from '../segment/analytics';

import requests from '../routes/backend_routes';
import rollbar from '../react-shared/src/logging/rollbar';

const createAssessment = async function(userStore, assessmentConfig) {
  try {
    const response = await requests.post('core', 'assessment.create_run', { 
      assessment: assessmentConfig,
    });
    const newAssessmentRun = await response.data;
    setNewAssessmentRun(userStore, newAssessmentRun);
    return returnSuccess();
  } catch (err) {
    rollbar.error(`Error while ceating assessment 
      config: ${JSON.stringify(assessmentConfig)},
      err: ${err}
      stack: ${err.stack}`);
    return returnError(err);
  }
};

const fetchAssessmentForBatteryRun = async function(userStore, assessmentRunId) {
  try {
    const response = await requests.put('core', 'assessment.run', {
      assessment_data: {
        event_name: 'startBatteryAssessmentRun',
      }
    }, { '{:id}': assessmentRunId });
    const batteryAssessmentRun = await response.data;
    // TODO: move mutation to action
    updateAssessmentRun(userStore, batteryAssessmentRun);
    return returnSuccess();
  } catch (err) {
    rollbar.error(`Error while fetching assessment for battery run: 
      id: ${assessmentRunId},
      err: ${err}
      stack: ${err.stack}`);
    return returnError(err);
  }
};

const parseAssessmentEventData = async (data) => {
  let eventName = '';
  let eventData = {}
  if (data) {
    if (typeof data === 'string') {
      eventName = data;
    } else if (Array.isArray(data)) {
      eventName = data[0];
      eventData = data[1];
    }
  }

  return {
    eventName,
    eventData,
  }
}

const handleEventActions = async (eventName, eventData, currentAssessmentRun, userStore) => {
  sendAssessmentSegmentEvents(eventName, eventData, currentAssessmentRun);

  const allowedEvents = ['game:start', 'game:loadComplete', 'game:complete'];
  if (eventData) {
    const assessmentUpdateData = createAssessmentDataObject(currentAssessmentRun, eventName, eventData);
    try {
      if (allowedEvents.includes(eventName)) {
        const submitEventDataResponse = await requests.put('core', 'assessment.run',
          { assessment_data: assessmentUpdateData },
          { '{:id}': assessmentUpdateData.id });
        updateAssessmentRun(userStore, submitEventDataResponse.data);
      }
    } catch(err) {
      rollbar.error(`ERROR while handlign event actions data: ${err},
       event Name: ${eventName},
       event data: ${eventData},
       assessment run: ${JSON.stringify(currentAssessmentRun)}
       stack: ${err.stack}`);
    }
  }
}
  
const adminSkipGame = async (userStore, assessmentRunId) => {
  try {
    const skipAssessmentResponse = await requests.put('core', 'assessment.run', { 
      assessment_data: {
        run_data: {
          stat: 5,
          data: {
            reason: 'Admin Skip',
          }
        },
        event_name: "game:complete"
      }
    }, { '{:id}': assessmentRunId });
    updateAssessmentRun(userStore, skipAssessmentResponse.data);
  } catch(err) {
    rollbar.error(`ERROR while admin skip assessment data: ${err} stack: ${err.stack}`);
  }
}

const adminShowLib = () => {
  setAdminShowLib();
}

/// PRIVATE

const createAssessmentDataObject = (assessmentRunData, eventName, eventData) => {
  const assessmentData = Object.assign({}, assessmentRunData);
  assessmentData['run_data'] = eventData;
  assessmentData['event_name'] = eventName;
  return assessmentData;
}

const sendAssessmentSegmentEvents = (eventName, eventData, currentAssessmentRun) => {
  const segmentAssessmentEvents = {
    'game:loadStart': 'assessment_load',
    // 'game:loadProgress',
    'game:loadComplete': 'assessment_load_finished',
    'game:abort_update': 'assessment_update',
    'game:start': 'assessment_start',
    'game:complete': 'assessment_finish',
  };
  if (!Object.keys(segmentAssessmentEvents).includes(eventName)) {
    return;
  }

  if (!(eventData instanceof Object)) {
    eventData = {};
  }
  let assessmentSlug = get(currentAssessmentRun, STORES.USER.CURRENT_ASSESSMENT_RUN_SLUG_KEY, '');
  analyticsAssessmentEvent(segmentAssessmentEvents[eventName], assessmentSlug, { assessmentData: eventData });
}

export {
  adminShowLib,
  adminSkipGame,
  createAssessment,
  fetchAssessmentForBatteryRun,
  handleEventActions,
  parseAssessmentEventData,
};
