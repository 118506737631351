import { useEffect, useState } from 'react';

import set from 'lodash/set';
import unset from 'lodash/unset';

const stores = {}

class Store {
  constructor(name, state) {
    this.name = name;
    this.state = state;
    this.subscriptions = [];
  }

  _mutate(key, value) {
    unset(this.state, key);
    set(this.state, key, value);
    this.state = Object.assign({}, this.state)
  }

  mutate(key, value) {
    this._mutate(key, value);
    this.updateSubs();
  }

  mutateMultiple(keyValueDict) {
    for (let [key, value] of Object.entries(keyValueDict)) {
      this._mutate(key, value);
    }
    this.updateSubs();
  }

  updateSubs() {
    // Send subscriptions from the most recent sub to latest
    // Bottom up FTW
    this.subscriptions.reverse().forEach(sub => {
      sub(this.state)
    })
  }

  getState() {
    return this.state;
  }

  subscribe(updateCallback) {
    if (!updateCallback) { return }
    this.subscriptions.push(updateCallback)
  }

  unsubscribe(updateCallback) {
    if (!updateCallback) { return }
    this.subscriptions = this.subscriptions.filter(sub => sub !== updateCallback)
  }
}


const createStore = (name, initialState) => {
  let state = { ...initialState } || {};

  stores[name] = new Store(name, state);
}

// Use as a Hook
const useStoreState = (name, updateCallback) => {
  const store = stores[name];
  const [state] = useState(store.getState())

  useEffect(() => {
    store.subscribe(updateCallback);

    return () => store.unsubscribe(updateCallback);
  }, []);

  return [state];
}

// Use in Class Components
const subscribeToStore = (name, updateCallback) => {
  const store = stores[name];
  store.subscribe(updateCallback);
}

const unsubscribeToStore = (name, updateCallback) => {
  const store = stores[name];
  store.unsubscribe(updateCallback);
}

const mutateStore = (name, key, value) => {
  const store = stores[name];
  store.mutate(key, value);
}

const mutateStoreMultiple = (name, keyValueDict) => {
  const store = stores[name];
  store.mutateMultiple(keyValueDict);
}

export {
  createStore,
  mutateStore,
  mutateStoreMultiple,
  subscribeToStore,
  unsubscribeToStore,
  useStoreState,
}
