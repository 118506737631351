
import Rollbar from 'rollbar';
import get from 'lodash/get';

let config = window.globalConfig || {};

let rollbar = console;

if (get(config, 'features_config.allow_rollbar')) {
  rollbar = new Rollbar({
    accessToken: config.services.rollbar.token,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: config.env,
      version: config.version,
    }
  });
}

export default rollbar;
