
import isEmpty from 'lodash/isEmpty';

import React, { 
  useState,
  useEffect,
} from 'react';

import Container from '@material-ui/core/Container';
import { css, StyleSheet } from 'aphrodite/no-important';
import commonStyles from '../commonStyles';

import { 
  useStateMachine,
} from '../../react-shared/src/statemachine';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  getAdminLibSetting,
  getCurrentBattery,
} from '../../stores/user_store_getters';

import {
  STORES,
  STATES,
  STATE_EVENTS,
} from '../../constants';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import {
  adminSkipGame,
} from '../../actions/assessment_actions';

import AssessmentCreateState from './AssessmentCreateState';
import AssessmentPlayState from './AssessmentPlayState';
import AssessmentScoreState from './AssessmentScoreState';
import AssessmentStageViewSubheader from '../subheader/AssessmentStageViewSubheader';

import { config } from '../../config/config';
const AssessmentStateMachine = () => {
  
  const updateStateMachineState = function(newStateMachineState) {
    stateMachineState = Object.assign({}, newStateMachineState);
    setStateMachineState(stateMachineState);
  }

  // Hooks
  let [userStoreState] = useStoreState(STORES.USER.NAME);
  let [smState, sendStateMachineEvent] = useStateMachine({ 
    updateCallback: updateStateMachineState
  });
  
  let [stateMachineState, setStateMachineState] = useState(smState);
  let [started, setStarted] = useState(false);

  useEffect(() => {
    analyticsPageView(userStoreState.currentAssessmentConfig.game_slug);
    setStarted(true);
  }, [])
  
  const goToPlayState = () => {
    if (stateMachineState.flattenedValue.includes(STATES.ASSESSMENT.CREATE)) {
      sendStateMachineEvent(STATE_EVENTS.ASSESSMENT.CREATED);
    }
  }

  const scoreStateCompleted = () => {
    // TODO: Battery Based Quit
    if (stateMachineState.flattenedValue.includes(STATES.ASSESSMENT.SCORE)) {
      if (!isEmpty(getCurrentBattery(userStoreState)) && !getAdminLibSetting(userStoreState)) {
        sendStateMachineEvent(STATE_EVENTS.LOGGED_IN.BATTERY_PLAY_COMPLETE);
      } else {
        sendStateMachineEvent(STATE_EVENTS.ASSESSMENT.QUIT);
      }
    }
  }

  const playStateQuit = () => {
    // TODO: Battery Based Quit
    if (stateMachineState.flattenedValue.includes(STATES.ASSESSMENT.PLAY)) {
      // adminShowLib();
      sendStateMachineEvent(STATE_EVENTS.ASSESSMENT.QUIT);
    }
  }

  const skipGame = async (assessmentRunId) => {
    // TODO: Battery Based Quit
    if (config.development) {
      await adminSkipGame(userStoreState, assessmentRunId);
      sendStateMachineEvent(STATE_EVENTS.ASSESSMENT.COMPLETED);
    }
  }

  if (!started) {
    return null;
  }

  return ( 
    <Container className = { css([commonStyles.fullWidthCenter, styles.assessmentSMContainer]) }>
      <AssessmentStageViewSubheader />
      {
        stateMachineState.flattenedValue.includes(STATES.ASSESSMENT.CREATE) &&
        <AssessmentCreateState 
          id = { userStoreState.currentAssessmentConfig.id } 
          startPracticeClicked = { goToPlayState }
        />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.ASSESSMENT.PLAY) &&
        <AssessmentPlayState
          playStateQuit = { playStateQuit }
          skipGame = { skipGame }
        />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.ASSESSMENT.SCORE) &&
        <AssessmentScoreState 
          scoreStateCompleted = { scoreStateCompleted }
        />
      }
    </Container>
  );
}

const styles = StyleSheet.create({
  assessmentSMContainer: {
    margin: 0,
    padding: 0,
    height: '805px',
  }
})

export default AssessmentStateMachine;
