
import React, {
  useEffect 
} from 'react';

import { 
  useStateMachine,
} from '../../react-shared/src/statemachine';

import { 
  logoutUser,
} from '../../actions/app_actions';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import { 
  STATE_EVENTS,
} from '../../constants';

import ReloginComponent from '../../react-shared/src/components/ReloginComponent';

const AppLoggedOutState = () => {
  const PAGE_NAME = 'logged_out';
  const [, sendStateMachineEvent] = useStateMachine({});
  
  const callLogout = async function() {
    await logoutUser();
  }

  const login = () => {
    sendStateMachineEvent(STATE_EVENTS.APP.LOGIN);
  }

  useEffect(() => {
    analyticsPageView(PAGE_NAME);
    callLogout();
  }, [])

  // TODO: HANDLE RELOGIN
  return ( 
    <ReloginComponent 
      message = 'You have been logged out.'
      loginCallback = { login }
      id = { 'logged_out_relogin' }
      currentPage = { PAGE_NAME }
      extraMainDivStyles = { [] }
    />
  )
}
  
export default AppLoggedOutState;
