import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';

import { css, StyleSheet } from 'aphrodite';

import { base } from '../lumos_common/colors';
import commonStyles from '../commonStyles';

import WideActionButton from '../WideActionButton';
import AssessmentStageViewComponent from './AssessmentStageViewComponent';

const AssessmentStartCardComponent = (props) => {

  return (
    <Container className = { css([commonStyles.flexRowAllCenter, styles.startCardContainer]) }>
      <Card className = { css([commonStyles.flexColumnAllCenter, styles.startCardStyle]) } >
        <Container className= { css([commonStyles.flexRowAllCenter, styles.assessmentStageViewContainer])  }>
          { props.numStages > 1 &&
            <AssessmentStageViewComponent 
              numStages = { props.numStages }
              currentStage = { props.currentStage }
            />
          }
        </Container>
        <Container className = { css([commonStyles.flexColumnAllCenter, styles.textContainer]) }>
          <Container className = { css(commonStyles.flexJustifyCenter)}>
            <Typography className = { css(styles.stageTitleText) }>
              { props.assessmentStageText }
            </Typography>
          </Container>
          <Container className = { css([commonStyles.flexJustifyCenter, commonStyles.titleContainer]) }>
            <Typography  className = { css(commonStyles.title) }>
                { props.assessmentTitle }
            </Typography>
          </Container>
        </Container>
        <Container className = { css([commonStyles.flexRowAllCenter, styles.actionContainer])  }>
          <WideActionButton 
            buttonText = { props.actionButtonText }
            onClick = { props.actionButtonClicked }
            id = { 'assessment_start' }
            currentPage = { props.currentPage }
          />
        </Container>
      </Card>
    </Container>
  );
}


const styles = StyleSheet.create({
  startCardContainer: {
    height: '600px',
  },
  startCardStyle: {
    justifyContent: 'space-around',
    height: '400px',
    width: 384,
  },
  textContainer: {
    flex: 2,
  },
  stageTitleText: {
    color: base.green,
    fontFamily: "MuseoSans500",
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: '1px',
  },
  startText: {
    color: base.lumosBlack,
    fontFamily: "MuseoSans500",
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: '1px',
  },

  //// Check Icon
  assessmentStageViewContainer: {
    paddingTop: 20,
    flex: 1,
  },
  actionContainer: {
    paddingBottom: 20,
    flex: 1,
  }
});

export default AssessmentStartCardComponent;
