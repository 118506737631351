

import React, {
  useState,
  useEffect,
} from 'react';

import { css, StyleSheet } from 'aphrodite/no-important';
import Container from '@material-ui/core/Container';

import commonStyles from '../commonStyles';
import WideActionButton from '../WideActionButton';
import WideCheckBox from '../WideCheckBox';
import SkipComponent from '../SkipComponent';
import PageTitleSubheader from '../subheader/PageTitleSubheader';

const initialCheckBoxes = [
  {
    text: 'Make sure you\'re feeling well rested.',
    selected: false,
  }, {
    text: 'Give yourself 15 - 30 uninterrupted minutes in a quiet room.',
    selected: false,
  }, {
    text: 'Find a table and chair to work from.',
    selected: false,
  }, {
    text: 'Complete the test without help from others.',
    selected: false,
  }, {
    text: 'Don\'t take notes or write anything down.',
    selected: false,
  },
]

// Dropdown needs access to the DOM node in order to position the Menu
const PrepStateCheckIn = (props) => {

  const [checkBoxes, setCheckBoxes] = useState(initialCheckBoxes.map(checkBox => checkBox.selected));
  const [continueButtonEnabled, setContinueButtonEnabled] = useState(false);

  const setCheckBoxStateIndex = (index) => {
    let boxes = checkBoxes.slice();
    boxes[index] = !boxes[index];
    setCheckBoxes(boxes);
    setContinueButtonEnabled(boxes.every(b => b));
  }

  useEffect(() => {
    setContinueButtonEnabled(false);
  }, []);

  const checkBoxContainers = initialCheckBoxes.map((checkBoxData, index) => {
    return (
      <WideCheckBox 
        key={'check_' + index}
        text={checkBoxData.text}
        selected={checkBoxes[index]}
        onClick={() => setCheckBoxStateIndex(index)}
        id = { 'prep_check_' + index }
        currentPage = { props.currentPage }
      />
    );
  });
  
  return (
    <Container className = { css([commonStyles.fullWidthCenter, styles.prepContainer]) }>
      <PageTitleSubheader 
        title= { 'Ready To Begin?' }
      />
      <Container className = { css([commonStyles.fullWidthCenter, commonStyles.flexColumnAllCenter]) }>
        <Container className = { css([commonStyles.flexColumnAllCenter, styles.checkBoxContainer]) }>
          { checkBoxContainers }
        </Container>
        <Container className = { css([commonStyles.flexColumnAllCenter, styles.actionContainer]) }>
            <WideActionButton 
              disabled = { !continueButtonEnabled }
              buttonText = { 'Begin Test' }
              onClick = { props.clickedBeginTest }
              id = { 'prep_begin' }
              currentPage = { props.currentPage }
            />
        </Container>
      </Container>
      { props.isTester &&
        <SkipComponent 
          onClick = { props.clickedBeginTest }
          message = { 'Admin Skip' }
        />
      }
    </Container>
  )
}

const styles = StyleSheet.create({
  prepContainer: {
    height: '805px',
    margin: 0,
    padding: 0,
  },
  checkBoxContainer: {
    marginTop: 20,
  },
  actionContainer: {
    marginTop: 40,
  },
});

export default PrepStateCheckIn;
