

import { 
  STATES,
  STATE_EVENTS,
  STATE_MACHINE,
} from '../constants';
import { assessmentStates } from './assessment_states';
import { libraryStates } from './library_states';
import { batteryStates } from './battery_states';

const LOGGED_IN_STATES = STATES.LOGGED_IN;
const LOGGED_IN_EVENTS = STATE_EVENTS.LOGGED_IN;

const assessmentState = {
  on: {},
  ...assessmentStates,
}
assessmentState.on[LOGGED_IN_EVENTS.BATTERY_PLAY_COMPLETE] = `#${STATE_MACHINE.NAMES.BATTERY_STATE_MACHINE_INTERSTITIAL}`;
assessmentState.on[LOGGED_IN_EVENTS.SINGLE_PLAY_COMPLETE] = `#${STATE_MACHINE.NAMES.LIBRARY_STATE_MACHINE}`;
assessmentState.on[STATE_EVENTS.ASSESSMENT.QUIT] = `#${STATE_MACHINE.NAMES.LIBRARY_STATE_MACHINE}`;

const batteryState = { 
  on: {}, 
  ...batteryStates,
}
batteryState.on[LOGGED_IN_EVENTS.LIBRARY] = LOGGED_IN_STATES.LIBRARY;

const decisionState = { on: {}, }
decisionState.on[LOGGED_IN_EVENTS.LIBRARY] = LOGGED_IN_STATES.LIBRARY;
decisionState.on[LOGGED_IN_EVENTS.BATTERY] = LOGGED_IN_STATES.BATTERY;
decisionState.on[LOGGED_IN_EVENTS.BATTERY_RESULTS_HISTORY] = `#${STATE_MACHINE.NAMES.BATTERY_STATE_MACHINE_RESULTS_HISTORY}`;
decisionState.on[LOGGED_IN_EVENTS.BATTERY_SUMMARY] = `#${STATE_MACHINE.NAMES.BATTERY_STATE_MACHINE_SUMMARY}`;
decisionState.on[LOGGED_IN_EVENTS.SELF] = LOGGED_IN_STATES.DECISION;
decisionState.on[LOGGED_IN_EVENTS.SURVEY] = LOGGED_IN_STATES.SURVEY;
decisionState.on[LOGGED_IN_EVENTS.WELCOME] = LOGGED_IN_STATES.WELCOME;

const initState = { on: {}, }
initState.on[STATE_EVENTS.COMMON.NEXT] = LOGGED_IN_STATES.DECISION;

const libraryState = {
  on: {},
  ...libraryStates,
};
libraryState.on[LOGGED_IN_EVENTS.PLAY] = LOGGED_IN_STATES.ASSESSMENT;

const surveyState = { on: {}, }
surveyState.on[STATE_EVENTS.COMMON.NEXT] = LOGGED_IN_STATES.DECISION;

const welcomeState = { on: {}, }
welcomeState.on[STATE_EVENTS.COMMON.NEXT] = LOGGED_IN_STATES.DECISION;
welcomeState.on[LOGGED_IN_EVENTS.LIBRARY] = LOGGED_IN_STATES.LIBRARY;

const loggedInStates = {
  id: STATE_MACHINE.NAMES.LOGGED_IN_STATE_MACHINE,
  initial: LOGGED_IN_STATES.INIT,
  states: {},
};

loggedInStates.states[LOGGED_IN_STATES.ASSESSMENT] = assessmentState;
loggedInStates.states[LOGGED_IN_STATES.BATTERY] = batteryState;
loggedInStates.states[LOGGED_IN_STATES.DECISION] = decisionState;
loggedInStates.states[LOGGED_IN_STATES.INIT] = initState;
loggedInStates.states[LOGGED_IN_STATES.LIBRARY] = libraryState;
loggedInStates.states[LOGGED_IN_STATES.SURVEY] = surveyState;
loggedInStates.states[LOGGED_IN_STATES.WELCOME] = welcomeState;

export {
  loggedInStates
}
