
import {
  useEffect,
} from 'react';

import { 
  analyticsTrack,
} from '../../react-shared/src/segment/analytics';

import {
  useStateMachine,
} from '../../react-shared/src/statemachine';

import { 
  STATE_EVENTS,
} from '../../constants';

// Dummy Init State. This shouldn't really be changing.
const AppInitState = () => {
  let [, sendStateMachineEvent] = useStateMachine({});
  useEffect(() => {
    sendStateMachineEvent(STATE_EVENTS.COMMON.NEXT);
  }, []);
  return null;
}

export default AppInitState;

