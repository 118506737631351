
import { isSegmentAllowed } from '../react-shared/src/segment/analytics';
import { config } from '../config/config';

const analyticsAssessmentEvent = async function (assessmentEvent, assessmentSlug, extraProps={}) {
  if (!isSegmentAllowed()) { return; }
  window.analytics.track(assessmentEvent, {
    properties: { 
      ...extraProps,
      slug: assessmentSlug,
      version: config.version,
    },
  });
}

export {
  analyticsAssessmentEvent,
}
