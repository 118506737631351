
import React, {
  useEffect,
} from 'react';

import {
  STATE_EVENTS,
} from '../../constants';

import { 
  useStateMachine,
} from '../../react-shared/src/statemachine';

import { 
  ncptLogin,
} from '../../actions/app_actions';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import LoadingComponent from '../LoadingComponent';

const AppNCPTLoginState = () => {
  const [, sendStateMachineEvent] = useStateMachine({});
  
  const callNCPTAuth = async function() {
    const loginResult = await ncptLogin();
    if (loginResult.success) {
      sendStateMachineEvent(STATE_EVENTS.COMMON.SUCCESS);
    } else {
      sendStateMachineEvent(STATE_EVENTS.COMMON.ERROR);
    }
  }

  useEffect(() => {
    analyticsPageView("login_ncpt");
    callNCPTAuth();
  }, [])

  return ( 
    <div> 
      <LoadingComponent title="NCPT Login" />
    </div>
  );
}

export default AppNCPTLoginState;
