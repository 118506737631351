
import React, { 
  useEffect,
} from 'react';

import {
  STATE_EVENTS,
} from '../../constants';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import { 
  loadAssessments
} from '../../actions/library_actions';

import LoadingComponent from '../LoadingComponent';

function LibraryLoadingState() {
  const [, sendStateMachineEvent] = useStateMachine({});

  const handleAssessmentLoad = async () => {
    const result = await loadAssessments();

    if (result.success) {
      sendStateMachineEvent(STATE_EVENTS.LIBRARY.LOADED);
    } else {
      sendStateMachineEvent(STATE_EVENTS.COMMON.ERROR);
    }
  }
  
  useEffect(() => {
    analyticsPageView('loading_library');
    handleAssessmentLoad();
  }, []);

  return (
    <div> 
      <LoadingComponent title="Loading Assessments" />
    </div> 
  );
}

export default LibraryLoadingState;
