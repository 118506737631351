
import {
  useEffect,
} from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  getCurrentUserStateBatteryRunId,
  shouldShowWelcomeScreen,
} from '../../stores/user_store_getters';

import {
  setChosenBatterySummaryId,
} from '../../stores/user_store';

import {
  getInitialPath,
} from '../../react-shared/src/stores/app_store_getters';

import {
  removeAllInitialPaths,
  removeInitialPath,
} from '../../stores/app_store';

import { 
  useStateMachine,
} from '../../react-shared/src/statemachine';

import {
  STORES,
  STATE_EVENTS,
} from '../../constants';

import {
  addTime,
  checkTimeIsAfter,
} from '../../utils/time_utils';


const BATTERY_RESULTS_HISTORY_PATH = 'battery/history';
const BATTERY_SUMMARY_PATH = 'battery/summary/';

const LoggedInDecisionState = () => {
  // Hooks
  let [userStoreState] = useStoreState(STORES.USER.NAME);
  let [appStoreState] = useStoreState(STORES.APP.NAME);
  let [, sendStateMachineEvent] = useStateMachine({});

  useEffect(() => {
    let showLoginWelcomeScreen = shouldShowWelcomeScreen(userStoreState);

    let surveyData = get(userStoreState, STORES.USER.SURVEY_KEY, {});
    let shouldShowSurvey = false;
    if (!isEmpty(surveyData)) {
      if (!surveyData.last_seen) {
        shouldShowSurvey = true;
      } else {
        const lastSeenWithin24Hours = addTime(86400, surveyData.last_seen);
        if (checkTimeIsAfter(lastSeenWithin24Hours)) {
          shouldShowSurvey = true;
        }
      }
    }

    let initialPath = getInitialPath(appStoreState);

    if (initialPath && initialPath.path.includes(BATTERY_RESULTS_HISTORY_PATH)) {
      sendStateMachineEvent(STATE_EVENTS.LOGGED_IN.BATTERY_RESULTS_HISTORY);
      removeInitialPath(initialPath);
      return;
    } else if (initialPath && initialPath.path.includes(BATTERY_SUMMARY_PATH)) {
      let startIndex = initialPath.path.indexOf(BATTERY_SUMMARY_PATH);
      let initialPathSplit = (initialPath.path.substring(startIndex + BATTERY_SUMMARY_PATH.length)).split('/');
      
      if (initialPathSplit) {
        let batterySummaryId = initialPathSplit[0];
        removeInitialPath(initialPath);
        setChosenBatterySummaryId(batterySummaryId);
        sendStateMachineEvent(STATE_EVENTS.LOGGED_IN.BATTERY_SUMMARY);
        return;
      } else {
        sendStateMachineEvent(STATE_EVENTS.LOGGED_IN.SELF);
        return;
      }
    } else if (showLoginWelcomeScreen) {
      sendStateMachineEvent(STATE_EVENTS.LOGGED_IN.WELCOME);
      return;
    } else if (initialPath && initialPath.path.includes('catalog')) {
      sendStateMachineEvent(STATE_EVENTS.LOGGED_IN.LIBRARY);
      return;
    } else if (shouldShowSurvey) {
      sendStateMachineEvent(STATE_EVENTS.LOGGED_IN.SURVEY);
      return;
    } else if (getCurrentUserStateBatteryRunId(userStoreState)) {
      // sendStateMachineEvent(STATE_EVENTS.LOGGED_IN.LIBRARY);
      removeAllInitialPaths();
      sendStateMachineEvent(STATE_EVENTS.LOGGED_IN.BATTERY);
      return;
    } else {
      removeAllInitialPaths();
      sendStateMachineEvent(STATE_EVENTS.LOGGED_IN.BATTERY);
      return;
    }
  }, [])

  return null;
}

export default LoggedInDecisionState;

