
import React from 'react';

import get from 'lodash/get';

import { css, StyleSheet } from 'aphrodite';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import {FormattedMessage} from 'react-intl';

import {
  Link,
} from "react-router-dom";

import { config } from '../../config/config';

import { 
  analyticsTrack
} from '../../react-shared/src/segment/analytics';

import {
  LocalizedStrings,
} from '../../locale';

import lockIcon from '../../assets/lock_icon.png';
import commonStyles from '../commonStyles';

const PrivacyTextFooter = (props) => {
  const lumosPrivacyUrl = get(config, 'services.rails.lumosPrivacyUrl', 'https://lumosity.com');

  const redirectToLumosPrivacyPolicy = function() {
    window.location.href = lumosPrivacyUrl;
  }
  return (
    <Container className = { css([commonStyles.flexColumnAllCenter, styles.privacyFooter]) }>
      <hr className = { css(styles.horizontalLine) } />
      <Container className= { css([commonStyles.flexRowAllCenter, styles.logoContainer]) }>
        <img 
          src = { lockIcon } 
          className = { css(styles.lockImg) }  
        />
        <Typography className= { css(styles.title) }>
          { LocalizedStrings.privacyFooterHeader }
          <Link
            to = ""
            onClick = {e => {
              e.preventDefault();
              analyticsTrack('link_click', {
                id: 'terms_of_service',
                current_page: props.currentPage,
                next_page: lumosPrivacyUrl,
              });
              redirectToLumosPrivacyPolicy();
            }}
            replace
          >
            { LocalizedStrings.privacyFooterHeaderLink }
          </Link>
        </Typography>
      </Container>
      <Container className = { css([commonStyles.flexColumnAlignCenter, styles.bodyTextContainer]) }>
        { 
          LocalizedStrings.privacyFooterBody.map((msg, i) => {
            return (
              <div key = {"div_"+i}> 
                { 
                  i > 0 &&
                  <div className = { css(styles.divSpacing) } key = {"div_i_"+i}> <br key = {"br_i_1"+i}/> <br key = {"br_i_2"+i}/> </div>
                }
                <Typography 
                  className = { css([commonStyles.flexColumnAllCenter, styles.privacyBodyText]) }
                  key = {"body_"+i}
                >
                  <FormattedMessage
                    id= {"privacyBody_"+i}
                    key = {"privacyBody_"+i}
                    defaultMessage={msg }
                  />
                </Typography>
              </div>
            )
          })
        }
      </Container>
    </Container>
  )
}

const styles = StyleSheet.create({
  privacyFooter: {
    height: '200px',
    padding: 0,
    margin: 0,
    paddingTop: '42px',
    width: 1080,
  },
  horizontalLine: {
    height: 2,
    width: 640,
    color: '#D8D8D8',
  },
  logoContainer: {
    paddingBottom: 20,
  },
  lockImg: {
    height: 10,
    width: 20,
    margin: 0,
    padding: 0,
    paddingRight: 10,
  },
  title: {
    color: '#222222',
    fontFamily: 'MuseoSans700',
    fontSize: 18,
    letterSpacing: '1px',
  },
  bodyTextContainer: {
    display: 'flex',
    paddingTop: 10,
    width: 900,
  },
  divSpacing: {
    height: '10px',
  },
  privacyBodyText: {
    display: 'flex',
    color: '#4A4A4A',
    fontFamily: "MuseoSans500",
    fontSize: 20,
    letterSpacing: '1px',
    textAlign: 'center',
    width: 900,
  },
});

export default PrivacyTextFooter;
