

import React from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { css, StyleSheet } from 'aphrodite';

import Spinner from 'react-bootstrap/Spinner';

import { base } from './lumos_common/colors';
import commonStyles from './commonStyles';

const LoadingComponent = (props) => {
  return (
    <Container className = { css([commonStyles.flexColumnAllCenter, styles.loadingAnimContainer])}>
      <Container className = { css([commonStyles.flexColumnAllCenter, styles.titleContainer])}>
        <Typography className = { css(styles.loadingTitle) }>
          { props.title }
        </Typography>
        </Container>
      <Container className = { css([commonStyles.flexColumnAllCenter, styles.loadingContainer]) }>
        {/* <BlockLoading /> */}
        <Spinner animation="border" />
      </Container>
    </Container>
  )
}

const styles = StyleSheet.create({
  loadingAnimContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  titleContainer: {
    height: '200px',
  },
  loadingTitle: {
    color: base.lumosBlack,
    fontFamily: "MuseoSans500",
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: '1px',
  },

  loadingContainer: {
    height: '400px',
  },
});

export default LoadingComponent;
