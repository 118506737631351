
import { StyleSheet } from 'aphrodite/no-important';

import { base, gray } from './lumos_common/colors';

const commonStyles = StyleSheet.create({
  simpleFlex: {
    display: 'flex',
    padding: 0,
    margin: 0,
  },
  fullWidthCenter: {
    width: '100%',
    maxWidth: '100%',
  },
  flexRowAllCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
  },
  flexColumnAllCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
  },
  flexColumnAlignCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  flexColumnJustifyCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  flexJustifyCenter: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
  },
  flexAlignCenter: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: 0,
  },
  titleContainer: {
    marginBottom: 10,
  },
  title: {
    color: base.lumosBlack,
    fontFamily: "MuseoSans500",
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: '1px',
    display: 'flex',
  },
  widerCtasContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  ctasContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cta: {
    fontFamily: "MuseoSans500",
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: '1px',
  },
  action: {
    color: base.lumosWhite,
    backgroundColor: base.lumosOrange,
    ':focus': {
      outline: 'none'
    },
    ':hover': {
      backgroundColor: gray.grayLighter,
      color: base.lumosBlack,
    },
    ':active': {
      backgroundColor: '#d5d5d5'
    }
  },
});

export default commonStyles;
