
import React, { 
  useState,
  useEffect,
} from 'react';

import { 
  useStateMachine,
} from '../../react-shared/src/statemachine';

import {
  STATES,
} from '../../constants';

import Container from '@material-ui/core/Container';
import { css } from 'aphrodite/no-important';
import commonStyles from '../commonStyles';

import AssessmentStateMachine from '../assessment_states/AssessmentStateMachine';
import BatteryStateMachine from '../battery_states/BatteryStateMachine';
import LibraryStateMachine from '../library_states/LibraryStateMachine';
import LoggedInInitState from './LoggedInInitState';
import LoggedInDecisionState from './LoggedInDecisionState';
import SurveyState from './SurveyState';
import LoggedInWelcomeState from './LoggedInWelcomeState';


const AppLoggedInStateMachine = (props) => {
  
  const updateStateMachineState = function(newStateMachineState) {
    setStateMachineState(newStateMachineState);
  }

  // Hooks
  let [smState] = useStateMachine({ 
    updateCallback: updateStateMachineState
  });
  const [stateMachineState, setStateMachineState] = useState(smState);
  const [started, setStarted] = useState(false);

  useEffect(() => {
    setStarted(true);
  }, []);

  if (!started) {
    return (
      <div> LOGGED IN STATE </div>
    )
  }

  return ( 
    <Container className = { css([commonStyles.flexColumnAllCenter, commonStyles.fullWidthCenter]) }>
      {
        stateMachineState.flattenedValue.includes(STATES.LOGGED_IN.ASSESSMENT) &&
        <AssessmentStateMachine history={props.history}/>
      }
      {
        stateMachineState.flattenedValue.includes(STATES.LOGGED_IN.BATTERY) &&
        <BatteryStateMachine history={props.history}/>
      }
      {
        stateMachineState.flattenedValue.includes(STATES.LOGGED_IN.DECISION) &&
        <LoggedInDecisionState />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.LOGGED_IN.INIT) &&
        <LoggedInInitState />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.LOGGED_IN.LIBRARY) &&
        <LibraryStateMachine history={props.history}/>
      }
      {
        stateMachineState.flattenedValue.includes(STATES.LOGGED_IN.SURVEY) &&
        <SurveyState />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.LOGGED_IN.WELCOME) &&
        <LoggedInWelcomeState history={props.history}/>
      }
    </Container>
  );
}

export default AppLoggedInStateMachine;
