
import React, { 
  useState,
  useEffect
} from 'react';
import Container from '@material-ui/core/Container';

import {
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import isEmpty from 'lodash/isEmpty';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  getCurrentBatteryAssessmentIndex,
  getCurrentUserStateBatteryRunId,
} from '../../stores/user_store_getters';

import {
  STORES,
  STATE_EVENTS,
} from '../../constants';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import {
  updateBatteryData,
} from '../../actions/battery_actions';

import {
  getCurrentBattery,
  getFutureBattery,
} from '../../stores/user_store_getters';

import LoadingComponent from '../LoadingComponent';

const BatteryWelcomeState = () => {

  const updateUserStore = (newUserStoreState) => {
    userStoreState = newUserStoreState;
    setLoaded(true);
    if (!isEmpty(getCurrentBattery(userStoreState))) {
      let currentBatteryIndex = getCurrentBatteryAssessmentIndex(userStoreState);
      if (currentBatteryIndex > 0) {
        sendStateMachineEvent(STATE_EVENTS.BATTERY.RESUME);
      } else {
        sendStateMachineEvent(STATE_EVENTS.COMMON.NEXT);
      }
    } else if (!isEmpty(getFutureBattery(userStoreState))) {
      sendStateMachineEvent(STATE_EVENTS.BATTERY.NOT_STARTED);
    } else {
      sendStateMachineEvent(STATE_EVENTS.BATTERY.SUMMARY);
    }
  }

  // Hooks
  let [userStoreState] = useStoreState(STORES.USER.NAME, updateUserStore);
  let [, sendStateMachineEvent] = useStateMachine({});

  const [loaded, setLoaded] = useState(false);
  
  const fetchBatteryData = async () => {
    let batteryRunId = getCurrentUserStateBatteryRunId(userStoreState);
    await updateBatteryData(userStoreState, batteryRunId);
  }

  useEffect(() => {
    fetchBatteryData();
    analyticsPageView('battery_welcome');
  }, [])

  return (
    <Container> 
      { 
        !loaded && 
        <Container>
          <LoadingComponent title="Fetching Test" />
        </Container>
      }
      {
        loaded &&
        <Container>
          Battery Loaded
        </Container>
      }
    </Container> 
  );
}


export default BatteryWelcomeState;

