
import flatten from 'flat';

const flattenKeysWithValue = (obj) => {
  return flatten(obj);
}

const flattenKeyValueIntoArray = (obj) => {
  if (typeof obj !== 'object') {
    return [obj];
  }
  let flatObj = flattenKeysWithValue(obj);
  let flattenedList = [];
  for( let[key, value] of Object.entries(flatObj)) {
    flattenedList.push(key+'.'+String(value));
  }
  return flattenedList;
}

const returnSuccess = () => {
  return {
    success: true,
  }
}

const returnError = (error) => {
  return {
    success: false,
    error
  }
}

export {
  flattenKeysWithValue,
  flattenKeyValueIntoArray,
  returnSuccess,
  returnError,
}
