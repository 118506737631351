
import {
  returnSuccess,
  returnError,
} from '../utils/obj_utils';


import { 
  setBatterySummary,
  setBatteryResultsHistory,
  updateBatteryRun,
} from '../stores/user_store';

import requests from '../routes/backend_routes';
import rollbar from '../react-shared/src/logging/rollbar';

const fetchBatterySummary = async function(userStore, batteryRunId) {
  try {
    const response = await requests.get('core', 'battery.runSummary', {}, { '{:id}': batteryRunId });
    const batterySummary = await response.data;
    setBatterySummary(userStore, batterySummary);
    return returnSuccess();
  } catch (err) {
    rollbar.error(`Failed while trying to fetch battery summary: id: ${batteryRunId}
     err: ${err}
     stack: ${err.stack}`);
    return returnError(err);
  }
}

const fetchResultsHistory = async function(userId) {
  try {
    const response = await requests.get('core', 'battery.resultsHistory', {}, { '{:id}': userId });
    const resultsHistory = await response.data;
    setBatteryResultsHistory(resultsHistory);
    return returnSuccess();
  } catch (err) {
    rollbar.error(`Failed while trying to fetch results history user: ${userId} 
      err: ${err}
      stack: ${err.stack}`);
    return returnError(err);
  }
};

const updateBatteryData = async function(userStore, batteryRunId) {
  try {
    const response = await requests.put('core', 'battery.run', {}, { '{:id}': batteryRunId });
    const updatedBatteryRun = await response.data;
    updateBatteryRun(userStore, updatedBatteryRun);
    return returnSuccess();
  } catch (err) {
    rollbar.error(`Failed while trying to update battery run: id: ${batteryRunId} 
      err: ${err}
      stack: ${err.stack}`);
    return returnError(err);
  }
};

export {
  fetchBatterySummary,
  fetchResultsHistory,
  updateBatteryData,
}
