
import get from 'lodash/get';

import {
  STORES,
} from '../constants';

const getCurrentAppState = (appStoreState) => {
  return get(appStoreState, STORES.APP.CURRENT_STATE_KEY, '');
}

const getAllPreviousAppStates = (appStoreState) => {
  return get(appStoreState, STORES.APP.PREVIOUS_STATES_KEY, []);
}

const getPreviousAppState = (appStoreState) => {
  let previousStates = get(appStoreState, STORES.APP.PREVIOUS_STATES_KEY, []);
  if (!previousStates) { return ''; }

  return previousStates[previousStates.length-1];
}

const getInitialPath = (appStoreState) => {
  let paths = get(appStoreState, STORES.APP.INITIAL_PATHS_KEY, []);
  if (!paths) {
    return undefined;
  }

  // return the path from final index.
  return paths[paths.length - 1];
}

export {
  getCurrentAppState,
  getInitialPath,
  getPreviousAppState,
  getAllPreviousAppStates,
}
