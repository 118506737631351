
import axios from 'axios';
import _ from 'lodash';
import * as Cookies from 'js-cookie';
import * as urljoin from 'url-join';
import { MISC } from '../constants';

import { config } from '../config/config';
import { guessCurrentUserTimeZone } from '../utils/time_utils';

axios.interceptors.request.use(axiosConfig => {
  const token = Cookies.get(MISC.AUTH_TOKEN_COOKIE);
  const refreshToken = Cookies.get(MISC.REFRESH_TOKEN_COOKIE);

  if (token != null) {
    axiosConfig.headers.Authorization = `Bearer ${token}`;
  }

  if (refreshToken != null) {
    axiosConfig.headers.Refresh = `Refresh ${refreshToken}`;
  }

  axiosConfig.headers['TimeZone'] = guessCurrentUserTimeZone();

  return axiosConfig;
}, function (err) {
  return Promise.reject(err);
});

const baseUrls = {
  core: `${config['services']['core']['url']}`,
}

const routesMap = {
  core: {
    assessment: {
      create_run: 'assessment/run/',
      run: 'assessment/run/{:id}',
    },
    battery: {
      run: 'battery/run/{:id}',
      resultsHistory: 'battery/runs/user/{:id}/results_history',
      runSummary: 'battery/run/{:id}/summary',
    },
    catalog: 'catalog',
    event: {
      event: 'event/segment',
    },
    sessions: {
      sessions: 'sessions',
      authorization: 'sessions/authorization',
    },
    user: {
      user: 'user',
      survey: {
        seen: 'user/{:id}/survey/seen',
        complete: 'user/{:id}/survey/complete'
      },
      welcome: {
        seen: 'user/{:id}/welcome/seen',
      } 
    }
  },
}

function resolveUrl(base, path, resources={}) {
  let url = urljoin(baseUrls[base], _.get(routesMap, base + '.' + path));

  for (let [resKey, resVal] of Object.entries(resources)) {
    url = url.replace(String(resKey), String(resVal));
  }
  
  return url;
}

function get(base, path, params, resources={}) {
  const url = resolveUrl(base, path, resources);
  return axios.get(url, { params });
}

function post(base, path, body, resources={}) {
  const url = resolveUrl(base, path, resources);
  return axios.post(url, body);
}

function put(base, path, body, resources={}) {
  const url = resolveUrl(base, path, resources);
  return axios.put(url, body);
}

export default {
  get,
  post,
  put,
}

