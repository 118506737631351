
import get from 'lodash/get';

import { sharedConfig } from '../config/config';
// use app config if available
let config = window.globalConfig || sharedConfig;

const isSegmentAllowed = function() {
  if (!window.analytics) {
    return false;
  }

  if (!get(config, 'features_config.allow_segment', false)) {
    return false;
  }

  return true;
}

const analyticsGameEvent = async function (gameEvent, gameSlug, extraProps={}) {
  if (!isSegmentAllowed()) { return; }
  window.analytics.track(gameEvent, {
    properties: { 
      ...extraProps,
      slug: gameSlug,
      version: config.version,
    },
  });
}

const analyticsIdentify = async function (userId) {
  if (!isSegmentAllowed()) { return; }
  window.analytics.identify(userId);
}

const analyticsPageView = async function (pageName, extraProps={}) {
  if (!isSegmentAllowed()) { return; }
  window.analytics.track('page_view', {
    ...extraProps,
    id: pageName,
    version: config.version,
  });
}

const analyticsTrack = async function (eventName, extraProps={}) {
  if (!isSegmentAllowed()) { return; }
  window.analytics.track(eventName, {
    ...extraProps,
    version: config.version,
  });
}

export {
  isSegmentAllowed,
  analyticsGameEvent,
  analyticsIdentify,
  analyticsPageView,
  analyticsTrack,
}
