
/*eslint-disable*/

import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { css } from 'aphrodite/no-important';

import commonStyles from './commonStyles';

import AssessmentStartCardComponent from './assessment_states/AssessmentStartCardComponent';
import AssessmentScoreCardComponent from './assessment_states/AssessmentScoreCardComponent';
import PrepStateCheckIn from './prep_state/PrepStateCheckIn';
import SurveyState from './logged_in_states/SurveyState';

const DevTestComponent = (props) => {

  return ( 
    // <AssessmentStartCardComponent 
    //   assessmentTitle = { 'Arithmetic Reasoning' }
    //   assessmentStageText = { 'Assessment 1'}
    //   actionButtonText = { 'Start Practice' }
    // />
    // <AssessmentScoreCardComponent 
    //   finalScore = { 24 }
    //   title = { 'Arithmetic Reasoning' }
    //   actionButtonText = { 'Next' }
    //   actionButtonClicked = { (e) => { console.log("Action Button Clicked") } }
    // />
    // <PrepStateCheckIn />
    <SurveyState />
  )
}

export default DevTestComponent;
