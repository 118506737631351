

import { 
  STATES,
  STATE_EVENTS,
  STATE_MACHINE,
} from '../constants';

const LIBRARY_STATES = STATES.LIBRARY;
const LIBRARY_EVENTS = STATE_EVENTS.LIBRARY;

// DEFINE at app level
const libraryCatalogState = { on: {}, };

const libraryErrorState = { 
  on: {
    [STATE_EVENTS.COMMON.RETRY]: LIBRARY_STATES.LOADING,
  },
};

const libraryLoadingState = { 
  on: {
    [LIBRARY_EVENTS.LOADED]: LIBRARY_STATES.CATALOG,
    [STATE_EVENTS.COMMON.ERROR]: LIBRARY_STATES.ERROR,
  },
};

const libraryStates = {
  id: STATE_MACHINE.NAMES.LIBRARY_STATE_MACHINE,
  initial: LIBRARY_STATES.LOADING,
  states: {
    [LIBRARY_STATES.CATALOG]: libraryCatalogState,
    [LIBRARY_STATES.ERROR]: libraryErrorState,
    [LIBRARY_STATES.LOADING]: libraryLoadingState,
  }
};

export {
  libraryStates as sharedLibraryStates,
}
