
import get from 'lodash/get';

import { 
  mutateStore,
} from '../react-shared/src/stores';

import {
  STORES,
} from '../constants';

import {
  InitialPath,
  clearAllCookiePaths,
  removePathFromCookie,
  setNewCookiePath,
} from '../helpers/path_helper';

let initialStore = {};
initialStore[STORES.APP.INITIAL_PATHS_KEY] = [];
initialStore[STORES.APP.INITIAL_QUERY_PARAMS_KEY] = '';
initialStore[STORES.APP.ASSESSMENT_BY_SLUG_KEY] = {};
initialStore[STORES.APP.ASSESSMENT_BY_ID_KEY] = {};

initialStore[STORES.APP.CURRENT_STATE_KEY] = '';
initialStore[STORES.APP.PREVIOUS_STATES_KEY] = [];

const initialAppStore = Object.assign({}, initialStore);

const setAssessmentsConfig = (assessments) => {
  let assessmentSlugMap = {};
  let assessmentIdMap = {};
  
  // TODO: use real image url
  for (let assessment of assessments) {
    assessmentSlugMap[assessment.game_slug] = assessment;
    assessmentIdMap[assessment.id] = assessment;
  }
  mutateStore(STORES.APP.NAME, STORES.APP.ASSESSMENTS_CONFIG_KEY, assessments);
  mutateStore(STORES.APP.NAME, STORES.APP.ASSESSMENT_BY_SLUG_KEY, assessmentSlugMap);
  mutateStore(STORES.APP.NAME, STORES.APP.ASSESSMENT_BY_ID_KEY, assessmentIdMap);
}

const removeAllInitialPaths = () => {
  clearAllCookiePaths();
  mutateStore(STORES.APP.NAME, STORES.APP.INITIAL_PATHS_KEY, []);
}

const removeInitialPath = (initialPath) => {
  let paths = removePathFromCookie(initialPath);
  mutateStore(STORES.APP.NAME, STORES.APP.INITIAL_PATHS_KEY, paths);
}

const setInitialPathAndParam = (path, queryParams) => {
  let initialPath = new InitialPath(path, queryParams);
  let paths = setNewCookiePath(initialPath);
  mutateStore(STORES.APP.NAME, STORES.APP.INITIAL_PATHS_KEY, paths);
}

const setCurrentAppState = (searchParams) => {
  mutateStore(STORES.APP.NAME, STORES.APP.CURRENT_STATE_KEY, searchParams);
}

const setNewPreviousAppState = (appStoreState, newPreviousState)  => {
  let previousStates = get(appStoreState, STORES.APP.PREVIOUS_STATES_KEY, []);
  previousStates.push(newPreviousState);
  mutateStore(STORES.APP.NAME, STORES.APP.PREVIOUS_STATES_KEY, previousStates);
}

export {
  initialAppStore,
  removeAllInitialPaths,
  removeInitialPath,
  setAssessmentsConfig,
  setInitialPathAndParam,
  setCurrentAppState,
  setNewPreviousAppState,
}
