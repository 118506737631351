
import React, { 
  useState,
} from 'react';

import Container from '@material-ui/core/Container';
import { css, StyleSheet } from 'aphrodite/no-important';
import commonStyles from '../commonStyles';

import { 
  useStateMachine,
} from '../../react-shared/src/statemachine';

import {
  STATES,
} from '../../constants';

import BatteryCompletedState from './BatteryCompletedState';
import BatteryInternalState from './BatteryInterstitialState';
import BatteryNotStartedState from './BatteryNotStartedState';
import BatteryPrepState from './BatteryPrepState';
import BatteryResumeState from './BatteryResumeState';
import BatteryResultsHistoryState from './BatteryResultsHistoryState';
import BatterySummaryState from './BatterySummaryState';
import BatteryWelcomeState from './BatteryWelcomeState';

const BatteryStateMachine = () => {
  const updateStateMachineState = function(newStateMachineState) {
    stateMachineState = newStateMachineState;
    setState({
      ...state,
      stateMachineState,
    })
  }

  // Hooks
  let [stateMachineState] = useStateMachine({ 
    updateCallback: updateStateMachineState
  });
  const [state, setState] = useState({
    stateMachineState,
  });
  
  return ( 
    <Container className = { css([commonStyles.fullWidthCenter, styles.batteryStateMachineContainer]) }>
      {
        stateMachineState.flattenedValue.includes(STATES.BATTERY.COMPLETED) &&
        <BatteryCompletedState />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.BATTERY.INTERSTITIAL) &&
        <BatteryInternalState />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.BATTERY.NOT_STARTED) &&
        <BatteryNotStartedState />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.BATTERY.PREP) &&
        <BatteryPrepState />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.BATTERY.RESUME) &&
        <BatteryResumeState />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.BATTERY.RESULTS_HISTORY) &&
        <BatteryResultsHistoryState />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.BATTERY.SUMMARY) &&
        <BatterySummaryState />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.BATTERY.WELCOME) &&
        <BatteryWelcomeState />
      }
    </Container>
  );
}

const styles = StyleSheet.create({
  batteryStateMachineContainer: {
    margin: 0,
    padding: 0,
  }
});

export default BatteryStateMachine;
