export const gray1 = '#F2F2F2';
export const gray2 = '#E5E5E5';
export const gray3 = '#CCC';
export const gray4 = '#B2B2B2';
export const gray5 = '#999';
export const gray6 = '#7F7F7F';
export const gray7 = '#666';
export const gray8 = '#4D4D4D';

export const grayNormal = '#555';
export const grayMedium = '#888';
export const grayMediumish = '#AEAEAE';
export const grayLightish = '#BDBDBD';
export const grayLight = '#E2E2E2';
export const grayLighter = '#EEE';
export const grayCool = '#F6F7F8';
export const grayWhiteSmoke = '#F5F5F5';

const gray = {
  1: gray1,
  2: gray2,
  3: gray3,
  4: gray4,
  5: gray5,
  6: gray6,
  7: gray7,
  8: gray8,
  grayNormal: grayNormal,
  grayMedium: grayMedium,
  grayMediumish: grayMediumish,
  grayLightish: grayLightish,
  grayLight: grayLight,
  grayLighter: grayLighter,
  grayCool: grayCool,
  grayWhiteSmoke: grayWhiteSmoke
};

export default gray;