
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  STORES,
} from '../constants';

const forceLumosSSO = (userStoreState) => {
  return !get(userStoreState, STORES.USER.FORCE_LUMOS_SSO_KEY, false);
}

const getAdminLibSetting = (userStoreState) => {
  return get(userStoreState, STORES.USER.ADMIN_LIB_KEY, false);
}

const getBatteryResultsHistory = (userStoreState) => {
  return get(userStoreState, STORES.USER.BATTERY_RESULTS_HISTORY_KEY, {});
}

// ASSUMMPTION: Only 1 Battery Summary is viewable at a time.
const getBatterySummary = (userStoreState) => {

  const chosenBatterySummaryId = get(userStoreState, STORES.USER.CHOSEN_BATTERY_SUMMARY_ID_KEY, '');

  if (!chosenBatterySummaryId) { return {} };

  const loadedBatterySummaryById = get(userStoreState, STORES.USER.LOADED_BATTERY_SUMMARY_BY_ID_KEY, {});
  return loadedBatterySummaryById[chosenBatterySummaryId] || {};
}

const getChosenBatterySummaryId = (userStoreState) => {
  return get(userStoreState, STORES.USER.CHOSEN_BATTERY_SUMMARY_ID_KEY, '');
}

const getCurrentAssessmentConfig = (userStoreState) => {
  return get(userStoreState, STORES.USER.CURRENT_ASSESSMENT_CONFIG_KEY, {});
}

const getCurrentAssessmentRun = (userStoreState) => {
  return get(userStoreState, STORES.USER.CURRENT_ASSESSMENT_RUN_KEY, {});
}

const getCompletedBattery = (userStoreState) => {
  return get(userStoreState, STORES.USER.COMPLETED_BATTERY_RUN_KEY, {});
}

const getMostRecentUserCompletedBatteryRunId = (userStoreState) => {
  let completedBattery = getCompletedBattery(userStoreState);
  if (!isEmpty(completedBattery)) {
    return completedBattery.id;
  }

  let completedBatteryRuns = get(userStoreState, STORES.USER.CURRENT_STUDY_COMPLETED_BATTERY_RUNS, []);
  let length = completedBatteryRuns.length;
  if (length > 0) {
    return completedBatteryRuns[length - 1];
  }

  // No battery id anywhere, use current battery for now.
  return getCurrentUserStateBatteryRunId(userStoreState);
}

const getCurrentBattery = (userStoreState) => {
  return get(userStoreState, STORES.USER.CURRENT_BATTERY_RUN_KEY, {});
}

const getCurrentBatteryAssessmentRunId = (userStoreState) => {
  return get(userStoreState, STORES.USER.CURRENT_BATTERY_RUN_ASSESSMENT_RUN_ID_KEY, '');
}

const getCurrentBatteryAssessmentIndex = (userStoreState) => {
  return get(userStoreState, STORES.USER.CURRENT_BATTERY_RUN_ASSESSMENT_INDEX_KEY, -1);
}

const getFutureBattery = (userStoreState) => {
  return get(userStoreState, STORES.USER.FUTURE_BATTERY_RUN_KEY, {});
}

const getCurrentUserStateBatteryRunId = (userStoreState) => {
  return get(userStoreState, STORES.USER.CURRENT_STATE_BATTERY_RUN_ID_KEY, '');
}

const getNumAssessmentsInCurrentBattery = (userStoreState) => {
  return get(userStoreState, STORES.USER.CURRENT_BATTERY_RUN_NUM_ASSESSMENTS_KEY, -1);
}

const getUser = (userStoreState) => {
  return get(userStoreState, STORES.USER.USER_KEY, {});
}

const getUserId = (userStoreState) => {
  return get(userStoreState, STORES.USER.USER_ID_KEY, '');
}

const getUserLumosityId = (userStoreState) => {
  return get(userStoreState, STORES.USER.LUMOS_USER_ID_KEY, '');
}

const getUserFirstName = (userStoreState) => {
  const user = get(userStoreState, STORES.USER.USER_KEY, {});
  return get(user, 'profile.first_name', '');
}

const isAdmin = (userStoreState) => {
  const role = get(userStoreState, STORES.USER.ROLE_KEY, 'user');
  return (role === 'admin' || role === 'superadmin');
}

const isTester = (userStoreState) => {
  const role = get(userStoreState, STORES.USER.ROLE_KEY, 'user');
  return (isAdmin(userStoreState) || role === 'tester');
}

const shouldShowWelcomeScreen = (userStoreState) => {
  return get(userStoreState, STORES.USER.SHOW_LOGIN_WELCOME_SCREEN_KEY, true);
}

export {
  forceLumosSSO,
  getAdminLibSetting,
  getBatteryResultsHistory,
  getBatterySummary,
  getChosenBatterySummaryId,
  getCurrentAssessmentConfig,
  getCurrentAssessmentRun,
  getCompletedBattery,
  getCurrentBattery,
  getCurrentBatteryAssessmentIndex,
  getCurrentBatteryAssessmentRunId,
  getCurrentUserStateBatteryRunId,
  getFutureBattery,
  getMostRecentUserCompletedBatteryRunId,
  getNumAssessmentsInCurrentBattery,
  getUser,
  getUserId,
  getUserFirstName,
  getUserLumosityId,
  isAdmin,
  isTester,
  shouldShowWelcomeScreen
}
