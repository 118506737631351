
import {
  useEffect,
} from 'react';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import { 
  useStateMachine,
} from '../../react-shared/src/statemachine';

import {
  STATE_EVENTS,
} from '../../constants';

// Dummy bootstrap state
const LoggedInInitState = () => {
  // Hooks
  let [, sendStateMachineEvent] = useStateMachine({});

  useEffect(() => {
    sendStateMachineEvent(STATE_EVENTS.COMMON.NEXT);
  }, [])

  return null;
}

export default LoggedInInitState;

