
import moment from 'moment-timezone';

const addTime = (delayInSeconds, startTime = moment.utc()) => {
  let copiedStartTime = moment(startTime);
  return delayInSeconds ? copiedStartTime.add(delayInSeconds, 'seconds') : copiedStartTime;
}

const diffTime = (firstTime, secondTime = moment.utc(), diffStr = 'seconds') => {
  const copiedFirstTime = moment(firstTime);
  const copiedSecondTime = moment(secondTime);
  return copiedFirstTime.diff(copiedSecondTime, diffStr);
}


const checkTimeIsAfter = (afterTime, timeToCheck = moment.utc()) => timeToCheck.isAfter(afterTime);
const convertToCurrentUserTimeZone = (utcTime) => moment(utcTime).local();
const readableFormatWithHourAndMinute = (time) => moment(time).format('MMM Do, YYYY h:mm A');
const readableFormatWithoutHourAndMinute = (time) => moment(time).format('MMM Do, YYYY');
const convertToUSDateFormat = (time) => moment(time).format('MM-DD-YYYY');
const getCurrentUTCString = () => moment.utc().format();
const guessCurrentUserTimeZone = () => moment.tz.guess();


export {
  addTime,
  diffTime,
  checkTimeIsAfter,
  convertToCurrentUserTimeZone,
  convertToUSDateFormat,
  getCurrentUTCString,
  guessCurrentUserTimeZone,
  readableFormatWithHourAndMinute,
  readableFormatWithoutHourAndMinute,
}