
import React from 'react';
import {FormattedMessage} from 'react-intl';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { css, StyleSheet } from 'aphrodite/no-important';

import {
  Link,
} from "react-router-dom";

import { 
  analyticsTrack,
} from '../../react-shared/src/segment/analytics';

import { base } from '../lumos_common/colors';
import commonStyles from '../commonStyles';
import WideActionButton from '../WideActionButton';

const BatteryTextContainer = (props) => {

  return (
    <Container className = { css([commonStyles.flexColumnJustifyCenter, styles.batteryTextColumnContainer]) }>
      <Container className = { css([commonStyles.flexColumnAllCenter, styles.bodyTextContainer]) }>
        { 
          props.batteryBodyText.map((msg, i) => {
            return (
              <div key = {"div_"+i}> 
                { 
                  i > 0 &&
                  <div key = {"div_i_"+i}> <br key = {"br_i_1"+i}/> <br key = {"br_i_2"+i}/> </div>
                }
                <Typography 
                  className = { css([commonStyles.flexColumnAllCenter, styles.batteryBodyText]) }
                  key = {"body_"+i}
                >
                  <FormattedMessage
                    id= {"batteryBody_"+i}
                    key = {"formattedBody_"+i}
                    defaultMessage={ msg }
                    values={{ 
                      study_begin_time: props.studyBeginTime,
                      study_begin_days: props.studyBeginDays || 0,
                    }}
                  />
                </Typography>
              </div>
            )
          })
        }
      </Container>
      {
        props.showActionButton &&
        <Container className = { css([commonStyles.flexRowAllCenter, styles.actionContainer])  }>
          <WideActionButton 
            buttonText = { props.actionButtonText }
            onClick = { props.actionButtonClicked }
            id = { props.wideButtonId }
            currentPage = { props.currentPage }
          />
        </Container>
      }
      {
        props.onResultsHistoryClick &&
        <Container className = { css([commonStyles.fullWidthCenter,
         commonStyles.flexRowAllCenter, styles.resultsHistoryText])}> 
          <Link
            to=''
            onClick = {e => {
              analyticsTrack('link_click', {
                id: 'view_results_history',
                currentPage: props.currentPage,
              });
              props.onResultsHistoryClick();
              e.preventDefault();
            }}
          >
            { 'View Your Recent Results' }
          </Link>
        </Container>
      }
    </Container> 
  );
}

const styles = StyleSheet.create({
  batteryTextColumnContainer: {
    textAlign: 'center',
    width: 784,
    height: '400px',
  },
  batteryTitleText: {
    display: 'flex',
    color: base.lumosBlack,
    fontFamily: "MuseoSans500",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '1px',
  },
  bodyTextContainer: {
    display: 'flex',
    flex: 2,
    justifyContent: 'center',
  },
  batteryBodyText: {
    display: 'flex',
    color: base.lumosBlack,
    fontFamily: "MuseoSans500",
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: '1px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  actionContainer: {
    display: 'flex',
    paddingBottom: 20,
    flex: 1,
  },

  resultsHistoryText: {
    display: 'flex',
    color: '#07818F',
    fontFamily: "MuseoSans500",
    fontSize: 18,
    letterSpacing: '1px',
    textAlign: 'center',
    width: 900,
  },
});

export default BatteryTextContainer;

