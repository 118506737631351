
import React, { 
  useEffect,
  useState,
} from 'react';

import {
  deviceDetect,
  isIE,
  isBrowser,
} from "react-device-detect";

import {FormattedMessage} from 'react-intl';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { 
  analyticsTrack
} from '../../react-shared/src/segment/analytics';

import {
  STATE_EVENTS,
} from '../../constants';

import {
  LocalizedStrings,
} from '../../locale';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import {
  lumosPlaformCheckURL,
} from '../../utils/link_utils';

import { css, StyleSheet } from 'aphrodite/no-important';

import commonStyles from '../commonStyles';
import WideActionButton from '../WideActionButton';
import PageTitleSubheader from '../subheader/PageTitleSubheader';
import LoadingComponent from '../LoadingComponent';

const AppDeviceDetectState = () => {
  const PAGE_NAME = 'device_detect';
  let [, sendStateMachineEvent] = useStateMachine({});


  const goToLumosity = () => {
    lumosPlaformCheckURL();
  }

  const verifyDevice = () => {
    if (isBrowser && !isIE) {
      setIsDeviceAllowed(true);
      isDeviceAllowed = true;
    }
    
    setVerifiedDevice(true);
    verifiedDevice = true;
    if (isDeviceAllowed) {
      analyticsTrack('application_start', { 
        current_page: PAGE_NAME,
        device: deviceDetect(),
      });
      sendStateMachineEvent(STATE_EVENTS.COMMON.NEXT);
    } else {
      analyticsTrack('application_attempt_failed', {
        current_page: PAGE_NAME,
        device: deviceDetect(),
      });
    }
  }

  let [verifiedDevice, setVerifiedDevice] = useState(false);
  let [isDeviceAllowed, setIsDeviceAllowed] = useState(false);

  useEffect(() => {
    verifyDevice();
  }, []);
  return ( 
    <Container className = { css([commonStyles.flexColumnAllCenter, commonStyles.fullWidthCenter]) }>
      { 
        !verifiedDevice && 
        <Container>
          <LoadingComponent title="Loading" />
        </Container>
      }
      {
        verifiedDevice && !isDeviceAllowed &&
        <Container className = { css([commonStyles.flexColumnAllCenter, commonStyles.fullWidthCenter]) }>
          <PageTitleSubheader 
            title= {LocalizedStrings.wrongDeviceHeader}
          />
          <Container className = { css([commonStyles.flexColumnAllCenter, styles.bodyTextContainer]) }>
            { 
              LocalizedStrings.wrongDeviceBody.map((msg, i) => {
                return (
                  <div key = {"div_"+i}> 
                    { 
                      i > 0 &&
                      <div key = {"div_i_"+i}> <br key = {"br_i_1"+i}/> <br key = {"br_i_2"+i}/> </div>
                    }
                    <Typography 
                      className = { css([commonStyles.flexColumnAllCenter, styles.wrongDeviceBodyText]) }
                      key = {"body_"+i}
                    >
                      <FormattedMessage
                        id= {"wrongDeviceBody_"+i}
                        key = {"formattedBody_"+i}
                        defaultMessage={msg }
                      />
                    </Typography>
                  </div>
                )
              })
            }
          </Container>
          <Container className = { css([commonStyles.flexRowAllCenter, styles.actionContainer])  }>
            <WideActionButton 
              buttonText = { LocalizedStrings.wrongDeviceCTA }
              onClick = { goToLumosity }
              id = { 'wrong_device_back_to_lumos' }
              currentPage = { PAGE_NAME }
            />
          </Container>
        </Container>
      }
    </Container> 
  );
}

const styles = StyleSheet.create({
  wrongDeviceContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '440px',
    width: 784,
    // marginTop: 40,
    // marginLeft: 'auto',
    // marginRight: 0,
  },
  wrongDeviceColumnContainer: {
    display: 'flex',
    // marginRight: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleTextContainer: {
    display: 'flex',
    paddingTop: 30,
    justifyContent: 'flex-start',
    // flex: 1,
  },
  bodyTextContainer: {
    display: 'flex',
    width: 360,
    paddingTop: '40px',
    // flex: 2,
  },
  wrongDeviceBodyText: {
    display: 'flex',
    color: '#4A4A4A',
    fontFamily: "MuseoSans500",
    fontSize: 20,
    letterSpacing: '1px',
    textAlign: 'center',
  },

  actionContainer: {
    display: 'flex',
    paddingTop: '40px',
    // paddingBottom: 20,
    // flex: 1,
  }
});

export default AppDeviceDetectState;

