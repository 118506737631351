
import React from 'react';
import {FormattedMessage} from 'react-intl';
import get from 'lodash/get';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { css, StyleSheet } from 'aphrodite/no-important';

import {
  LocalizedStrings,
} from '../../locale';

import {
  STORES,
} from '../../constants';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  getBatterySummary,
} from '../../stores/user_store_getters';

import {
  convertToCurrentUserTimeZone,
  readableFormatWithoutHourAndMinute,
  getCurrentUTCString,
} from '../../utils/time_utils';

import {
  getLocalizedScoredData,
} from '../../utils/scores_utils';

import commonStyles from '../commonStyles';
import checkIcon from '../../assets/check_mark_done.png';
import WideActionButton from '../WideActionButton';

import BatterySummaryAssessmentRunCard from './BatterySummaryAssessmentRunCard';

const PAGE_NAME = 'battery_summary';

const buildAssessmentSummaryCards = (assessmentRuns) => {
  return assessmentRuns.map((assessmentRun, index) => {

    let assessmentConfig = assessmentRun.assessment_config;
    let descriptionTextKey = assessmentConfig.frontend_data.summary_description_key;
    const runData = assessmentRun.run_data;
    const scoresConfig = assessmentConfig.frontend_data.scores.filter(score_obj => score_obj.screens.includes('summary'));
    let scoresData = getLocalizedScoredData(scoresConfig, runData);

    return (
      <Grid item key={index}> 
        <BatterySummaryAssessmentRunCard
          title = {assessmentConfig.title}
          slug = {assessmentConfig.slug}
          description = {LocalizedStrings[descriptionTextKey]}
          scoresData = {scoresData}
          id = { 'summary_card_' + index}
          currentPage = { PAGE_NAME }
        />
      </Grid>
    );
  });
}

const BatterySummaryView = (props) => {

  // Hooks
  let [userStoreState] = useStoreState(STORES.USER.NAME);
  let batterySummary = getBatterySummary(userStoreState);
  let assessmentCards = buildAssessmentSummaryCards(batterySummary.assessments);
  let batteryRun = batterySummary.battery;
  let batteryDateToUse = get(batteryRun, 'completed_at', get(batteryRun, 'updated_at', getCurrentUTCString()));
  batteryDateToUse = convertToCurrentUserTimeZone(batteryDateToUse);
  batteryDateToUse = readableFormatWithoutHourAndMinute(batteryDateToUse);
  
  return (
    <Container  className = { css([commonStyles.flexColumnAllCenter, styles.summaryContainer]) }>
      <Container className= { css([commonStyles.flexColumnAllCenter, styles.checkIconContainer ]) }>
        <img 
          src = { checkIcon } 
          className = { css(styles.checkIconImage) }  
          alt = { 'Check Icon' }
        />
      </Container>
      <Container className = { css([commonStyles.flexJustifyCenter, styles.summaryDescriptionContainer]) }>
        <Typography  className = { css(styles.summaryDescription) }>
          <FormattedMessage
            id="summaryDescription"
            defaultMessage={ LocalizedStrings.summaryDescription }
            values={{ date: batteryDateToUse }}
          />
        </Typography>
      </Container>
      <Grid container className = { css([commonStyles.flexJustifyCenter, styles.gridContainer]) } spacing={2}>
        {assessmentCards}
      </Grid>
      <Container className = { css([commonStyles.flexRowAllCenter, styles.actionContainer])  }>
        <WideActionButton 
          buttonText = { props.actionButtonText }
          onClick = { props.actionButtonClicked }
          id = { PAGE_NAME }
          currentPage = { props.currentPage }
        />
      </Container>
    </Container>
  );
}

const styles = StyleSheet.create({
  summaryContainer: {
    height: '720px',
    width: 900,
    justifyContent: 'flex-start',
  },
  summaryDescriptionContainer: {
    marginTop: 24,
  },
  gridContainer: {
    marginTop: 32,
  },
  //// Check Icon
  checkIconContainer: {
    marginTop: 24,
  },
  checkIconImage: {
    height: 48,
    width: 48,
  },

  actionContainer: {
    marginTop: 28,
    // display: 'flex',
    paddingBottom: 20,
    // flex: 1,
  }
});



export default BatterySummaryView;

