
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import '../Assessment.css';

class AssessmentCardComponent extends Component {
  render() {
    // https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/templates/album/Album.js
    return (
      <Card className="Card">
        <CardActionArea 
          onClick={() => this.props.launchAssessment(this.props.params)}>
          <CardMedia
            className="CardMedia"
            image={this.props.params.banner_url}
            title="Image title"
          />
          <CardContent className="CardContent">
            <Typography gutterBottom variant="h5" component="h2">
              {this.props.params.title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

AssessmentCardComponent.propTypes = {
  launchAssessment: PropTypes.func,
  params: PropTypes.shape({
    title: PropTypes.string,
    banner_url: PropTypes.string,
  })
}

export default AssessmentCardComponent;
