

import { 
  STATES,
  STATE_EVENTS,
  STATE_MACHINE,
} from '../constants';

const ASSESSMENT_STATES = STATES.ASSESSMENT;
const ASSESSMENT_EVENTS = STATE_EVENTS.ASSESSMENT;

const assessmentCreateState = { on: {}, };
assessmentCreateState.on[ASSESSMENT_EVENTS.CREATED] = ASSESSMENT_STATES.PLAY;

const assessmentPlayState = { on: {}, };
assessmentPlayState.on[ASSESSMENT_EVENTS.COMPLETED] = ASSESSMENT_STATES.SCORE;

const assessmentScoreState = { on: {} }

const assessmentStates = {
  id: STATE_MACHINE.NAMES.ASSESSMENT_STATE_MACHINE,
  initial: ASSESSMENT_STATES.CREATE,
  states: {},
};

assessmentStates.states[ASSESSMENT_STATES.CREATE] = assessmentCreateState;
assessmentStates.states[ASSESSMENT_STATES.PLAY] = assessmentPlayState;
assessmentStates.states[ASSESSMENT_STATES.SCORE] = assessmentScoreState;

export {
  assessmentStates
}
