
import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { css, StyleSheet } from 'aphrodite/no-important';

import { base } from '../lumos_common/colors';
import commonStyles from '../commonStyles';
import activeCircle from '../../assets/active_assessment_stage.png';
import activeCircleArrow from '../../assets/active_assessment_stage_arrow.png';
import completedCircle from '../../assets/active_assessment_stage.png';
import inactiveCircle from '../../assets/inactive_assessment_stage.png';
import assessmentStageBar from '../../assets/assessment_stage_bar.png';

const AssessmentStageViewComponent = (props) => {

  const chainElements = [];
  
  const numStages = props.numStages || 5;
  const currentStage = props.currentStage;

  if (currentStage < 0) {
    return chainElements;
  }
  
  for (let i = 0; i <numStages; i++) {
    let circleSrc;
    let circleStyle;
    let circleContainerStyle = css([commonStyles.flexColumnJustifyCenter, styles.stageNormalContainer]);
    let numberTextColor;
    
    if (i < currentStage) {
      circleSrc = completedCircle;
      circleStyle = css(styles.stageCompletedImage);
      numberTextColor = styles.stageActiveNumberColor;
    } else if (i === currentStage) {
      circleSrc = activeCircle;
      circleStyle = css(styles.stageActiveImage);
      circleContainerStyle = css([commonStyles.flexColumnJustifyCenter, styles.stageActiveContainer]);
      numberTextColor = styles.stageActiveNumberColor;
    } else {
      circleSrc = inactiveCircle;
      circleStyle = css(styles.stageInactiveImage);
      numberTextColor = styles.stageInactiveNumberColor;
    }

    let barImage = 
      <Container 
        key = { 'bar_img_container_' + i }
        className = { css([commonStyles.flexColumnJustifyCenter, styles.stageBarContainer])}>
        <img 
        key = { 'bar_img_' + i }
          src = { assessmentStageBar } 
          className = { css(styles.stageBarImage) }
        />
      </Container>
    let circleImage = 
      <Container 
        key = { 'circle_img_container_' + i }
        className = { circleContainerStyle }>
        <img 
          key = { 'img_' + i }
          src = { circleSrc } 
          className = { circleStyle }
        />
        <Container 
          key = { 'circle_img_number_container_' + i }
          className = { css(styles.stageActiveNumberContainer)}>
          <Typography 
            key = { 'circle_img_number_container_text' + i }
            className = { css([styles.stageNumberText, numberTextColor]) }>
            { i + 1 }
          </Typography>
        </Container>
        {
          i === currentStage &&
          <Container 
            key = { 'circle_arrow_container' }
            className = { css([commonStyles.flexJustifyCenter, styles.stageActiveArrowImageContainer]) }>
            <img 
              key = { 'img_arrow' }
              src = { activeCircleArrow } 
              className = { css(styles.stageActiveArrowImage) }
            />
          </Container>
        }
      </Container>
    if (i > 0) {
      chainElements.push(barImage);
    }
    chainElements.push(circleImage);
  }

  return (
    <Container className = { css(styles.chainContainer)}>
      { chainElements }
    </Container>
  )
}

const styles = StyleSheet.create({
  chainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: 40,
  },
  stageActiveContainer: {
    margin: 0,
    padding: 0,
    height: 43,
    width: 24,
    justifyContent: 'space-between',
    position: 'relative',
  },
  stageNormalContainer: {
    margin: 0,
    padding: 0,
    height: 24,
    width: 24,
    position: 'relative',
  },
  stageArrowImageContainer: {
    height: 8,
    width: 12,
  },
  stageActiveImage: {
    margin: 0,
    padding: 0,
    height: 24,
    width: 24,
  },
  stageActiveArrowImage: {
    margin: 0,
    padding: 0,
    height: 8,
    width: 12,
  },
  stageActiveNumberContainer: {
    position: 'absolute',
    top: '0',
    left: '0px',
    margin: 0,
    padding: 0,
    height: 24,
    width: 24,
  },
  stageNumberText: {
    fontFamily: "MuseoSans500",
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: '1px',
    textAlign: 'center',
  },
  stageActiveNumberColor: {
    color: base.lumosWhite,
  },
  stageInactiveNumberColor: {
    color: base.lumosBlack,
  },
  stageCompletedImage: {
    height: 24,
    width: 24,
  },
  stageInactiveImage: {
    margin: 0,
    padding: 0,
    height: 24,
    width: 24,
  },
  stageBarContainer: {
    margin: 0,
    padding: 0,
    height: 24,
    width: 48,
  },
  stageBarImage: {
    margin: 0,
    padding: 0,
    height: 2,
    width: 48,
  }
});

export default AssessmentStageViewComponent;
