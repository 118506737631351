
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import React, { 
  useEffect,
  useState,
} from 'react';

import { css, StyleSheet } from 'aphrodite';
import Container from '@material-ui/core/Container';

import Iframe from 'react-iframe'

import { 
  analyticsPageView,
  analyticsTrack,
} from '../../react-shared/src/segment/analytics';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  STORES,
  STATE_EVENTS,
} from '../../constants';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import { 
  getSurveyLink,
} from '../../utils/link_utils';

import {
  sendSurveySeen,
  sendSurveyComplete,
} from '../../actions/logged_in_actions';

import commonStyles from '../commonStyles';
import SkipComponent from '../SkipComponent';
import PageTitleSubheader from '../subheader/PageTitleSubheader';

const SurveyState = () => {
  const PAGE_NAME = 'survey';
  const SKIP_SURVEY_MESSAGE = 'Skip Survey';

  let [userStoreState] = useStoreState(STORES.USER.NAME);
  const [, sendStateMachineEvent] = useStateMachine({});
  const [isSurveyComplete, setIsSurveyComplete] = useState(false);
  const goToNextState = () => {
    sendStateMachineEvent(STATE_EVENTS.COMMON.NEXT);
  }

  const skipSurvey = () => {
    analyticsTrack('button_click', {
      id: 'skip_survey',
      current_page: PAGE_NAME,
      message: SKIP_SURVEY_MESSAGE,
    })
    goToNextState();
  }

  const sendSurveySeenUpdate = async () => {
    sendSurveySeen(userStoreState);
  }

  const checkWindowMessage = async (data) => {
    if (data.origin && data.origin === 'https://www.surveygizmo.com') {
      if (data.data && data.data === 'surveyComplete') {
        analyticsTrack('survey_finish', { current_page: PAGE_NAME });
        setIsSurveyComplete(true);
        await sendSurveyComplete(userStoreState);
        goToNextState();
      }
    }
  }

  useEffect(() => {
    sendSurveySeenUpdate();
    analyticsPageView(PAGE_NAME);
    window.addEventListener('message', checkWindowMessage);
    return () => {
      window.removeEventListener('message', checkWindowMessage);
    }
  }, []);

  const surveyData = get(userStoreState, STORES.USER.SURVEY_KEY, {});

  if (isSurveyComplete) { return (null) }

  let surveyUrl = '';
  if (!isEmpty(surveyData)) {
    surveyUrl = getSurveyLink(get(surveyData, 'config.link'), get(userStoreState, `${STORES.USER.USER_KEY}.id`, undefined));
  }

  return (
    <Container className= { css([commonStyles.fullWidthCenter, commonStyles.flexColumnAllCenter, styles.mainContainer])} >
      <PageTitleSubheader 
        title= { 'Tell Us More About You' }
      />
      <Iframe url= {surveyUrl}
        className= { css([commonStyles.flexRowAllCenter, styles.surveyContainer])} 
        width="642" height="481" style="overflow:hidden"
        display="initial"
        position="relative"/>
      {
        <SkipComponent 
          onClick = { skipSurvey }
          message = { SKIP_SURVEY_MESSAGE }
        />
      }
    </Container>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    height: '805px',
  },
  surveyContainer: {
    height: '805px',
    width: 642,
    marginTop: 24,
    marginBottom: 30,
    overflow: 'hidden',
    border: '1px solid',
  },
});

export default SurveyState;
