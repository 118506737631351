

let assessmentStrings = {
  arithmeticReasoningSummaryDescription: 'Designed to assess numerical problem solving ability.',
  continuousPerformanceSummaryDescription: 'Measures sustained and response inhibition.',
  delayedObjectRecognitionSummaryDescription: 'Assess a delayed recall of familiarity of a previously seen object.',
  digitSymbolCodingSummaryDescription: 'Assess processing speed in a matching task.',
  grammaticalReasoningSummaryDescription: 'Assess cognitive flexibility and reasoning.',
  memorySpanSummaryDescription: 'Assess visual short-term memory by recalling sequence of spatial locations.',
  objectRecognitionSummaryDescription: 'Ability to judge a previously encountered object as familiar.',
  reverseMemorySpanSummaryDescription: 'Assess working memory by recalling the reverse order of a spatial sequence.',
  scaleBalanceSummaryDescription: 'Assess visual reasoning and problem solving.',
  trailMakingASummaryDescription: 'Assess attention and processing speed.',
  trailMakingBSummaryDescription: 'Assess attention, processing speed and flexibility.',
  
  scoreScreenScoreMistakes: 'Mistakes: {num_errors}',
  scoreScreenScoreCorrect: 'Correct: {num_correct}',
  scoreScreenTotalTime: 'Time to complete(seconds): {time}',
  scoreScreenScoreLongestSpan: 'Longest span reached: {max_span}',
  scoreScreenScoreCorrectWithTime: 'Correct: {num_correct} in {config_time} seconds',
  scoreScreenScoreCorrectWithTrials: 'Correct: {num_correct} out of {num_tries}',
  scoreScreenScoreCorrectSpanWithTrials: 'Correct spans: {num_correct} out of {num_tries}',
  scoreScreenScoreResponseTime: 'Response Time (milliseconds): {response_time_ms}',
  
  summaryScoreMistakes: 'Mistakes: {num_errors}',
  summaryScoreCorrect: 'Correct: {num_correct}',
  summaryScoreTotalTime: 'Time to complete(seconds): {time}',
  summaryScoreLongestSpan: 'Longest span reached: {max_span}',
  summaryScoreCorrectWithTime: 'Correct: {num_correct} in {config_time} seconds',
  summaryScoreCorrectWithTrials: 'Correct: {num_correct} out of {num_tries}',
  summaryScoreCorrectSpanWithTrials: 'Correct spans: {num_correct} out of {num_tries}',
  summaryScoreResponseTime: 'Response Time (milliseconds): {response_time_ms}',
  
}


// Careful with sentences here, many have been split across multiple lines.
// Making it an extra field in the array will be a new para so use concat where needed.
const Strings = {
  ...assessmentStrings,
  welcomeHeader: 'Welcome, {name}',
  welcomeBody: [
    'You’re about to take the NeuroCognitive Performance Test, ' +
    'which will give you a snapshot of your cognitive performance today. ' +
    'Lumosity adapted this tool from pen-and-paper cognitive assessments commonly used in research.',

    'You should not rely on the results of the NCPT to make health or life decisions, ' + 
    'and the NCPT is not intended to diagnose, treat, cure, or prevent cognitive impairment or any other disease or health condition.',
  ],
  welcomeCTA: 'Start the NCPT',
  wrongDeviceHeader: 'Sorry, we can\'t do that',
  wrongDeviceBody: [
    'If you are on a mobile or tablet device, ' +
    'You will need to access this website on a laptop or desktop computer.',

    'If you are on a computer, this app may not support on this browser or device. ' +
    'Please see our help center page for supported browsers and computer operating systems.',
  ],
  wrongDeviceCTA: 'Back To Lumosity',
  batteryCompeletedHeader: 'Great job, {name}',
  batteryNotStartedHeader: 'Come back later',
  batteryResumeHeader: 'Resume the test',
  batteryCompeletedBodyUnknownNextStudy: [
    'Thank you for completing this study.',
    'Please check back again in a few weeks for the next study.',
  ],
  batteryCompeletedBodyKnownNextStudy: [
    'You\'ve finished the NeuroCognitive Performance Test! We\'ll prompt you when it\'s time ' +
    'to take the test again after {study_begin_time}. Thank you for helping our scientists explore ' +
    'new ways to measure human cognition.',
  ],
  batteryNotStartedBody: [
    'It\'s not yet time to take the NeuroCognitive Performance Test again. ' +
    'You can come back after {study_begin_time}. Thank you for helping our scientists explore ' +
    'new ways to measure human cognition.',
  ],
  batteryResumeBody: [
    'You\'ve already started this assessment. Let\'s continue where you left off.',
  ],
  privacyFooterHeader: 'Your privacy is important to us. ',
  privacyFooterHeaderLink: 'Learn more.',
  privacyFooterBody: [
    'We use this tool to help our scientists improve Lumosity and to collaborate with researchers ' +
    'who further our understanding of cognitive science. We only share aggregated or de-identified ' +
    'data with our research collaborators. Thanks for your contribution!',
  ],

  resultsHistoryCardDescription: 'Summary Report',
  resultsHistoryTextFooter: [
    'Build up your results history by coming back to take the NCPT periodically.',
    'If you\'re looking for results from a test you took prior to July 10, 2020 ' +
    'please contact customer support.'
  ],

  summaryDescription: 'Results from the NeuroCognitive Performance Test that you took on  {date}',

  
}


export {
  Strings,
}

