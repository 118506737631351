

import { 
  STATES,
  STATE_EVENTS,
  STATE_MACHINE,
} from '../constants';

const LIBRARY_STATES = STATES.LIBRARY;
const LIBRARY_EVENTS = STATE_EVENTS.LIBRARY;

const libraryCatalogState = { on: {}, };
libraryCatalogState.on[LIBRARY_EVENTS.PLAY] = `#${STATE_MACHINE.NAMES.ASSESSMENT_STATE_MACHINE}`;

const libraryErrorState = { on: {}, };
libraryErrorState.on[STATE_EVENTS.COMMON.RETRY] = LIBRARY_STATES.LOADING;

const libraryLoadingState = { on: {}, };
libraryLoadingState.on[LIBRARY_EVENTS.LOADED] = LIBRARY_STATES.CATALOG;
libraryLoadingState.on[STATE_EVENTS.COMMON.ERROR] = LIBRARY_STATES.ERROR;

const libraryStates = {
  id: STATE_MACHINE.NAMES.LIBRARY_STATE_MACHINE,
  initial: LIBRARY_STATES.LOADING,
  states: {}
};

libraryStates.states[LIBRARY_STATES.CATALOG] = libraryCatalogState;
libraryStates.states[LIBRARY_STATES.ERROR] = libraryErrorState;
libraryStates.states[LIBRARY_STATES.LOADING] = libraryLoadingState;

export {
  libraryStates
}
