
import React, { 
  useEffect
} from 'react';

import {FormattedMessage} from 'react-intl';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { analyticsPageView } from '../../react-shared/src/segment/analytics';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import { 
  getUserId,
  getUserFirstName,
} from '../../stores/user_store_getters';

import {
  STORES,
  STATE_EVENTS,
} from '../../constants';

import {
  LocalizedStrings,
} from '../../locale';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import { css, StyleSheet } from 'aphrodite/no-important';

import {
  setSeenLoggedInWelcomeScreenAction,
} from '../../actions/logged_in_actions';

import commonStyles from '../commonStyles';
import WideActionButton from '../WideActionButton';
import PageTitleSubheader from '../subheader/PageTitleSubheader';

import PrivacyTextFooter from '../footer/PrivacyTextFooter';

const LoggedInWelcomeState = () => {
  const PAGE_NAME = 'welcome';
  // Hooks
  let [userStoreState] = useStoreState(STORES.USER.NAME);
  let [, sendStateMachineEvent] = useStateMachine({});

  const getStartedClicked = () => {
    sendStateMachineEvent(STATE_EVENTS.COMMON.NEXT);
  }
  
  useEffect(() => {
    setSeenLoggedInWelcomeScreenAction(getUserId(userStoreState));
    analyticsPageView(PAGE_NAME);
  }, [])
  
  return (
    <Container className = { css([commonStyles.flexColumnAllCenter, commonStyles.fullWidthCenter]) }>
      <PageTitleSubheader 
        title= {LocalizedStrings.welcomeHeader} 
        userName = {getUserFirstName(userStoreState)}
      />
      <Container className = { css([styles.welcomeContainer]) }>
        <Container className = { css([commonStyles.flexColumnJustifyCenter, styles.welcomeColumnContainer]) }>
          <Container className = { css([commonStyles.flexColumnAllCenter, styles.bodyTextContainer]) }>
            { 
              LocalizedStrings.welcomeBody.map((msg, i) => {
                return (
                  <div key = {"div_"+i}> 
                    { 
                      i > 0 &&
                      <div key = {"div_i_"+i}> <br key = {"br_i_1"+i}/> <br key = {"br_i_2"+i}/> </div>
                    }
                    <Typography 
                      className = { css([commonStyles.flexColumnAllCenter, styles.welcomeBodyText]) }
                      key = {"body_"+i}
                    >
                      <FormattedMessage
                        id= {"welcomeBody_"+i}
                        key = {"formattedBody_"+i}
                        defaultMessage={msg }
                      />
                    </Typography>
                  </div>
                )
              })
            }
          </Container>
          <Container className = { css([commonStyles.flexRowAllCenter, styles.actionContainer])  }>
            <WideActionButton 
              buttonText = { LocalizedStrings.welcomeCTA }
              onClick = { getStartedClicked }
              id = { 'welcome_start' }
              currentPage = { PAGE_NAME }
            />
          </Container>
        </Container>
      </Container>
      <PrivacyTextFooter currentPage = { PAGE_NAME }/>
    </Container> 
  );
}

const styles = StyleSheet.create({
  welcomeContainer: {
    display: 'flex',
    height: '440px',
    width: 784,
    // marginTop: 40,
    // marginLeft: 'auto',
    // marginRight: 0,
  },
  welcomeColumnContainer: {
    display: 'flex',
    // marginRight: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleTextContainer: {
    display: 'flex',
    paddingTop: 30,
    justifyContent: 'flex-start',
    // flex: 1,
  },
  bodyTextContainer: {
    display: 'flex',
    width: 900,
    // flex: 2,
  },
  welcomeBodyText: {
    display: 'flex',
    color: '#4A4A4A',
    fontFamily: "MuseoSans500",
    fontSize: 20,
    letterSpacing: '1px',
    textAlign: 'center',
  },

  actionContainer: {
    display: 'flex',
    paddingTop: 24,
    // paddingBottom: 20,
    // flex: 1,
  }
});


export default LoggedInWelcomeState;

