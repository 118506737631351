
import { 
  setSeenLoggedInWelcomeScreen,
  mergeUser,
}  from '../stores/user_store';

import { 
  returnSuccess,
  returnError,
} from '../utils/obj_utils';

import {
  getUser,
} from '../stores/user_store_getters';

import rollbar from '../react-shared/src/logging/rollbar';

import requests from '../routes/backend_routes';

const sendSurveySeen = async function(userStoreState) {
  const currentUser = getUser(userStoreState);
  try {
    const response = await requests.put('core', 'user.survey.seen', {}, { '{:id}': currentUser.id });
    let user = response.data;
    if (user) {
      mergeUser(currentUser, user);
    }
    return returnSuccess();
  } catch (err) {
    rollbar.error(`Error while send survey seen: user: ${JSON.stringify(currentUser)}, err: ${err} stack: ${err.stack}`);
    return returnError(err);
  }
}

const sendSurveyComplete = async function(userStoreState) {
  const currentUser = getUser(userStoreState);
  try {
    const response = await requests.put('core', 'user.survey.complete', {}, { '{:id}': currentUser.id });
    let user = response.data;
    if (user) {
      mergeUser(currentUser, user);
    }
    return returnSuccess();
  } catch (err) {
    rollbar.error(`Error while send survey complete: id: ${JSON.stringify(currentUser)}, err: ${err} stack: ${err.stack}`);
    return returnError(err);
  }
}

const setSeenLoggedInWelcomeScreenAction = (userId) => {
  setSeenLoggedInWelcomeScreen();
  try {
    requests.put('core', 'user.welcome.seen', {}, { '{:id}': userId });
    return returnSuccess();
  } catch (err) {
    rollbar.error(`Error while send welcome seen: id: ${userId}, err: ${err} stack: ${err.stack}`);
    return returnError(err);
  }
}

export {
  sendSurveySeen,
  sendSurveyComplete,
  setSeenLoggedInWelcomeScreenAction,
}
