
import React, { 
  useState,
  useEffect,
} from 'react';

import { 
  useStateMachine,
} from '../../react-shared/src/statemachine';

import {
  STATES,
} from '../../constants';

import Container from '@material-ui/core/Container';
import { css } from 'aphrodite/no-important';
import commonStyles from '../commonStyles';

import LibraryCatalogState from './LibraryCatalogState';
import LibraryErrorState from './LibraryErrorState';
import LibraryLoadingState from './LibraryLoadingState';

const LibraryStateMachine = (props) => {
  const updateStateMachineState = function(newStateMachineState) {
    setStateMachineState(newStateMachineState)
  }

  // Hooks
  let [smState] = useStateMachine({ 
    updateCallback: updateStateMachineState
  });
  const [stateMachineState, setStateMachineState] = useState(smState);
  const [started, setStarted] = useState(false);

  useEffect(() => {
    setStarted(true);
  }, []);

  if (!started) {
    return (
      <div> LIBRARY STATE </div>
    )
  }
  
  return ( 
    <Container className = { css([commonStyles.flexColumnAllCenter, commonStyles.fullWidthCenter]) }>
      {
        stateMachineState.flattenedValue.includes(STATES.LIBRARY.CATALOG) &&
        <LibraryCatalogState history={ props.history } />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.LIBRARY.ERROR) &&
        <LibraryErrorState />
      }
      {
        stateMachineState.flattenedValue.includes(STATES.LIBRARY.LOADING) &&
        <LibraryLoadingState />
      }
    </Container>
  );
}

export default LibraryStateMachine;
