
import { 
  STATES,
  STATE_EVENTS,
  STATE_MACHINE,
} from '../constants';

import { sharedLoggedInStates } from './shared_logged_in_states';

const APP_STATES = STATES.APP;
const APP_EVENTS = STATE_EVENTS.APP;

const appErrorState = { on: {}, }

// define at app level
const deviceDetectState = { on: {
  [STATE_EVENTS.COMMON.NEXT]: APP_STATES.LOGIN,
  [STATE_EVENTS.COMMON.ERROR]: APP_STATES.APP_ERROR,
}, }

const initState = { on: {
  [STATE_EVENTS.COMMON.ERROR]: APP_STATES.APP_ERROR,
  [STATE_EVENTS.COMMON.NEXT]: APP_STATES.DEVICE_DETECT,
}, }

const devTestState = { on: {}, }

const loginState = { on: {
  [STATE_EVENTS.COMMON.ERROR]: APP_STATES.APP_ERROR,
  [STATE_EVENTS.COMMON.NEXT]: APP_STATES.LOGGED_IN,
}, }

const loggedInState = {
  ...sharedLoggedInStates,
  on: {
    [STATE_EVENTS.COMMON.ERROR]: APP_STATES.APP_ERROR,
    [APP_EVENTS.LOGOUT]: APP_STATES.LOGGED_OUT,
  },
};

const loggedOutState = { on: {
  [STATE_EVENTS.COMMON.ERROR]: APP_STATES.APP_ERROR,
  [APP_EVENTS.LOGIN]: APP_STATES.LOGIN,
}, };

const loginErrorState = { on: {
  [APP_EVENTS.LOGIN]: APP_STATES.LOGIN,
}, }

const appStates = {
  id: STATE_MACHINE.NAMES.APP_STATE_MACHINE,
  initial: APP_STATES.INIT,
  // initial: APP_STATES.DEV_TEST,
  flattenedValue: APP_STATES.INIT,
  states: {
    [APP_STATES.APP_ERROR]: appErrorState,
    [APP_STATES.DEVICE_DETECT]: deviceDetectState,
    [APP_STATES.INIT]: initState,
    [APP_STATES.LOGIN]: loginState,
    [APP_STATES.LOGGED_IN]: loggedInState,
    [APP_STATES.LOGGED_OUT]: loggedOutState,
    [APP_STATES.LOGIN_ERROR]: loginErrorState,
    [APP_STATES.DEV_TEST]: devTestState,
  },
};

export {
  appStates as sharedAppStates,
}
