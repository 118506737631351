/* eslint no-undef: 0 */

import merge from 'lodash/merge';

import { sharedConfig } from '../react-shared/src/config/config';

const devConfig = {
  development: true,
  env: 'development',
  locale: 'en_us',
  version: '1.2',
  services: {
    core: {
      url: 'http://127.0.0.1:7001',
    },
    features_config: {
      allow_segment: true,
      allow_rollbar: true,
    },
    rails: {
      askSSO: false,
      authUrl: 'https://staging-60.lumosity.com/authorization',
      lumosUrl: 'https://staging-60.lumosity.com/',
      lumosPrivacyUrl: 'https://www.lumosity.com/en/legal/privacy_policy/',
      lumosPlatformCheckUrl: 'https://www.lumosity.com/platform_check/',
      authQueryParams: {
        response_type: 'code',
        scope: 'user',
        client_id: 'a6bm4iyhknll8xj4e0p8wincv',
        redirect_uri: 'http://127.0.0.1:7000/authorization',
      },
    },
    rollbar: {
      token: 'cec8261aa0a0419a87486135ad974036',
    }
  },
}

const stagingConfig = {
  development: true,
  env: 'staging',
  services: {
    core: {
      url: 'https://ncpt-backend.staging.eks.lumoslabs.net',
    },
    features_config: {
      allow_segment: true,
      allow_rollbar: true,
    },
    rails: {
      askSSO: false,
      authUrl: 'https://staging-60.lumosity.com/authorization',
      lumosUrl: 'https://staging-60.lumosity.com/',
      authQueryParams: {
        client_id: '84w09qmhil566knjlsnpexelg',
        redirect_uri: 'https://ncpt-frontend.staging.eks.lumoslabs.net/authorization',
      },
    }
  }
}

const productionConfig = {
  development: false,
  env: 'production',
  services: {
    core: {
      url: 'https://ncpt-backend.lumosity.com',
    },
    features_config: {
      allow_segment: true,
      allow_rollbar: true,
    },
    rails: {
      askSSO: false,
      authUrl: 'https://www.lumosity.com/authorization',
      lumosUrl: 'https://www.lumosity.com/',
      authQueryParams: {
        client_id: '84w09qmhil566knjlsnpexelg',
        redirect_uri: 'https://ncpt.lumosity.com/authorization',
      },
    }
  }
}

let finalConfig = merge({}, sharedConfig, devConfig);

switch (window.REACT_APP_ENVIRONMENT) {
  case 'staging':
    finalConfig = merge({}, finalConfig, stagingConfig);
    break;
  case 'production':
    finalConfig = merge({}, finalConfig, productionConfig);
    break;
  default:
    break;
}

console.log(finalConfig);

window.globalConfig = { ...finalConfig };
export const config = { ...finalConfig };
