
import flatten from 'flat';

const flattenKeysWithValue = (obj) => {
  return flatten(obj);
}

const flattenKeyValueIntoArray = (obj) => {
  if (typeof obj !== 'object') {
    return [obj];
  }
  let flatObj = flattenKeysWithValue(obj);
  let flattenedList = [];
  for( let[key, value] of Object.entries(flatObj)) {
    flattenedList.push(key+'.'+String(value));
  }
  return flattenedList;
}

const returnSuccess = (extras={}) => {
  return {
    success: true,
    ...extras,
  }
}

const returnComplete = (extras={}) => {
  return {
    complete: true,
    ...extras,
  }
}

const returnError = (error, message='', extras={}) => {
  return {
    error,
    message,
    success: false,
    ...extras,
  }
}

export {
  flattenKeysWithValue,
  flattenKeyValueIntoArray,
  returnSuccess,
  returnComplete,
  returnError,
}
