
import React, { 
  useEffect,
  useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';

import Container from '@material-ui/core/Container';
import { css, StyleSheet } from 'aphrodite/no-important';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import {
  LocalizedStrings,
} from '../../locale';

import {
  STORES,
  STATE_EVENTS,
} from '../../constants';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  getUserFirstName,
} from '../../stores/user_store_getters';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import { 
  updateBatteryData
} from '../../actions/battery_actions';

import {
  getCurrentUserStateBatteryRunId,
  getFutureBattery,
} from '../../stores/user_store_getters';

import {
  convertToCurrentUserTimeZone,
  diffTime,
  getCurrentUTCString,
  readableFormatWithHourAndMinute,
} from '../../utils/time_utils';

import {
  backToLumos,
} from '../../utils/link_utils';

import commonStyles from '../commonStyles';
import PageTitleSubheader from '../subheader/PageTitleSubheader';
import BatteryTextContainer from './BatteryTextContainer';

import LoadingComponent from '../LoadingComponent';

const BatteryCompletedState = () => {

  const PAGE_NAME = 'battery_thanks';
  const updateUserStore = (newUserStoreState) => {
    userStoreState = newUserStoreState;
    const futureBattery = getFutureBattery(userStoreState);
    
    let studyBeginDays = 0;
    if (!isEmpty(futureBattery)) {
      setCompletedText(LocalizedStrings.batteryCompeletedBodyKnownNextStudy);
      setStudyBeginTime(readableFormatWithHourAndMinute(
        convertToCurrentUserTimeZone(futureBattery.battery_begin_time)));
      studyBeginDays = diffTime(futureBattery.battery_begin_time,
        convertToCurrentUserTimeZone(getCurrentUTCString()),
        'days');
      setStudyBeginDays(studyBeginDays);
    }
    setLoaded(true);
  }

  const goToLumosity = () => {
    backToLumos();
  }

  // Hooks
  let [userStoreState] = useStoreState(STORES.USER.NAME, updateUserStore);
  let [, sendStateMachineEvent] = useStateMachine({});
  
  const userName = getUserFirstName(userStoreState);
  const [loaded, setLoaded] = useState(false);
  const [completedText, setCompletedText] = useState(LocalizedStrings.batteryCompeletedBodyUnknownNextStudy);
  const [studyBeginTime, setStudyBeginTime] = useState(convertToCurrentUserTimeZone(getCurrentUTCString()));
  const [studyBeginDays, setStudyBeginDays] = useState(1);

  const adminOnlyQuit = () => {
    sendStateMachineEvent(STATE_EVENTS.COMMON.NEXT);
  }

  const goToResultsHistory = () => {
    sendStateMachineEvent(STATE_EVENTS.BATTERY.RESULTS_HISTORY);
  }

  const loadFutureBatteryIfAny = async () => {
    let futureBatteryId = getCurrentUserStateBatteryRunId(userStoreState);
    if (futureBatteryId) {
      await updateBatteryData(userStoreState, futureBatteryId);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    analyticsPageView(PAGE_NAME);
    loadFutureBatteryIfAny();
  }, [])

  return (
    <Container className = { css([commonStyles.fullWidthCenter, styles.completedContainer]) }>
      <PageTitleSubheader 
        title= { LocalizedStrings.batteryCompeletedHeader } 
        userName = { userName }
      />
      { 
        !loaded && 
        <Container>
          <LoadingComponent title="Loading" />
        </Container>
      }
      {
        loaded &&
        <BatteryTextContainer
          batteryBodyText = { completedText }
          studyBeginTime = { studyBeginTime }
          studyBeginDays = { studyBeginDays }
          userName = { userName }
          showAdminOnlyQuit = { true }
          onResultsHistoryClick = { goToResultsHistory }
          adminOnlyQuit = { adminOnlyQuit }
          showActionButton = { true }
          actionButtonText = { 'Back To Lumosity' }
          actionButtonClicked = { goToLumosity }
          currentPage = { PAGE_NAME }
          wideButtonId = { 'battery_completed_back' }
        />
      }
    </Container>
  );
}

const styles = StyleSheet.create({
  completedContainer: {
    height: '805px',
    margin: 0,
    padding: 0,
  }
});

export default BatteryCompletedState;

