

import { 
  STATES,
  STATE_EVENTS,
  STATE_MACHINE,
} from '../constants';

const BATTERY_STATES = STATES.BATTERY;
const BATTERY_EVENTS = STATE_EVENTS.BATTERY;

const batteryInterstitialState = { 
  id: STATE_MACHINE.NAMES.BATTERY_STATE_MACHINE_INTERSTITIAL,
  on: {}, };
batteryInterstitialState.on[BATTERY_EVENTS.ASSESSMENT] = `#${STATE_MACHINE.NAMES.ASSESSMENT_STATE_MACHINE}`;
batteryInterstitialState.on[BATTERY_EVENTS.SUMMARY] = BATTERY_STATES.SUMMARY;
batteryInterstitialState.on[BATTERY_EVENTS.COMPLETED] = BATTERY_STATES.COMPLETED;

const batteryCompletedState = { on: {}, };
batteryCompletedState.on[STATE_EVENTS.COMMON.NEXT] = `#${STATE_MACHINE.NAMES.LIBRARY_STATE_MACHINE}`;
batteryCompletedState.on[STATE_EVENTS.BATTERY.RESULTS_HISTORY] = BATTERY_STATES.RESULTS_HISTORY;

const batteryNotStartedState = { on: {}, }
batteryNotStartedState.on[STATE_EVENTS.COMMON.NEXT] = `#${STATE_MACHINE.NAMES.LIBRARY_STATE_MACHINE}`;
batteryNotStartedState.on[STATE_EVENTS.BATTERY.RESULTS_HISTORY] = BATTERY_STATES.RESULTS_HISTORY;

const batteryPrepState = { on: {}, }
batteryPrepState.on[STATE_EVENTS.COMMON.NEXT] = BATTERY_STATES.INTERSTITIAL;

const batteryResumeState = { on: {}, }
batteryResumeState.on[STATE_EVENTS.COMMON.NEXT] = BATTERY_STATES.PREP;

const batterySummaryState = { 
  id: STATE_MACHINE.NAMES.BATTERY_STATE_MACHINE_SUMMARY,
  on: {}, 
}
batterySummaryState.on[STATE_EVENTS.COMMON.NEXT] = BATTERY_STATES.COMPLETED;
batterySummaryState.on[STATE_EVENTS.BATTERY.RESULTS_HISTORY] = BATTERY_STATES.RESULTS_HISTORY;
batterySummaryState.on[STATE_EVENTS.COMMON.HOME] = `#${STATE_MACHINE.NAMES.LOGGED_IN_STATE_MACHINE}`;

const batteryResultsHistoryState = { 
  id: STATE_MACHINE.NAMES.BATTERY_STATE_MACHINE_RESULTS_HISTORY,
  on: {}, 
}
batteryResultsHistoryState.on[STATE_EVENTS.BATTERY.SUMMARY] = BATTERY_STATES.SUMMARY;
batteryResultsHistoryState.on[STATE_EVENTS.COMMON.NEXT] = `#${STATE_MACHINE.NAMES.LOGGED_IN_STATE_MACHINE}`;

const batteryWelcomeState = { on: {}, };
batteryWelcomeState.on[BATTERY_EVENTS.RESUME] = BATTERY_STATES.RESUME;
batteryWelcomeState.on[STATE_EVENTS.COMMON.NEXT] = BATTERY_STATES.PREP;
batteryWelcomeState.on[BATTERY_EVENTS.NOT_STARTED] = BATTERY_STATES.NOT_STARTED;
batteryWelcomeState.on[BATTERY_EVENTS.COMPLETED] = BATTERY_STATES.COMPLETED;
batteryWelcomeState.on[BATTERY_EVENTS.SUMMARY] = BATTERY_STATES.SUMMARY;

const batteryStates = {
  id: STATE_MACHINE.NAMES.BATTERY_STATE_MACHINE,
  initial: BATTERY_STATES.WELCOME,
  states: {}
};

batteryStates.states[BATTERY_STATES.COMPLETED] = batteryCompletedState;
batteryStates.states[BATTERY_STATES.INTERSTITIAL] = batteryInterstitialState;
batteryStates.states[BATTERY_STATES.NOT_STARTED] = batteryNotStartedState;
batteryStates.states[BATTERY_STATES.PREP] = batteryPrepState;
batteryStates.states[BATTERY_STATES.RESUME] = batteryResumeState;
batteryStates.states[BATTERY_STATES.SUMMARY] = batterySummaryState;
batteryStates.states[BATTERY_STATES.RESULTS_HISTORY] = batteryResultsHistoryState;
batteryStates.states[BATTERY_STATES.WELCOME] = batteryWelcomeState;

export {
  batteryStates,
}
