import { registerStateMachine } from '../react-shared/src/statemachine';

import {
  STATES,
  STATE_EVENTS,
  STATE_MACHINE,
} from '../constants';

import { sharedAppStates } from '../react-shared/src/statemachine/shared_app_states';
import { loggedInStates } from './logged_in_states';

const appStates = {
  ...sharedAppStates,
};

const loggedInState = {
  on: {
    [STATE_EVENTS.APP.LOGOUT]: STATES.APP.LOGGED_OUT,
  },
  ...loggedInStates,
};

const deviceDetectState = { on: {}, }
deviceDetectState.on[STATE_EVENTS.COMMON.NEXT] = STATES.APP.NCPT_LOGIN;

const lumosSSOLoginState = { on: {}, };
lumosSSOLoginState.on[STATE_EVENTS.COMMON.ERROR] = STATES.APP.LOGIN_ERROR;
lumosSSOLoginState.on[STATE_EVENTS.COMMON.SUCCESS] = STATES.APP.LOGGED_IN;

const ncptLoginState = { on: {}, };
ncptLoginState.on[STATE_EVENTS.COMMON.ERROR] = STATES.APP.LOGIN_ERROR;
ncptLoginState.on[STATE_EVENTS.COMMON.SUCCESS] = STATES.APP.LUMOS_SSO_LOGIN;

appStates.states[STATES.APP.DEVICE_DETECT] = deviceDetectState;
appStates.states[STATES.APP.LOGGED_IN] = loggedInState;
appStates.states[STATES.APP.LUMOS_SSO_LOGIN] = lumosSSOLoginState;
appStates.states[STATES.APP.NCPT_LOGIN] = ncptLoginState;

const [appStateMachine, appStateMachineService] = registerStateMachine(STATE_MACHINE.NAMES.APP_STATE_MACHINE, appStates);
appStateMachineService.start();

export {
  appStates
}
