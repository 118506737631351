
import * as _ from 'lodash';

import React, { 
  useEffect,
} from 'react';

import AssessmentCardComponent from '../assessment_states/AssessmentCardComponent';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import '../Assessment.css';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  STORES,
  STATE_EVENTS,
} from '../../constants';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import { 
  getInitialPath
} from '../../react-shared/src/stores/app_store_getters';

import { 
  getAssessmentToLaunchFromPath,
  launchAssessment,
} from '../../actions/library_actions';

import { css, StyleSheet } from 'aphrodite/no-important';
import commonStyles from '../commonStyles';

function LibraryCatalogState(props) {
  let [appStoreState] = useStoreState(STORES.APP.NAME, undefined);
  const [, sendStateMachineEvent] = useStateMachine({});

  const launchCatalogAssessment = (assessmentConfig) => {
    launchAssessment(assessmentConfig, props.history);
    sendStateMachineEvent(STATE_EVENTS.LOGGED_IN.PLAY);
  }
  
  const checkAndLaunchFromPath = () => {
    let path = getInitialPath(appStoreState);
    if (!path) {
      return;
    }
    
    let assessmentConfig = getAssessmentToLaunchFromPath(path, appStoreState[STORES.APP.ASSESSMENT_BY_SLUG_KEY]);
    if (assessmentConfig) {
      launchCatalogAssessment(assessmentConfig);
    }
  }

  const assessmentCards = appStoreState[STORES.APP.ASSESSMENTS_CONFIG_KEY].map((assessment, index) => {
    return (
      <Grid item key={index} xs={12} sm={6} md={4}> 
        <AssessmentCardComponent
          params={assessment}
          launchAssessment={launchCatalogAssessment} />
      </Grid>
    );
  });

  useEffect(() => {
    analyticsPageView('catalog');
    checkAndLaunchFromPath();
  }, []);

  return (
    <Container  className = { css([commonStyles.flexColumnAllCenter, styles.catalogContainer]) }>
      <Grid container spacing={4}>
        {assessmentCards}
      </Grid>
    </Container>
  );
}

const styles = StyleSheet.create({
  catalogContainer: {
    marginTop: 30,
    height: '805px',
    width: 900,
    justifyContent: 'flex-start',
    // marginLeft: 'auto',
    // marginRight: 0,
  }
});
// AssessmentsLibraryComponent.propTypes = {
//   location: PropTypes.object,
//   history: PropTypes.object,
// }

export default LibraryCatalogState;
