
import React, { 
  useState,
  useEffect
} from 'react';

import Container from '@material-ui/core/Container';
import { css, StyleSheet } from 'aphrodite/no-important';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import {
  STORES,
  STATE_EVENTS,
} from '../../constants';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import {
  setChosenBatterySummaryId,
} from '../../stores/user_store';

import {
  getUserId,
} from '../../stores/user_store_getters';

import { 
  fetchResultsHistory,
} from '../../actions/battery_actions';

import {
  backToLumos,
} from '../../utils/link_utils';

import commonStyles from '../commonStyles';
import PageTitleSubheader from '../subheader/PageTitleSubheader';
import LoadingComponent from '../LoadingComponent';
import BatteryResultsHistoryView from './BatteryResultsHistoryView';

const BatteryResultsHistoryState = () => {

  const PAGE_NAME = 'battery_results_history';

  // Hooks
  let [userStoreState] = useStoreState(STORES.USER.NAME);
  
  let [loaded, setLoaded] = useState(false);
  let [, sendStateMachineEvent] = useStateMachine({});

  const goToSummaryState = (chosenBatterySummaryId) => {
    setChosenBatterySummaryId(chosenBatterySummaryId);
    sendStateMachineEvent(STATE_EVENTS.BATTERY.SUMMARY);
  }

  const goToLumosity = () => {
    backToLumos();
  }

  const goToNextState = () => {
    sendStateMachineEvent(STATE_EVENTS.COMMON.NEXT);
  }

  const loadBatteryResultsHistory = async () => {
    const userId = getUserId(userStoreState);
    let fetchResult = await fetchResultsHistory(userId);
    if (fetchResult.success) {
      setLoaded(true);
    } else {
      goToNextState();
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    analyticsPageView(PAGE_NAME);
    loadBatteryResultsHistory();
  }, [])

  return (
    <Container className = { css([commonStyles.fullWidthCenter, styles.stateSummaryContainer]) }>
      <PageTitleSubheader 
        title= { 'Results History' }
      />
      { 
        !loaded && 
        <Container>
          <LoadingComponent title="Fetching History" />
        </Container>
      }
      {
        loaded &&
        <Container className = { css([commonStyles.flexJustifyCenter, commonStyles.fullWidthCenter]) }>
          <BatteryResultsHistoryView
            backToLumosText = { 'Back To Lumosity' }
            actionButtonText = { 'Home' }
            backToLumosClicked = { goToLumosity }
            actionButtonClicked = { goToNextState }
            onCardClick = { goToSummaryState }
            currentPage = { PAGE_NAME }
          />
        </Container>
      }
    </Container> 
  );
}

const styles = StyleSheet.create({
  stateSummaryContainer: {
    height: '720px',
    margin: 0,
    padding: 0,
  },
});



export default BatteryResultsHistoryState;

