
import * as queryString from 'query-string';

import { 
  removeInitialPath,
  setInitialPathAndParam,
} from '../stores/app_store';

import {
  loginUser,
  logoutUser as logoutUserStore,
} from '../stores/user_store';

import { 
  returnSuccess,
  returnError,
} from '../utils/obj_utils';

import requests from '../routes/backend_routes';
import rollbar from '../react-shared/src/logging/rollbar';

////////// PUBLIC //////////////

const logoutUser = async () => {
  logoutUserStore();
}

const lumosSSOLogin = async (initialPath) => {
  const { code } = queryString.parse(initialPath.params);
  try {
    removeInitialPath(initialPath);
    const ssoResponse = await requests.post('core', 'sessions.authorization', { 
      code
    });
    loginUser(ssoResponse.data);
    return returnSuccess();
  } catch (err) {
    rollbar.error(`Error while lumosSSO login 
      params: ${JSON.stringify(initialPath)},
      err: ${err} 
      stack: ${err.stack}`);
    return returnError(err);
  }
}

const ncptLogin = async () => {
  try {
    const response = await requests.post('core', 'sessions.sessions');
    loginUser(response.data);
    return returnSuccess();
  } catch(err) {
    rollbar.error(`Error while ncpt login err: ${err}
      stack: ${err.stack}`);
    return returnError(err);
  }
}

const saveInitPath = () => {
  let searchParams = '';
  let path = '';

  if (window.location.search) {
    searchParams = window.location.search;
  }

  if (window.location.pathname && window.location.pathname !== '/') {
    path = window.location.pathname;
  }
  
  if (path) {
    setInitialPathAndParam(path, searchParams);
  }
}

export {
  logoutUser,
  lumosSSOLogin,
  ncptLogin,
  saveInitPath,
}
