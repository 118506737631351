
import { 
  removeInitialPath,
} from '../stores/app_store';

import { 
  setNewAssessmentConfig,
} from '../stores/user_store';

import { 
  setAssessmentsConfig,
} from '../stores/app_store';

import {
  returnSuccess,
  returnError,
} from '../utils/obj_utils';

import requests from '../routes/backend_routes';
import rollbar from '../react-shared/src/logging/rollbar';

const loadAssessments = async function() {
  try {
    const response = await requests.get('core', 'catalog');
    const assessments = await response.data;
    setAssessmentsConfig(assessments);
    return returnSuccess();
  } catch (err) {
    rollbar.error(`Error while loading assessments err: ${err} stack: ${err.stack}`);
    return returnError(err);
  }
}

const launchAssessment = (assessmentConfig, history) => {
  history.push(`/${assessmentConfig.path}`, assessmentConfig);
  setNewAssessmentConfig(assessmentConfig);
}

const getAssessmentToLaunchFromPath = (initialPath, assessmentSlugMap) => {
  let assessmentToLoadFromPath = undefined;
  if (!initialPath) { 
    return assessmentToLoadFromPath;
  }
  if (assessmentSlugMap[initialPath.path]) {
    assessmentToLoadFromPath = assessmentSlugMap[initialPath.path];
    removeInitialPath(initialPath);
  }
  return assessmentToLoadFromPath;
}

export {
  getAssessmentToLaunchFromPath,
  loadAssessments,
  launchAssessment,
}
