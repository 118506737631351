export const lumosBlack = '#333333'; // aka grayDark
export const lumosWhite = '#FFFFFF';
export const lumosGold = '#FCB913'; // aka yellow
export const lumosKumquat = '#F98816'; // aka orangeLight
export const lumosOrange = '#F1693C';
export const lumosRaspberry = '#D84C5E';
export const lumosPlum = '#9A4B8A';
export const lumosDarkBlue = '#0A5960'; // aka blueDark
export const lumosBlue = '#0E91A1';
export const lumosTeal = '#4DBCB6';
export const lumosKale = '#5ABE82';
export const lumosPistachio = '#B0CA5F';
export const green = '#A7C81F'; // from lux 1
export const tealDark = '#077380'; // from lux 1
export const black = '#000';
export const grayDarker = '#222';
export const redLight = '#ED1C24';
export const turquoise = '#CBE6EA';
export const whiteAt98 = 'rgba(256, 256, 256, 0.98)';
export const blackAt62 = 'rgba(0, 0, 0, 0.62)';
export const blackAt89 = 'rgba(0, 0, 0, 0.89)';

const base = {
  black: black,
  green: green,
  grayDarker: grayDarker,
  tealDark: tealDark,
  redLight: redLight,
  turquoise: turquoise,
  lumosBlack: lumosBlack,
  lumosWhite: lumosWhite,
  lumosGold: lumosGold,
  lumosKumquat: lumosKumquat,
  lumosOrange: lumosOrange,
  lumosRaspberry: lumosRaspberry,
  lumosPlum: lumosPlum,
  lumosDarkBlue: lumosDarkBlue,
  lumosTeal: lumosTeal,
  lumosKale: lumosKale,
  lumosPistachio: lumosPistachio,
  whiteAt98: whiteAt98,
  blackAt89: blackAt89,
  blackAt62: blackAt62
};

export default base;