import React from 'react';

import { css, StyleSheet } from 'aphrodite';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Dropdown from 'react-bootstrap/Dropdown';

import { 
  analyticsTrack,
} from '../../react-shared/src/segment/analytics';

import { base } from '../lumos_common/colors';
import commonStyles from '../commonStyles';

// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ 
  children,
  onClick,
  className,
}, ref) => (
  <Container
    ref = {ref}
    className = { className }
    onClick = {e => {
      e.preventDefault();
      analyticsTrack('link_click', {
        id: 'profile_id',
      });
      onClick(e);
    }}
  >
    {children}
  </Container>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(({
  children,
  className,
  show,
}, ref) => {
  return (
    <Container
      ref={ref}
      className={className}
    >
      { show && children }
    </Container>
  );
});

const HeaderDropdown = (props) => {
  
  return (
    <Dropdown className = { css([commonStyles.flexRowAllCenter, styles.dropdown]) }>
      <Dropdown.Toggle 
        as={CustomToggle}
        className= { css([commonStyles.flexAlignCenter, styles.dropdownToggle]) }>
        <Container className= { css([commonStyles.simpleFlex, styles.titleContainer]) }>
          <Typography className= { css(styles.title) }>
            { props.title }
          </Typography>
        </Container>
      </Dropdown.Toggle>
      <Dropdown.Menu 
        as={CustomMenu}
        className= {css(styles.dropdownMenuContainer)} 
        listItemClassName= {css(styles.dropdownMenuContainerItem)}> 
        <Dropdown.Item
          onClick = {e => {
            e.preventDefault();
            analyticsTrack('link_click', {
              id: 'back_to_lumos_click',
              next_page: props.lumosLink,
            });
            props.backToLumosCallback();
          }}
          className = {css(styles.dropdownMenuContainerItemTitle)}>
            { 'Back To Lumosity'}
        </Dropdown.Item>
        {
          props.isTester && <Dropdown.Item 
          onClick = {e => {
            e.preventDefault();
            props.goToCatalog();
          }}
          className = {css(styles.dropdownMenuContainerItemTitle)}>
          { 'Catalog'}
          </Dropdown.Item>
        }
        <Dropdown.Item 
          onClick = {e => {
            e.preventDefault();
            analyticsTrack('link_click', {
              id: 'logout_click',
              next_page: props.logoHref,
            });
            props.logoutCallback();
          }}
          className = {css(styles.dropdownMenuContainerItemTitle)}>
          { 'Logout'}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const styles = StyleSheet.create({
  dropdown: {
    cursor: 'pointer',
  },
  dropdownToggle: {
    color: base.lumosWhite,
  },
  dropdownMenuContainer: {
    backgroundColor: base.lumosDarkBlue,
    padding: 0,
    margin: 0,
    width: 'fit-content',
  },
  dropdownMenuContainerItem: {
    padding: 0,
    margin: 0,
  },
  dropdownMenuContainerItemTitle: {
    color: base.lumosWhite,
    fontFamily: "MuseoSans500",
    fontSize: 15,
    letterSpacing: '1px',
    ':active': {
      backgroundColor: base.lumosBlue,
    },
    ':hover': {
      backgroundColor: base.lumosOrange,
    }
  },
  titleContainer: {
    justifyContent: 'flex-start',
    marginLeft: 10,
    width: '130px',
  },
  title: {
    color: base.lumosWhite,
    fontFamily: "MuseoSans500",
    fontSize: 15,
    letterSpacing: '1px',
    textAlign: 'left',
    overflow: 'hidden',
  }
});

export default HeaderDropdown;
