
import React from 'react';

import Container from '@material-ui/core/Container';

import ProgressBar from 'react-bootstrap/ProgressBar';

import './cocos.css';

import { css, StyleSheet } from 'aphrodite';

import commonStyles from '../commonStyles';

function AssessmentProgressBar(props) {
  
  return (
    <Container className = { css([commonStyles.flexJustifyCenter, styles.progressBarContainer]) }>
      {/* Overwrite react boostrap progress bar styles */}
      {/* <ProgressBar now={props.progressLevel} label={`${props.progressLevel}%`}/> */}
      <ProgressBar animated={ false } now={props.progressLevel} />
    </Container> 
  );
}

const styles = StyleSheet.create({
  progressBarContainer: {
    height: '15px',
    position: 'absolute',
    top: '400px',
    width: 400,
  },
});

export default AssessmentProgressBar;
