import React, {
  useEffect,
  useState
} from 'react';

// import PropTypes from "prop-types";
import Script from 'react-load-script';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';

import { css, StyleSheet } from 'aphrodite';

import get from 'lodash/get';

import {
  Link,
} from "react-router-dom";

import { 
  analyticsPageView,
  analyticsTrack,
} from '../../react-shared/src/segment/analytics';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  STATES,
  STATE_EVENTS,
  STORES,
} from '../../constants';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import {
  parseAssessmentEventData,
  handleEventActions,
} from '../../actions/assessment_actions';

import {
  isTester,
} from '../../stores/user_store_getters';

import commonStyles from '../commonStyles';
import AssessmentProgressBar from './AssessmentProgressBar';

import { config } from '../../config/config';

function AssessmentPlayState(props) {
  
  const updateUserStore = (newUserStoreState) => {
    userStoreState = newUserStoreState;
  }

  const parseEventData = async (data) => {
    let { eventName, eventData } = await parseAssessmentEventData(data);
    
    // Mutli step event handler. this requires pre async stuff
    switch(eventName) {
      case 'game:loadStart':
        setShowProgress(true);
        break;
      case 'game:loadProgress':
        if (eventData > progressLevel) {
          setProgressLevel(eventData);
        }
        break;
      case 'game:loadComplete':
        setShowProgress(false);
        break;
      default:
          // Do nothing
    }

    await handleEventActions(eventName, eventData, userStoreState[STORES.USER.CURRENT_ASSESSMENT_RUN_KEY], userStoreState);

    // post async event handler
    switch(eventName) {
      case 'game:complete':
        setAssessmentCompleted(true);
        sendStateMachineEvent(STATE_EVENTS.ASSESSMENT.COMPLETED);
        break;
      default:
        // Do nothing
    }
  }

  let [userStoreState] = useStoreState(STORES.USER.NAME, updateUserStore);
  const updateStateMachineState = function(newStateMachineState) {
    stateMachineState = newStateMachineState;
    // setStateMachineState(newStateMachineState);
  }

  // Hooks
  let [smState, sendStateMachineEvent] = useStateMachine({ 
    updateCallback: updateStateMachineState
  });
  let [stateMachineState] = useState(smState);
  let [progressLevel, setProgressLevel] = useState(0);
  let [showProgress, setShowProgress] = useState(false);
  let [assessmentCompleted, setAssessmentCompleted] = useState(false);
  
  const assessmentConfig = get(userStoreState, STORES.USER.CURRENT_ASSESSMENT_CONFIG_KEY, {});
  const assessmentRunId = get(userStoreState, STORES.USER.CURRENT_ASSESSMENT_RUN_ID_KEY, '');
  const gameResourcesUrl = assessmentConfig.game_url;
  // const gameResourcesUrl = `http://10.11.1.35/~sk/${assessmentConfig.game_slug}/`;
  let Lumos = {
    gamevars: {
      ...assessmentConfig.extras,
      game_resources_url: gameResourcesUrl,
    },
  }

  window.sendToJavaScript = (data) => {
    parseEventData(data);
  }
  window.Lumos = Lumos;
  
  useEffect(() => {
    analyticsPageView('assessment_play', { slug: assessmentConfig.slug });
    // Remove Cocos on unmount
    return () => {
      if (!assessmentCompleted) {
        // Send only if assessment was a real quit as this block is called even if completed successfully.
        analyticsTrack('assessment_quit', { slug: assessmentConfig.slug });
      }
      if (window.cc) {
        window.cc.director.end();
      }
      if (stateMachineState.flattenedValue.includes(STATES.ASSESSMENT.PLAY)) {
        props.playStateQuit();
      }
    };  
  }, []);
  
  return (
    <Container id="gameContainer" className = { css([commonStyles.fullWidthCenter, commonStyles.flexColumnAllCenter, styles.gameContainer])}>
      <Card id="gameDiv" className = { css([commonStyles.flexJustifyCenter, styles.gameFrame]) }>
        <canvas 
          id="gameCanvas" 
          className = { css([commonStyles.flexColumnAllCenter, styles.gameCanvas]) }
          width= "640" height= "480">
        </canvas>
        {
          showProgress && 
          <AssessmentProgressBar 
            progressLevel = { progressLevel }
          />
        }
        <Script attributes={{ id:"gameScript", ref:"gameScript"}}  url={gameResourcesUrl + "game.js" + "?ts=" + Date.now()} />
      </Card>
      {
        config.development && (config.env === 'staging' || config.env === 'development' || isTester(userStoreState)) && 
        <Container className = { css([commonStyles.flexRowAllCenter, styles.skipTextContainer]) }> 
          <Link to = ''
            onClick={ () => props.skipGame(assessmentRunId) } replace
          >
            Skip
          </Link>
        </Container>
      }
    </Container>
  );
}

const styles = StyleSheet.create({
  gameContainer: {
    height: '720px',
    paddingTop: '24px',
  },
  gameFrame: {
    width: 640,
    minWidth: '640px',
    height: 480,
    minHeight: '480px',
    position: 'relative',
  },
  gameCanvas: {
    maxWidth: 640,
    maxHeight: 480,
  },
  homeContainer: {
    paddingBottom: 20,
  },
  homeText: {
  },
  skipTextContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex',
    margin: 0,
    padding: 0,
  },
});


export default AssessmentPlayState;
