

import React from 'react';
import {FormattedMessage} from 'react-intl';

import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import { css, StyleSheet } from 'aphrodite/no-important';

import { 
  analyticsTrack,
} from '../../react-shared/src/segment/analytics';

import commonStyles from '../commonStyles';
import tagetLogo from '../../assets/target_icon.png';

const BatterySummaryAssessmentRunCard = (props) => {
  // Hooks
  return (
    <Card className = { css([commonStyles.flexColumnAllCenter, styles.card]) } 
      onClick = {
        (e) => {
          analyticsTrack('card_click', {
            id: props.id,
            current_page: props.currentPage,
            slug: props.slug,
          });
          props.onClick();
          e.preventDefault();
        }
      }
    >
      <Container className= { css([commonStyles.flexRowAllCenter, styles.cardContents])  }>

        {/* Left Part */}
        <Container className = { css(styles.logo) }>
          <img 
            src = { tagetLogo } 
            className = { css(styles.logoImg) }
          />
        </Container>

        {/* Right Part */}
        <Container className = { css([commonStyles.flexColumnAllCenter, styles.rightContainer]) }>
          <Container className = { css([commonStyles.simpleFlex, styles.leftAlignText])}>
            <Typography className = { css(styles.title) }>
              { props.title }
            </Typography>
          </Container>
          <Container className = { css([commonStyles.simpleFlex, styles.leftAlignText]) }>
            <Typography  className = { css(styles.scoreText) }>
              <FormattedMessage
                id="resultDescription"
                defaultMessage={ props.description }
              />
            </Typography>
          </Container>
        </Container>
      </Container>
    </Card>
  );
}

const styles = StyleSheet.create({

  card: {
    width: '290px',
    height: '125px',
    cursor: 'pointer',
  },

  logo: {
    margin: 0,
    padding: 0,
    paddingLeft: 0,
    marginLeft: 0,
    width: '75px',
    display: 'flex',
    justifyContent: 'center',
  },

  logoImg: {
    height: 32,
    width: 32,
    margin: 0,
    padding: 0,
  },

  rightContainer: {
    marginRight: '5px',
  },

  textContainer: {

  },


  title: {
    color: '#222222',
    fontFamily: "MuseoSans500",
    fontSize: 18,
    letterSpacing: '1px',
  },

  description: {
    color: '#666666',
    fontFamily: "MuseoSans500",
    fontSize: 13,
    letterSpacing: '1px',
    marginTop: '10px',
  },

  leftAlignText: {
    textAlign: 'left',
    margin: 0,
    padding: 0,
  },

  scoreText: {
    color: '#F37444',
    fontFamily: "MuseoSans500",
    fontSize: 13,
    letterSpacing: '1px',
    marginTop: '10px',
  }

});

export default BatterySummaryAssessmentRunCard;
