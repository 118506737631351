

import { 
  STATES,
  STATE_EVENTS,
  STATE_MACHINE,
} from '../constants';
import { sharedLibraryStates } from './shared_library_states';

const LOGGED_IN_STATES = STATES.LOGGED_IN;
const LOGGED_IN_EVENTS = STATE_EVENTS.LOGGED_IN;

const accountState = { 
  on: {
    [LOGGED_IN_EVENTS.HOME]: LOGGED_IN_STATES.HOME,
    [LOGGED_IN_EVENTS.LIBRARY]: LOGGED_IN_STATES.LIBRARY,
  }, 
}

const decisionState = { 
  on: {
    [LOGGED_IN_EVENTS.ACCOUNT]: LOGGED_IN_STATES.ACCOUNT,
    [LOGGED_IN_EVENTS.HOME]: LOGGED_IN_STATES.HOME,
    [LOGGED_IN_EVENTS.LIBRARY]: LOGGED_IN_STATES.LIBRARY,
    [STATE_EVENTS.COMMON.SELF]: LOGGED_IN_STATES.DECISION,
    [LOGGED_IN_EVENTS.WELCOME]: LOGGED_IN_STATES.WELCOME,
  }, 
}

const homeState = { 
  on: {
    [LOGGED_IN_EVENTS.ACCOUNT]: LOGGED_IN_STATES.ACCOUNT,
    [LOGGED_IN_EVENTS.LIBRARY]: LOGGED_IN_STATES.LIBRARY,
    [STATE_EVENTS.COMMON.NEXT]: LOGGED_IN_STATES.DECISION,
  }, 
}

const initState = { 
  on: {
    [STATE_EVENTS.COMMON.NEXT]: LOGGED_IN_STATES.DECISION,
  }
}

const libraryState = {
  on: {},
  ...sharedLibraryStates,
};

const welcomeState = { 
  on: {
    [STATE_EVENTS.COMMON.NEXT]: LOGGED_IN_STATES.DECISION,
  }, 
}

const loggedInStates = {
  id: STATE_MACHINE.NAMES.LOGGED_IN_STATE_MACHINE,
  initial: LOGGED_IN_STATES.INIT,
  states: {
    [LOGGED_IN_STATES.ACCOUNT]: accountState,
    [LOGGED_IN_STATES.DECISION]: decisionState,
    [LOGGED_IN_STATES.HOME]: homeState,
    [LOGGED_IN_STATES.INIT]: initState,
    [LOGGED_IN_STATES.WELCOME]: welcomeState,
    // Update at app level
    [LOGGED_IN_STATES.LIBRARY]: libraryState,
  },
};

export {
  loggedInStates as sharedLoggedInStates,
}
