
import React, { 
  useEffect,
  useState,
} from 'react';

import Container from '@material-ui/core/Container';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  STORES,
  STATE_EVENTS,
} from '../../constants';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import {
  createAssessment,
  fetchAssessmentForBatteryRun
} from '../../actions/assessment_actions';

import {
  getAdminLibSetting,
  getCurrentAssessmentConfig,
  getCurrentBatteryAssessmentRunId,
} from '../../stores/user_store_getters';

import LoadingComponent from '../LoadingComponent';

function AssessmentCreateState(props) {
  let [userStoreState] = useStoreState(STORES.USER.NAME);
  const [, sendStateMachineEvent] = useStateMachine({});

  const [created, setCreated] = useState(false);
  
  const handleAssessmentCreation = async () => {
    // if there's a battery, fetch assessment for id.
    const batteryAssessmentRunId = getCurrentBatteryAssessmentRunId(userStoreState);
    let result;
    if (batteryAssessmentRunId && !getAdminLibSetting(userStoreState)) {
      result = await fetchAssessmentForBatteryRun(userStoreState, batteryAssessmentRunId);
    } else {
      // if there's a current config key, create a new one.
      result = await createAssessment(userStoreState, getCurrentAssessmentConfig(userStoreState));
    }

    if (result.success) {
      setCreated(true);
      props.startPracticeClicked();
    } else {
      sendStateMachineEvent(STATE_EVENTS.ASSESSMENT.ERROR);
    } 
  }
  
  useEffect(() => {
    analyticsPageView('assessment_create');
    handleAssessmentCreation();
  }, []);

  return (
    <Container> 
      { 
        !created && 
        <Container>
          <LoadingComponent title="Creating Assessment" />
        </Container>
      }
    </Container> 
  );
}

export default AssessmentCreateState;
