
import React, { useEffect } from 'react';

import Container from '@material-ui/core/Container';
import { css, StyleSheet } from 'aphrodite/no-important';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import {
  LocalizedStrings,
} from '../../locale';

import {
  STORES,
  STATE_EVENTS,
} from '../../constants';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  getUserFirstName,
} from '../../stores/user_store_getters';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import { 
  updateBatteryData
} from '../../actions/battery_actions';

import {
  getCurrentUserStateBatteryRunId,
} from '../../stores/user_store_getters';

import commonStyles from '../commonStyles';
import PageTitleSubheader from '../subheader/PageTitleSubheader';
import BatteryTextContainer from './BatteryTextContainer';

const BatteryResumeState = () => {

  const PAGE_NAME = 'battery_resume';

  const updateUserStore = (newUserStoreState) => {
    userStoreState = newUserStoreState;
  }

  // Hooks
  let [userStoreState] = useStoreState(STORES.USER.NAME, updateUserStore);
  
  let [, sendStateMachineEvent] = useStateMachine({});
  
  const startTest = () => {
    sendStateMachineEvent(STATE_EVENTS.COMMON.NEXT);
  }

  const loadFutureBatteryIfAny = async () => {
    let futureBatteryId = getCurrentUserStateBatteryRunId(userStoreState);
    if (futureBatteryId) {
      await updateBatteryData(userStoreState, futureBatteryId);
    }
  }

  useEffect(() => {
    analyticsPageView(PAGE_NAME);
    loadFutureBatteryIfAny();
  }, [])

  const userName = getUserFirstName(userStoreState);
  let resumeText = LocalizedStrings.batteryResumeBody;
  
  return (
    <Container className = { css([commonStyles.fullWidthCenter, styles.resumeContainer]) }>
      <PageTitleSubheader 
        title= { LocalizedStrings.batteryResumeHeader } 
        userName = { userName }
      />
      <BatteryTextContainer
        batteryBodyText = { resumeText }
        userName = { userName }
        showActionButton = { true }
        actionButtonText = { 'Continue' }
        actionButtonClicked = { startTest }
        currentPage = { PAGE_NAME }
        wideButtonId = { 'battery_resume_continue' }
      />
    </Container>
  );
}

const styles = StyleSheet.create({
  resumeContainer: {
    height: '805px',
    margin: 0,
    padding: 0,
  }
});



export default BatteryResumeState;

