
import React from 'react';

import { css, StyleSheet } from 'aphrodite/no-important';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { 
  analyticsTrack,
} from '../../react-shared/src/segment/analytics';

import lumosityLogo from '../../assets/lumosity_white_logo.png';
import avatarBasic from '../../assets/avatar_basic.png';

import HeaderDropdown from './HeaderDropdown';

import { base } from '../lumos_common/colors';
import { RoundedButton } from '../lumos_common/buttons';
import commonStyles from '../commonStyles';

const Header = (props) => {
  const links = props.links;
  const userName = props.userName;
  const showLogin = props.showLogin;
  const showSubheader = props.showSubheader;

  return (
    <Container className = { css([commonStyles.flexColumnAllCenter, styles.header]) }>
      <Container className = { css([commonStyles.flexRowAllCenter, styles.mainHeader]) }>
        <Container className = { css(styles.logo) }>
          <img 
              src = { lumosityLogo } 
              className = { css(styles.logoImg) }  
              alt = { 'Lumosity Logo' }
              onClick = {e => {
                analyticsTrack('link_click', {
                  id: 'lumos_logo',
                  next_page: links.logo.href,
                });
                props.backToLumosCallback();
                e.preventDefault();
              }}
          />
        </Container>
        <Container className= { css(styles.titleContainer) }>
          <Typography className= { css(styles.title) } variant="h1">
            { 'NeuroCognitive Performance Test' }
          </Typography>
        </Container>
        <Container className= { css([commonStyles.flexRowAllCenter, styles.rightContainer]) }>
          { showLogin && 
            <Container>
              <RoundedButton
                styles = { [commonStyles.cta, commonStyles.login] }
                value = { 'Log In' }
                href = { links.login.href }
              />
            </Container>
          }
          <Container className= { css([commonStyles.flexJustifyCenter, styles.avatarContainer]) }>
              <img 
                src = { avatarBasic } 
                className = { css(styles.avatarImg) }  
                alt = { 'Lumosity Logo' }
              />
          </Container>
          {
            userName && 
            <Container className= { css([commonStyles.flexJustifyCenter, styles.userActionContainer]) }>
              <HeaderDropdown 
                title = { userName } 
                backToLumosCallback = { props.backToLumosCallback }
                logoutCallback = { props.logoutCallback }
                logoHref = { links.logo.href }
                isTester = { props.isTester }
                goToCatalog = { props.catalogCallback }
              />
            </Container>
          }
        </Container>
      </Container>
      { 
        showSubheader && 
        <Container className= { css([commonStyles.flexRowAllCenter, styles.subHeader]) }>
          <Typography className= { css(styles.subHeaderItem) }>
            { 'Home' }
          </Typography>
          <Typography className= { css(styles.subHeaderItem) }>
            { 'Your Stats' }
          </Typography>
          <Typography className= { css(styles.subHeaderItem) }>
            { 'Back To Lumosity' }
          </Typography>
        </Container>
      }
      </Container>
  )
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: "#0C3040",
    height: '66px',
    width: '1080px',
    margin: 'auto',
  },
  mainHeader: {
    width: '100%',
  },
  logo: {
    margin: 0,
    padding: 0,
    marginLeft: 24,
    width: 'fit-content',
  },
  logoImg: {
    height: 32,
    width: 125,
    margin: 0,
    padding: 0,
    cursor: 'pointer',
  },

  // TITLE

  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'flex-start',
    padding: 0,
    margin: 0,
    marginLeft: 24,
    // width: '800px',
  },

  title: {
    color: base.lumosWhite,
    fontFamily: "MuseoSans500",
    fontSize: 28,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    textAlign: 'flex-start',
    letterSpacing: '1px',
  },

  // USER NAME & AVATAR
  rightContainer: {
    justifyContent: 'flex-end',
    // marginLeft: 10,
    width: '200px',
  },
  avatarContainer: {
    justifyContent: 'flex-end',
  },
  avatarImg: {
    height: 32,
    width: 32,
  },
  userActionContainer: {
    justifyContent: 'flex-end',
    marginLeft: 10,
    width: '150px',
  },
  
  // SUBHEADER
  subHeader: {
    justifyContent: 'flex-start',
    marginTop: 20,
    marginLeft: 25,
  },
  
  subHeaderItem: {
    color: base.lumosWhite,
    fontFamily: "MuseoSans500",
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '1px',
    marginLeft: '20px',
    alignItems: 'center',
  },

});

export default Header;
