import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import {FormattedMessage} from 'react-intl';

import { css, StyleSheet } from 'aphrodite/no-important';

import { base } from '../lumos_common/colors';
import commonStyles from '../commonStyles';
import checkIcon from '../../assets/check_mark_done.png';

import WideActionButton from '../WideActionButton';

const AssessmentScoreCardComponent = (props) => {
  return (
    <Container className = { css([commonStyles.flexColumnAlignCenter, styles.scoreCardContainer]) }>
      <Card className = { css([commonStyles.flexColumnAlignCenter, styles.scoreCardStyle]) }>
        <Container className= { css([commonStyles.flexColumnAllCenter, styles.checkIconContainer ]) }>
            <img 
              src = { checkIcon } 
              className = { css(styles.checkIconImage) }  
              alt = { 'Check Icon' }
            />
        </Container>
        <Container className = { css([commonStyles.flexColumnAllCenter, styles.textContainer])}>
          <Container className = { css([commonStyles.flexJustifyCenter, commonStyles.titleContainer])}>
            <Typography  className = { css(styles.title) }>
              { props.title }
            </Typography>
          </Container>
          <Container className = { css([commonStyles.flexColumnAlignCenter]) }>
            <Typography  className = { css(styles.description) }>
              <FormattedMessage
                id="description"
                defaultMessage={ props.description }
              />
            </Typography>
          </Container>
          <Container className = { css([commonStyles.flexColumnAlignCenter, styles.scoreValueContainer])}>
          { 
            props.scoresData.map((scoreData, i) => {
              return (
                <div key = {"div_"+i}> 
                  <Typography  className = { css(styles.scoreText) }>
                    <FormattedMessage
                      id={"scoreText_"+i}
                      defaultMessage={ scoreData.scoreText }
                      values={scoreData.values}
                    />
                  </Typography>
                </div>
              )
            })
          }
          </Container>
        </Container>
        <Container className = { css([commonStyles.flexColumnAllCenter, styles.actionContainer])}>
          <WideActionButton 
            buttonText = { props.actionButtonText }
            onClick = { props.actionButtonClicked }
            id = { 'assessment_next' }
            currentPage = { props.currentPage }
          />
        </Container>
      </Card>
    </Container>
  );
}

const styles = StyleSheet.create({
  scoreCardContainer: {
    height: '805px',
  },
  scoreCardStyle: {
    justifyContent: 'space-around',
    height: '500px',
    width: '445px',
    marginTop: '32px',
  },
  textContainer: {
    flex: 2,
  },
  scoreValueContainer: {
    backgroundColor: base.lumosBlue,
    marginTop: '40px',
  },
  scoreText: {
    color: '#2D2D2D',
    fontFamily: "MuseoSans500",
    fontSize: 18,
    fontWeight: 400,
  },

  //// Check Icon
  checkIconContainer: {
    flex: 1,
  },
  checkIconImage: {
    height: 48,
    width: 48,
  },

  title: {
    color: '#2D2D2D',
    fontFamily: "MuseoSans500",
    fontSize: 24,
    letterSpacing: '1px',
    display: 'flex',
  },

  description: {
    color: '#2D2D2D',
    fontFamily: "MuseoSans300",
    fontSize: 18,
    letterSpacing: '1px',
    textAlign: 'center',
  },

  actionContainer: {
    margin: 0,
    padding: 0,
    paddingBottom: 20,
    flex: 1,
  }
});

export default AssessmentScoreCardComponent;
