
import get from 'lodash/get';
import * as queryString from 'query-string';

import { config } from '../config/config';
const backToLumos = () => {
  window.location.href = get(config, 'services.rails.lumosUrl', 'https://lumosity.com');
}

const lumosPlaformCheckURL = () => {
  window.location.href = get(config, 'services.rails.lumosPlatformCheckUrl', 'https://lumosity.com/platform_check');
}

const getLumosSSOLoginLink = () => {
  const url = get(config, 'services.rails.authUrl', '');
  if (!url) {
    console.error("No rails auth url");
  }
  const query = get(config, 'services.rails.authQueryParams', {});
  let loginUrl = queryString.stringifyUrl({
    url,
    query,
  });
  return loginUrl;
}

const getSurveyLink = (surveyLink, userId) => {
  if (userId) {
    return surveyLink + `?customerid=${userId}`;
  }
  return surveyLink;
}

export {
  backToLumos,
  getLumosSSOLoginLink,
  getSurveyLink,
  lumosPlaformCheckURL,
}
