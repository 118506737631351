
import React from 'react';
import {FormattedMessage} from 'react-intl';
import get from 'lodash/get';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { css, StyleSheet } from 'aphrodite/no-important';

import {
  Link,
} from "react-router-dom";

import {
  LocalizedStrings,
} from '../../locale';

import {
  STORES,
} from '../../constants';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  getBatteryResultsHistory,
} from '../../stores/user_store_getters';

import { 
  analyticsTrack,
} from '../../react-shared/src/segment/analytics';

import {
  convertToCurrentUserTimeZone,
  getCurrentUTCString,
  readableFormatWithHourAndMinute,
} from '../../utils/time_utils';

import commonStyles from '../commonStyles';
import checkIcon from '../../assets/check_mark_done.png';
import WideActionButton from '../WideActionButton';

import BatterySummaryResultsHistoryCard from './BatteryResultsHistoryCard';

const PAGE_NAME = 'battery_results_history';
const buildResultsHistoryCards = (batteryRuns, onCardClick) => {
  return batteryRuns.map((batteryRun, index) => {

    return (
      <Grid item key={index}> 
        <BatterySummaryResultsHistoryCard
          title = {
            readableFormatWithHourAndMinute(
              convertToCurrentUserTimeZone(
                get(batteryRun, 'completed_at', getCurrentUTCString())
              )
            )}
          description = { LocalizedStrings.resultsHistoryCardDescription }
          id = { 'results_history_card_' + index}
          currentPage = { PAGE_NAME }
          onClick = { () => { onCardClick(batteryRun.id) } }
        />
      </Grid>
    );
  });
}

const BatteryResultsHistoryView = (props) => {

  // Hooks
  let [userStoreState] = useStoreState(STORES.USER.NAME);
  let resultsHistory = getBatteryResultsHistory(userStoreState);
  let historyCards = buildResultsHistoryCards(resultsHistory.batteries, props.onCardClick);
  
  return (
    <Container  className = { css([commonStyles.flexColumnAllCenter, styles.summaryContainer]) }>
      <Container className= { css([commonStyles.flexColumnAllCenter, styles.checkIconContainer ]) }>
        <img 
          src = { checkIcon } 
          className = { css(styles.checkIconImage) }  
          alt = { 'Check Icon' }
        />
      </Container>
      <Grid container className = { css([commonStyles.flexJustifyCenter, styles.gridContainer]) } spacing={2}>
        {historyCards}
      </Grid>
      <Container className = { css([commonStyles.flexRowAllCenter, styles.actionContainer])  }>
        <WideActionButton 
          buttonText = { props.actionButtonText }
          onClick = { props.actionButtonClicked }
          id = { [PAGE_NAME, 'action_1'].join('_') }
          currentPage = { props.currentPage }
        />
      </Container>
      <Container className = { css([commonStyles.fullWidthCenter,
          commonStyles.flexRowAllCenter, styles.resultsHistoryText])}> 
          <Link
            to=''
            onClick = {e => {
              analyticsTrack('link_click', {
                id: 'back_to_lumos',
                currentPage: props.currentPage,
              });
              props.backToLumosClicked();
              e.preventDefault();
            }}
          >
            { props.backToLumosText }
          </Link>
        </Container>
      <hr className = { css(styles.horizontalLine) } />
      <Container className = { css([commonStyles.flexColumnAlignCenter, styles.bodyTextContainer]) }>
        { 
          LocalizedStrings.resultsHistoryTextFooter.map((msg, i) => {
            return (
              <div key = {"div_"+i}> 
                { 
                  i > 0 &&
                  <div className = { css(styles.divSpacing) } key = {"div_i_"+i}> <br key = {"br_i_1"+i}/> <br key = {"br_i_2"+i}/> </div>
                }
                <Typography 
                  className = { css([commonStyles.flexColumnAllCenter, styles.footerBodyText]) }
                  key = {"body_"+i}
                >
                  <FormattedMessage
                    id= {"footerBody_"+i}
                    key = {"footerBody_"+i}
                    defaultMessage={msg }
                  />
                </Typography>
              </div>
            )
          })
        }
      </Container>
    </Container>
  );
}

const styles = StyleSheet.create({
  horizontalLine: {
    height: 2,
    width: 640,
    color: '#D8D8D8',
  },
  summaryContainer: {
    // height: '720px',
    width: 900,
    justifyContent: 'flex-start',
  },
  summaryDescriptionContainer: {
    marginTop: 24,
  },
  gridContainer: {
    marginTop: 32,
  },
  //// Check Icon
  checkIconContainer: {
    marginTop: 24,
  },
  checkIconImage: {
    height: 48,
    width: 48,
  },

  actionContainer: {
    marginTop: 28,
    // display: 'flex',
    paddingBottom: 20,
    // flex: 1,
  },

  bodyTextContainer: {
    display: 'flex',
    paddingTop: 10,
    width: 900,
    paddingBottom: 20,
  },
  divSpacing: {
    height: '10px',
  },
  footerBodyText: {
    display: 'flex',
    color: '#4A4A4A',
    fontFamily: "MuseoSans500",
    fontSize: 20,
    letterSpacing: '1px',
    textAlign: 'center',
    width: 600,
  },
});

export default BatteryResultsHistoryView;

