
import React, { 
  useState,
  useEffect
} from 'react';

import Container from '@material-ui/core/Container';
import { css, StyleSheet } from 'aphrodite/no-important';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import {
  STORES,
  STATES,
  STATE_EVENTS,
} from '../../constants';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import { 
  fetchBatterySummary,
} from '../../actions/battery_actions';

import {
  getChosenBatterySummaryId,
} from '../../stores/user_store_getters';

import {
  getPreviousAppState,
} from '../../react-shared/src/stores/app_store_getters';

import commonStyles from '../commonStyles';
import PageTitleSubheader from '../subheader/PageTitleSubheader';
import LoadingComponent from '../LoadingComponent';
import BatterySummaryView from './BatterySummaryView';

const BatterySummaryState = () => {

  const PAGE_NAME = 'battery_summary';
  // Hooks
  let [userStoreState] = useStoreState(STORES.USER.NAME);
  let [appStoreState] = useStoreState(STORES.APP.NAME);
  
  let [loaded, setLoaded] = useState(false);
  let [, sendStateMachineEvent] = useStateMachine({});

  const determineNextState = () => {
    const previousState = getPreviousAppState(appStoreState);
    if (previousState.includes(STATES.BATTERY.INTERSTITIAL)) {
      sendStateMachineEvent(STATE_EVENTS.COMMON.NEXT);
    } else if (previousState.includes(STATES.BATTERY.RESULTS_HISTORY)) {
      sendStateMachineEvent(STATE_EVENTS.BATTERY.RESULTS_HISTORY);
    } else {
      sendStateMachineEvent(STATE_EVENTS.COMMON.HOME);
    }
  }
  
  const loadBatterySummary = async () => {
    let chosenBatterySummaryId = getChosenBatterySummaryId(userStoreState);
    if (!chosenBatterySummaryId) {
      determineNextState();
      return;
    }
    let result = await fetchBatterySummary(userStoreState, chosenBatterySummaryId);
    if (!result.success) {
      determineNextState();
      return;
    }
    setLoaded(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    analyticsPageView(PAGE_NAME);
    loadBatterySummary();
  }, [])

  return (
    <Container className = { css([commonStyles.fullWidthCenter, styles.stateSummaryContainer]) }>
      <PageTitleSubheader 
        title= { 'Summary Report' }
      />
      { 
        !loaded && 
        <Container>
          <LoadingComponent title="Fetching Summary" />
        </Container>
      }
      {
        loaded &&
        <Container className = { css([commonStyles.flexJustifyCenter, commonStyles.fullWidthCenter]) }>
          <BatterySummaryView
            actionButtonText = { 'Finish' }
            actionButtonClicked = { determineNextState }
            currentPage = { PAGE_NAME }
          />
        </Container>
      }
    </Container> 
  );
}

const styles = StyleSheet.create({
  stateSummaryContainer: {
    height: '720px',
    margin: 0,
    padding: 0,
  },
});



export default BatterySummaryState;

