
import React, { 
  useEffect,
} from 'react';

import Container from '@material-ui/core/Container';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import {
  LocalizedStrings,
} from '../../locale';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  getCurrentAssessmentRun
} from '../../stores/user_store_getters';

import {
  STORES,
} from '../../constants';

import {
  getLocalizedScoredData,
} from '../../utils/scores_utils';

import AssessmentScoreCardComponent from './AssessmentScoreCardComponent';

function AssessmentScoreState(props) {

  const PAGE_NAME = 'assessment_result';

  let [userStoreState] = useStoreState(STORES.USER.NAME);
  const assessmentRun = getCurrentAssessmentRun(userStoreState);
  const assessmentConfig = assessmentRun.assessment_config;
  let descriptionTextKey = assessmentConfig.frontend_data.score_description_key;
  const runData = assessmentRun.run_data;
  const scoresConfig = assessmentConfig.frontend_data.scores.filter(score_obj => score_obj.screens.includes('score'));
  
  let scoresData = getLocalizedScoredData(scoresConfig, runData);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    analyticsPageView(PAGE_NAME, { slug: assessmentConfig.slug });
    return () => props.scoreStateCompleted();
  }, []);

  return (
    <Container maxWidth="md">
      <AssessmentScoreCardComponent 
        title = { assessmentConfig.title }
        description = {LocalizedStrings[descriptionTextKey]}
        scoresData = {scoresData}
        actionButtonText = { 'Next' } 
        actionButtonClicked = { props.scoreStateCompleted }
        currentPage = { PAGE_NAME }
      />
    </Container>
  );
}

export default AssessmentScoreState;
