
import React, { 
  useEffect 
} from 'react';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

const LibraryErrorState = () => {
  useEffect(() => {
    analyticsPageView('library_error');
  }, [])
  return ( <div> LIBRARY ERROR STATE </div> );
}

export default LibraryErrorState;

