
import { Strings } from './en_us/strings';

// TODO: locale based import 
const LocalizedStrings = {
  ...Strings,
}

export {
  LocalizedStrings,
}
