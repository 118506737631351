
const MISC = {
  AUTH_TOKEN_COOKIE: 'auth-token',
  REFRESH_TOKEN_COOKIE: 'refresh-auth-token',
  AUTHORIZATION_PATH: 'authorization',
  SURVEY_MVP_MAR_2020: 'surveyMVPMarch2020',
}

const STORES = {
  APP: {
    NAME: 'app',

    INITIAL_PATHS_KEY: 'initialPaths',
    COOKIE_INITIAL_PATHS_STACK_KEY: 'cookiePathsStack',
    COOKIE_INITIAL_PARAMS_STACK_KEY: 'cookieQueryParamsStack',

    ASSESSMENTS_CONFIG_KEY: 'assessmentsConfig',
    ASSESSMENT_BY_SLUG_KEY: 'assessmentBySlug',
    ASSESSMENT_BY_ID_KEY: 'assessmentById',

    CURRENT_STATE_KEY: 'currentState',
    PREVIOUS_STATES_KEY: 'previousStates',
  },
  USER: {
    ADMIN_LIB_KEY: 'adminMoves.showLibrary',

    CURRENT_ASSESSMENT_RUN_ID_KEY: 'user.state.current_assessment_run_id',

    CURRENT_STATE_BATTERY_RUN_ID_KEY: 'user.state.current_battery_run_id',
    CURRENT_STUDY_COMPLETED_BATTERY_RUNS: 'user.state.current_study.completed_battery_run_ids',

    CURRENT_BATTERY_RUN_ASSESSMENT_INDEX_KEY: 'current_battery_run.current_battery_assessment_index',
    CURRENT_BATTERY_RUN_ASSESSMENT_RUN_ID_KEY: 'current_battery_run.current_battery_assessment_run_id',
    CURRENT_BATTERY_RUN_KEY: 'current_battery_run',
    CURRENT_BATTERY_RUN_NUM_ASSESSMENTS_KEY: 'current_battery_run.num_assessments',

    COMPLETED_BATTERY_RUN_KEY: 'completed_battery_run',
    BATTERY_SUMMARY_KEY: 'battery_summary',
    FUTURE_BATTERY_RUN_KEY: 'future_battery_run',

    CURRENT_ASSESSMENT_RUN_KEY: 'currentAssessmentRun',
    CURRENT_ASSESSMENT_RUN_SLUG_KEY: 'assessment_config.slug',
    CURRENT_ASSESSMENT_BATTERY_INDEX_KEY: 'currentAssessmentRun.battery_assessment_index',
    CURRENT_ASSESSMENT_CONFIG_KEY: 'currentAssessmentConfig',

    NAME: 'user',
    ROLE_KEY: 'user.role',
    SURVEY_KEY: 'user.state.current_survey',
    USER_KEY: 'user',
    USER_ID_KEY: 'user.id',
    LUMOS_USER_ID_KEY: 'user.lumosity_user_id',

    // MISC FLAGS
    FORCE_LUMOS_SSO_KEY: 'forceLumosSSO',
    SHOW_LOGIN_WELCOME_SCREEN_KEY: 'showLoginWelcomeScreen',

    // BATTERY RESULTS_HISTORY
    BATTERY_RESULTS_HISTORY_KEY: 'battery_results_history',
    CHOSEN_BATTERY_SUMMARY_ID_KEY: 'chosen_battery_summary_id',
    LOADED_BATTERY_SUMMARY_BY_ID_KEY: 'loaded_battery_summary_by_id',
  },
}

const STATES = {
  APP: {
    APP_ERROR: 'appError',
    DEV_TEST: 'devTest',
    DEVICE_DETECT: 'deviceDetect',
    INIT: 'init',
    LOGGED_IN: 'loggedIn',
    LOGGED_OUT: 'loggedOut',
    LOGIN_ERROR: 'loginError',
    LUMOS_SSO_LOGIN: 'lumosSSO',
    NCPT_LOGIN: 'ncptLogin',
  },
  ASSESSMENT: {
    CREATE: 'create',
    ERROR: 'assessmentError',
    PLAY: 'play',
    SCORE: 'score',
  },
  BATTERY: {
    COMPLETED: 'completed',
    INIT: 'batteryInit',
    INTERSTITIAL: 'interstitial',
    NOT_STARTED: 'batteryNotStarted',
    PREP: 'testPrep',
    RESUME: 'batteryResume',
    RESULTS_HISTORY: 'batteryResultsHistory',
    SUMMARY: 'batterySummary',
    WELCOME: 'batteryWelcome',
  },
  LIBRARY: {
    CATALOG: 'catalog',
    ERROR: 'error',
    LOADING: 'loading',
  },
  LOGGED_IN: {
    ASSESSMENT: 'assessment',
    BATTERY: 'battery',
    DECISION: 'decision',
    INIT: 'init',
    LIBRARY: 'library',
    SURVEY: 'survey',
    WELCOME: 'welcome',
  },
}

const STATE_EVENTS = {
  APP: {
    LOGIN: 'login',
    LOGOUT: 'logout',
  },
  ASSESSMENT: {
    CREATED: 'created',
    COMPLETED: 'completed',
    STARTED: 'started',
    QUIT: 'quit',
  },
  BATTERY: {
    ASSESSMENT: 'assessment',
    COMPLETED: 'completed',
    NOT_STARTED: 'notStarted',
    PREP: 'prep',
    RESUME: 'resume',
    RESULTS_HISTORY: 'resultsHistory',
    SUMMARY: 'summary',
  },
  COMMON: {
    ERROR: 'error',
    HOME: 'home',
    NEXT: 'next',
    RETRY: 'retry',
    SUCCESS: 'success',
  },
  LIBRARY: {
    LOADED: 'loaded',
    PLAY: 'play',
  },
  LOGGED_IN: {
    LIBRARY: 'library',
    BATTERY: 'battery',
    BATTERY_PLAY_COMPLETE: 'batteryPlayCompleted',
    BATTERY_RESULTS_HISTORY: 'batteryResultsHistory',
    BATTERY_SUMMARY: 'batterySummary',
    PLAY: 'play',
    SELF: 'self',
    SINGLE_PLAY_COMPLETE: 'singlePlayCompleted',
    SURVEY: 'survey',
    WELCOME: 'welcome',
  },
}

const STATE_MACHINE = {
  NAMES: {
    APP_STATE_MACHINE: 'appMachine',
    ASSESSMENT_STATE_MACHINE: 'assessmentMachine',
    BATTERY_STATE_MACHINE: 'batteryMachine',
    BATTERY_STATE_MACHINE_INTERSTITIAL: 'batteryInterstitial',
    BATTERY_STATE_MACHINE_RESULTS_HISTORY: 'batteryResultsHistory',
    BATTERY_STATE_MACHINE_SUMMARY: 'batterySummary',
    LIBRARY_STATE_MACHINE: 'libraryMachine',
    LOGGED_IN_STATE_MACHINE: 'loggedInMachine',
  },
}

export {
  MISC,
  STATE_EVENTS,
  STATE_MACHINE,
  STATES,
  STORES,
}
