
import get from 'lodash/get';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  Link,
} from "react-router-dom";

import Container from '@material-ui/core/Container';

import LoadingComponent from '../LoadingComponent';

import { css, StyleSheet } from 'aphrodite';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  MISC,
  STORES,
  STATE_EVENTS,
} from '../../constants';

import {
  useStateMachine
} from '../../react-shared/src/statemachine';

import {
  lumosSSOLogin,
} from '../../actions/app_actions';

import {
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import {
  getLumosSSOLoginLink,
} from '../../utils/link_utils';

import {
  forceLumosSSO,
} from '../../stores/user_store_getters';

import {
  getInitialPath,
} from '../../react-shared/src/stores/app_store_getters';

import { config } from '../../config/config';
const loginLink = getLumosSSOLoginLink();

const AppLumosSSOLoginState = () => {

  const updateUserStore = (newUserStoreState) => {
    userStoreState = newUserStoreState;
  }
  let [userStoreState] = useStoreState(STORES.USER.NAME, updateUserStore);

  let [appStoreState] = useStoreState(STORES.APP.NAME);
  const [, sendStateMachineEvent] = useStateMachine({});

  let [skipSSOQuestion, setSkipSSOQuestion] = useState(false);

  const redirectToLumosSSO = function () {
    window.location.href = loginLink;
  }

  const goToNextState = function () {
    sendStateMachineEvent(STATE_EVENTS.COMMON.SUCCESS);
  }

  const callLumosSSOAuth = async function () {
    let initialPath = getInitialPath(appStoreState);
    if (initialPath && initialPath.path.includes(MISC.AUTHORIZATION_PATH) && initialPath.params) {
      const loginResult = await lumosSSOLogin(initialPath);
      if (loginResult.success) {
        sendStateMachineEvent(STATE_EVENTS.COMMON.SUCCESS);
      } else {
        sendStateMachineEvent(STATE_EVENTS.COMMON.ERROR);
      }
    }
    else if (forceLumosSSO(userStoreState)) {
      goToNextState();
    } else {
      if (config.services.rails.askSSO) {
        setSkipSSOQuestion(true);
      } else {
        redirectToLumosSSO();
      }
    }
  }

  useEffect(() => {
    analyticsPageView('login_sso');
    callLumosSSOAuth();
  }, [])

  return (
    <div>
      <LoadingComponent title="Lumos SSO" />
      {
        skipSSOQuestion &&
        <Container className={css(styles.ssoQuestionContainer)}>
          <Link className={css(styles.doSSO)} onClick={redirectToLumosSSO} replace>Dev Login</Link>
          <Link className={css(styles.skipSSO)} onClick={goToNextState} replace>Dev Skip</Link>
        </Container>
      }
    </div>);
}

const styles = StyleSheet.create({
  ssoQuestionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  skipSSO: {},
  doSSO: {},
});

export default AppLumosSSOLoginState;
