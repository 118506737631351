
const MISC = {
  AUTH_TOKEN_COOKIE: 'auth-token',
  REFRESH_TOKEN_COOKIE: 'refresh-auth-token',
}

const STORES = {
  APP: {
    NAME: 'app',
    INITIAL_PATHS_KEY: 'initialPaths',
    COOKIE_INITIAL_PATHS_STACK_KEY: 'cookiePathsStack',
    COOKIE_INITIAL_PARAMS_STACK_KEY: 'cookieQueryParamsStack',

    CURRENT_STATE_KEY: 'currentState',
    PREVIOUS_STATES_KEY: 'previousStates',
  },
  USER: {
    NAME: 'user',
    USER_ID: 'user.id',

    USER_KEY: 'user',
    SHOW_LOGIN_WELCOME_SCREEN_KEY: 'showLoginWelcomeScreen',

    ACCESS_TOKEN_KEY: 'accessToken',
    REFRESH_TOKEN_KEY: 'refreshToken'
  },
}

const STATES = {
  APP: {
    APP_ERROR: 'appError',
    DEV_TEST: 'devTest',
    DEVICE_DETECT: 'deviceDetect',
    INIT: 'init',
    LOGIN: 'login',
    LOGGED_IN: 'loggedIn',
    LOGGED_OUT: 'loggedOut',
    LOGIN_ERROR: 'loginError',
  },
  GAME: {
    CREATE: 'create',
    PLAY: 'play',
    SCORE: 'score',
  },
  LIBRARY: {
    CATALOG: 'catalog',
    ERROR: 'error',
    LOADING: 'loading',
  },
  LOGGED_IN: {
    ACCOUNT: 'account',
    DECISION: 'decision',
    GAME: 'game',
    HOME: 'home',
    INIT: 'init',
    LIBRARY: 'library',
    SURVEY: 'survey',
    WELCOME: 'welcome',
  },
}

const STATE_EVENTS = {
  APP: {
    LOGIN: 'login',
    LOGIN_SUCCESS: 'loginSuccess',
    LOGOUT: 'logout',
  },
  COMMON: {
    BATCH_CREATE: 'batchUpdate',
    CANCEL: 'cancel',
    CREATED: 'created',
    COMPLETED: 'completed',
    DELETE: 'delete',
    ERROR: 'error',
    FAILURE: 'failure',
    HOME: 'home',
    NEXT: 'next',
    PLAY: 'play',
    QUIT: 'quit',
    RETRY: 'retry',
    SEARCH: 'search',
    SELF: 'self',
    SUCCESS: 'success',
    UPDATED: 'updated',
  },
  LIBRARY: {
    LOADED: 'loaded',
    PLAY: 'play',
  },
  LOGGED_IN: {
    ACCOUNT: 'account',
    GAME: 'game',
    HOME: 'home',
    LIBRARY: 'library',
    WELCOME: 'welcome',
  },
}

const STATE_MACHINE = {
  NAMES: {
    APP_STATE_MACHINE: 'appMachine',
    GAME_STATE_MACHINE: 'gameMachine',
    LIBRARY_STATE_MACHINE: 'libraryMachine',
    LOGGED_IN_STATE_MACHINE: 'loggedInMachine',
  },
}

export {
  MISC,
  STATE_EVENTS,
  STATE_MACHINE,
  STATES,
  STORES,
}
