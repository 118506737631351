
import React, { 
  useEffect 
} from 'react';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

const AppErrorState = () => {
  useEffect(() => {
    analyticsPageView('app_error');
  }, [])
  return ( <div> APP ERROR STATE </div> );
}

export default AppErrorState;

