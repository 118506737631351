
import React from 'react';

import { css } from 'aphrodite';
import Container from '@material-ui/core/Container';

import {
  Link,
} from "react-router-dom";

import commonStyles from './commonStyles';

import { config } from '../config/config';

const SkipComponent = (props) => {
  return (
    <Container className = { css([commonStyles.fullWidthCenter, commonStyles.flexRowAllCenter])}> 
      <Link
        to=''
        onClick= { e => props.onClick(e) }
      >
        { props.message || 'Skip' }
      </Link>
    </Container>
  );
}

export default SkipComponent;
