
import get from 'lodash/get';
import {
  LocalizedStrings,
} from '../locale';

const getLocalizedScoredData = function(scoresConfig, runData) {
  let scoresData = [];
  scoresConfig.forEach(scoreCfg => {
    let scoreData = {
      scoreText: LocalizedStrings[scoreCfg.summary_score_key],
    }
    let values = {};
    for (let scoreTextKey of Object.keys(scoreCfg.run_data_references)) {
      let scoreObj = scoreCfg.run_data_references[scoreTextKey];
      values[scoreTextKey] = get(runData, scoreObj.key, scoreObj.default);
      if (scoreObj.truncate_ms) {
        values[scoreTextKey] = values[scoreTextKey] / 1000;
      }
      if (scoreObj.round) {
        values[scoreTextKey] = parseInt(Math.round(values[scoreTextKey]));
      }
    }
    scoreData['values'] = values;
    scoresData.push(scoreData);
  })

  return scoresData;
}

export {
  getLocalizedScoredData,
}
