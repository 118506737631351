
import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';

import isEmpty from 'lodash/isEmpty';

import { 
  analyticsPageView,
} from '../../react-shared/src/segment/analytics';

import {
  useStoreState,
} from '../../react-shared/src/stores';

import {
  setChosenBatterySummaryId,
} from '../../stores/user_store';

import {
  STORES,
  STATE_EVENTS,
} from '../../constants';

import { 
  useStateMachine
} from '../../react-shared/src/statemachine';

import {
  updateBatteryData,
} from '../../actions/battery_actions';

import {
  getCompletedBattery,
  getCurrentBattery,
  getCurrentUserStateBatteryRunId,
} from '../../stores/user_store_getters';

import LoadingComponent from '../LoadingComponent';

const BatteryInterstitialState = () => {

  const updateUserStore = (newUserStoreState) => {
    userStoreState = newUserStoreState;
    let completedBattery = getCompletedBattery(userStoreState);
    let currentBattery = getCurrentBattery(userStoreState);
    if (!isEmpty(currentBattery)) {
      sendStateMachineEvent(STATE_EVENTS.BATTERY.ASSESSMENT);
    } else if (!isEmpty(completedBattery)) {
      sendStateMachineEvent(STATE_EVENTS.BATTERY.SUMMARY);
    } else {
      sendStateMachineEvent(STATE_EVENTS.BATTERY.COMPLETED);
    }
  }

  // Hooks
  let [userStoreState] = useStoreState(STORES.USER.NAME, updateUserStore);
  let [, sendStateMachineEvent] = useStateMachine({});

  const startAssessment = async () => {
    let batteryRunId = getCurrentUserStateBatteryRunId(userStoreState);
    await updateBatteryData(userStoreState, batteryRunId);
  }

  useEffect(() => {
    analyticsPageView('battery_update');
    startAssessment();
  }, [])

  return ( 
    <Container>
      <LoadingComponent title="Fetching Test" />
    </Container>
  );
}

export default BatteryInterstitialState;

